import axios from "axios";

// <-------- Development URL --------> 
// const BASE_URL = 'https://xpanse.dev.bexcart.com/';

// <-------- Old Production URL -------->
// const BASE_URL = 'https://api.xpansecafe.com/'

// -----------> NEW PRODUCTION URL FOR ADMIN PANEL <-----------------
const BASE_URL = 'https://admin.api.xpansecafe.com/';
// const BASE_URL = 'https://admin.api.xpansecafe.com/';

// -----------> LOCALHOST URL <-----------------
// const BASE_URL = 'http://localhost:2023/'

export default axios.create({
    baseURL: BASE_URL,
})