import React, { useEffect, useRef, useState } from "react";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { cilLockLocked, cilUser, cilEye, cilEyeSlash } from "@coreui/icons";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components";
import { toast } from "react-toastify";

const Login = () => {
  const [logInInputData, setLogInInputData] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userRef = useRef();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogInInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post("/auth/admin/signin", logInInputData);

      if (response?.data?.status === true) {
        toast.success(response.data.data.message, {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
      }
      const authToken = response?.data?.data?.jwt;
      // localStorage.setItem("authToken", authToken);
      // navigate("/dashboard");

      if (authToken) {
        localStorage.setItem("authToken", authToken);
  
        // Ensure the token is set before navigating
        setTimeout(() => {
          navigate("/dashboard");
        }, 100); // Small delay to ensure storage update
      } else {
        throw new Error("Authentication token is missing!");
      }

    } catch (error) {
      if (
        error.response &&
        error.response?.data?.status === false &&
        error.response?.status === 406
      ) {
        let errorMessage = error?.response?.data?.error;

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
      } else if (
        error.response &&
        error.response?.data?.status === false &&
        error.response?.status === 412
      ) {
        let errorMessage = error?.response?.data?.data?.message;

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
      } else if (
        error.response &&
        error.response?.data?.status === false &&
        error.response?.status === 404
      ) {
        let errorMessage = error?.response?.data?.data?.message;

        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const disableBtn = !logInInputData?.username?.trim() || !logInInputData?.password?.trim()

  useEffect(() => {
    userRef.current.focus();
  }, []);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard
                className="p-4"
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                }}
              >
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <div
                      className="mb-5"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      <h2>Login</h2>
                      <p className="text-medium-emphasis">
                        Sign In to your account
                      </p>
                    </div>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="username"
                        autoComplete="username"
                        name="username"
                        value={logInInputData.username}
                        onChange={handleChange}
                        ref={userRef}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4 mt-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        value={logInInputData.password}
                        onChange={handleChange}
                      />
                      <CInputGroupText onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                        {isPasswordVisible ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                          </svg>
                        )
                        }
                      </CInputGroupText>
                    </CInputGroup>
                    <PrimaryButton
                      isDisable={disableBtn}
                      isLoading={isLoading}
                      title="Login"
                      style={{ width: "100%" }}
                    />
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
