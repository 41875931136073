import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CContainer, CForm, CFormCheck, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components'
import Select from "react-select";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { format } from 'date-fns';
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import debounce from 'lodash/debounce';
import Edit from './Edit';
import MultiSelectComponent from '../../components/MultiSelectComponent';

const Add = () => {
    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [usersList, setUsersList] = useState([]);
    const [usersFromFile, setUsersFromFile] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [couponData, setCouponData] = useState({
        isBogoCoupon: false,
        isSizeUpgradeCoupon: false,
        sizeUpgradeAddon: "",
        title: "",
        description: "",
        usageType: "",
        couponCode: "",
        discountPercent: "",
        dayOfWeek: "",
        discountAmount: "",
        validityStartDate: "",
        validityEndDate: "",
        startTime: "",
        endTime: "",
        discountPrice: "",
        applicableQuantityType: "",
        termsAndConditions: [], // Array to store terms
        isComboCategoryCoupon: false,
        maxAddonsAllowed: null,
        maxDiscountAmount: "",
        minOrderAmount: "",
        isMrpDiscount: false
    });
    const [selections, setSelections] = useState({
        selectedIncludedOutlets: [],
        selectedExcludedOutlets: [],
        selectedApplicableCategories: [],
        selectedApplicableProducts: [],
        selectedDependentCategories: [],
        selectedDependentProducts: [],
        selectedExcludedProducts: [],
        selectedApplicableLevels: [],
        primaryComboCategories: [],
        secondaryComboCategories: []
    });
    const [currentTerm, setCurrentTerm] = useState('');
    const [addonList, setAddonList] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [addNewOpenModal, setAddNewOpenModal] = useState(false);
    const [userCouponAllocationData, setUserCouponAllocationData] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [couponUsers, setCouponUsers] = useState([]);
    const [couponDetails, setCouponDetails] = useState({});
    const [openEditModal, setOpenEditModal] = useState(false);
    const [userCouponId, setUserCouponId] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [userCouponAllocationId, setUserCouponAllocationId] = useState("");
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    const [filteredData, setFilteredData] = useState([]);
    const [file, setFile] = useState(null);
    const [outlets, setOutlets] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedIncludedOptions, setSelectedIncludedOptions] = useState([]);
    const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
    const [levels, setLevels] = useState([]);
    const [primaryComboCategories, setPrimaryComboCategories] = useState([])
    const [secondaryComboCategories, setSecondaryComboCategories] = useState([]);
    const [editSelections, setEditSelections] = useState({
        selectedIncludedOutlets: [],
        selectedExcludedOutlets: [],
        selectedAddonValues: [],
        selectedApplicableLevels: [],
        primaryComboCategories: [],
        secondaryComboCategories: []
    });
    const [updatingData, setUpdatingData] = useState(null);
    const [exProductList, setExProductList] = useState([]);
    const [selectedDependentCategories, setSelectedDependentCategories] = useState([]);
    const [selectedDependentProducts, setSelectedDependentProducts] = useState([]);
    const [selectedExcludedProducts, setSelectedExcludedProducts] = useState([]);

    const formattedDate = (string) => {
        return string?.split("T")[0];
    }

    const columns = [
        {
            name: "Sr.No",
            // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
            cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
            // center: true,
        },
        {
            name: "COUPON TITLE",
            selector: (row) => row.coupon_id?.title,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.title ? row.coupon_id?.title : "--------"}</div>
            ),
        },
        {
            name: "CODE",
            selector: (row) => row.coupon_id?.couponCode,
            cell: (row) => (
                <div className="fs-7 fw-semibold" style={{ color: 'purple' }}>{row.coupon_id?.couponCode ? row.coupon_id?.couponCode : "--------"}</div>
            ),
        },
        {
            name: "USAGE-TYPE",
            selector: (row) => row.coupon_id?.usageType
            ,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.usageType
                    ? row.coupon_id?.usageType
                    : "--------"}</div>
            ),
        },
        {
            name: "VALID FROM ",
            selector: (row) => row.coupon_id?.validityStartDate
            ,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.validityStartDate
                    ? formattedDate(row.coupon_id?.validityStartDate)
                    : "--------"}</div>
            ),
        },
        {
            name: "VALID TO ",
            selector: (row) => row.coupon_id?.validityEndDate
            ,
            cell: (row) => (
                <div className="fs-7">{row.coupon_id?.validityEndDate
                    ? formattedDate(row.coupon_id?.validityEndDate)
                    : "--------"}</div>
            ),
        },
        {
            name: "ACTIONS",
            right: "true",
            width: "25%",
            cell: (row) => (
                <div className="d-flex justify-content-around align-items-center">
                    <a
                        href="#"
                        className="text-info ms-2 me-2 "
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={() => handleOpenViewModal(row)}
                    >
                        <FaEye /> View
                    </a>{" "}
                    | {" "}
                    <a
                        href="#"
                        onClick={() => handleOpenEditModal(row)}
                        className="text-primary ms-2 me-2 d-flex align-items-center gap-1"
                        style={{ textDecoration: "none" }}
                    >
                        <FaPencilAlt /> EDIT
                    </a>{" "}
                    |
                    <a
                        href="#"
                        className="text-danger ms-2 d-flex align-items-center gap-1"
                        onClick={() => handleDeleteModal(row)}
                        style={{ textDecoration: "none" }}
                    >
                        <FaTrash /> DELETE
                    </a>
                </div>
            ),
        },
    ];

    const updatedColumns = columns.map((column) => ({
        ...column,
        name: (
            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
        ),
    }));

    const addTerm = () => {
        if (!currentTerm.trim()) return;
        setCouponData((prevData) => ({
            ...prevData,
            termsAndConditions: Array.isArray(prevData.termsAndConditions)
                ? [...prevData.termsAndConditions, currentTerm]
                : [currentTerm], // Fallback to an array if it's not already one
        }));
        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setCouponData((prevData) => ({
            ...prevData,
            termsAndConditions: Array.isArray(prevData.termsAndConditions)
                ? prevData.termsAndConditions.filter((_, i) => i !== index)
                : [], // Ensure it doesn't break if termsAndConditions is undefined
        }));
    };

    const usersInfo = usersList.map((user) => ({
        value: user?._id,
        label: `${user?.name}, ${user?.mobile}`
    }));

    const productsInfo = productsList.map((product) => ({
        value: product?._id,
        label: `${product?.name}`
    }));

    const categoriesData = categoryList.map((category) => ({
        value: category._id,
        label: `${category.name}`
    }));

    const outletData = outlets
        .filter(
            (outlet) =>
                !selectedOptions.some((selected) => selected.value === outlet._id) && // Exclude selected Excluded outlets
                !selectedIncludedOptions.some((selected) => selected.value === outlet._id) // Exclude selected Included outlets
        )
        .map((outlet) => ({
            value: outlet._id,
            label: `${outlet.name} (${outlet.city})`,
        }));


    const editOutletData = outlets
        .filter((outlet) => {
            const isExcluded = editSelections?.selectedExcludedOutlets?.some(
                (excludedOutlet) => excludedOutlet.value === outlet._id
            );

            const isIncluded = editSelections?.selectedIncludedOutlets?.some(
                (includedOutlet) => includedOutlet.value === outlet._id
            );

            return !isExcluded && !isIncluded;
        })
        .map((outlet) => ({
            value: outlet._id,
            label: `${outlet.name} (${outlet.city})`,
        }));


    const levelsData = levels.map((level) => ({
        value: level._id,
        label: `${level.sequenceNo}. ${level.title}`
    }));

    const exProductsInfo = exProductList.map((pro) => ({
        value: pro?._id,
        label: `${pro?.name}`
    }));

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: '6px',
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    const checkboxes = [
        { id: "isBogoCoupon", label: "Is BOGO Coupon ?", field: "isBogoCoupon" },
        { id: "isSizeUpgradeCoupon", label: "Is Size Upgrade Coupon ?", field: "isSizeUpgradeCoupon" },
        { id: "isComboCategoryCoupon", label: "Is Combo Category Coupon ?", field: "isComboCategoryCoupon" },
        { id: "isMRPCoupon", label: "Is MRP Coupon ?", field: "isMrpDiscount" },
    ];

    // const handleCheckboxChange = (field) => (e) => { Old Code
    //     resetFields()
    //     setCouponData((prev) => ({
    //         ...prev,
    //         [field]: e.target.checked,
    //         isBogoCoupon: field === "isBogoCoupon" ? e.target.checked : false,
    //         isSizeUpgradeCoupon: field === "isSizeUpgradeCoupon" ? e.target.checked : false,
    //         isComboCategoryCoupon: field === "isComboCategoryCoupon" ? e.target.checked : false,
    //         isMrpDiscount: field === "isMrpDiscount" ? e.target.checked : false,
    //     }));
    // };

    const handleCheckboxChange = (field) => (e) => {
        // Save the current users selection before resetting
        const currentUsers = selectedUsers;
        
        resetFields();
        
        // Restore the users selection
        setSelectedUsers(currentUsers);
        
        setCouponData((prev) => ({
            ...prev,
            [field]: e.target.checked,
            isBogoCoupon: field === "isBogoCoupon" ? e.target.checked : false,
            isSizeUpgradeCoupon: field === "isSizeUpgradeCoupon" ? e.target.checked : false,
            isComboCategoryCoupon: field === "isComboCategoryCoupon" ? e.target.checked : false,
            isMrpDiscount: field === "isMrpDiscount" ? e.target.checked : false,
        }));
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const resetFields = () => {
        setCouponData({
            isBogoCoupon: false,
            isSizeUpgradeCoupon: false,
            sizeUpgradeAddon: "",
            title: "",
            description: "",
            usageType: "",
            couponCode: "",
            discountPercent: "",
            dayOfWeek: "",
            discountAmount: "",
            validityStartDate: "",
            validityEndDate: "",
            startTime: "",
            endTime: "",
            discountPrice: "",
            applicableQuantityType: "",
            applicableProducts: [],
            termsAndConditions: [],
            isComboCategoryCoupon: false,
            maxAddonsAllowed: null,
            maxDiscountAmount: "",
            minOrderAmount: "",
            isMrpDiscount: false
        });

        setSelections({
            selectedIncludedOutlets: [],
            selectedExcludedOutlets: [],
            selectedApplicableCategories: [],
            selectedApplicableProducts: [],
            selectedDependentCategories: [],
            selectedDependentProducts: [],
            selectedExcludedProducts: [],
            selectedApplicableLevels: [],
            primaryComboCategories: [],
            secondaryComboCategories: []
        });

        setFile(null);
        setFromDate(null);
        setToDate(null);
        setSelectedCategories([]);
        setSelectedProducts([]);
        setSelectedUsers([]);
    }

    // const handleRowsPerPageChange = (rowsPerPage) => {
    //     setPagination({ ...pagination, rowsPerPage });
    // };

    // const handlePageChange = (page) => {
    //     setPagination({ ...pagination, page });
    // };

    const handleDeleteModal = async (data) => {
        setOpenDeleteModal(true);
        setUserCouponAllocationId(data?._id);
    };

    // Delete user coupon allocation
    const handleDeleteUserCouponAllocation = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                _id: userCouponAllocationId,
            };
            const response = await axios.delete(`user-coupon-allocation`, { data, headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                setOpenDeleteModal(false);
            }
            fetchData();
        } catch (error) {

            console.log(error.response);
            
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }

            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtnLoading(false);
        }
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    };

    const handleAddNewOpenModal = () => {
        setAddNewOpenModal(true);
        setCouponData({
            isBogoCoupon: false,
            isSizeUpgradeCoupon: false,
            sizeUpgradeAddon: "",
            title: "",
            description: "",
            usageType: "",
            couponCode: "",
            discountPercent: "",
            discountAmount: "",
            validityStartDate: "",
            validityEndDate: "",
            discountPrice: "",
            applicableQuantityType: "",
            applicableProducts: []
        });
        setFile(null);
        setFromDate(null);
        setToDate(null);
        setSelectedCategories([]);
        setSelectedProducts([]);
        setSelectedUsers([]);
    };

    const handleCloseAddNewOpenModal = () => {
        setAddNewOpenModal(false);
        resetFields()
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        const updatedCouponCode = name === "couponCode" ? value.toUpperCase() : value
        setCouponData((prev) => {
            const updatedData = {
                ...prev,
                [name]: type === "number" ? parseFloat(value) || "" : updatedCouponCode,
            };
            return updatedData;
        });
    };

    const handleSelectUsersChange = (users) => {
        setSelectedUsers(users);
    };

    const handleSelectCategoryChange = (cats) => {
        setSelectedCategories(cats);
    };

    const handleSelectProductChange = (products) => {
        setSelectedProducts(products);
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    // Modified getUsersList to accept a search query
    const getUsersList = async (query) => {
        if (!query) {
            setUsersList([]); // Clear the user list if there's no query
            return;
        }
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get(`user?search=${query}`, { headers });
            if (response?.data?.status === true) {
                const users = response?.data?.data?.users;
                setUsersList(users);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Debounce the search function to avoid excessive calls
    const debouncedGetUsersList = debounce(getUsersList, 500);

    const getProductsList = async (catId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);

                let excludedProducts = response?.data?.data?.products?.filter((fi) =>
                    catId.some((category) => category.value === fi?.category?._id)
                );

                setExProductList(excludedProducts);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoryList(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Adds a new coupon to the system.
    const handleSubmitCoupon = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let selectedUsersData

            if (selectedUsers.length > 0) {
                selectedUsersData = selectedUsers.map((c) => c.value);
            } else {
                selectedUsersData = usersFromFile
            }

            if (selectedUsersData?.length === 0) {
                toast.error("Users Required", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });

                return
            }

            if (couponData?.dayOfWeek === "" || couponData?.dayOfWeek === "all") {
                delete couponData.dayOfWeek
            }

            if (!couponData.validityStartDate && !couponData?.validityEndDate) {
                toast.error("Please provide start date and end date for the coupon validity.", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                return
            }

            if (!couponData.validityStartDate && couponData?.validityEndDate) {
                toast.error("Please select a start date for the coupon validity.", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                return
            }

            if (couponData.validityStartDate && !couponData?.validityEndDate) {
                toast.error("Please select a end date for the coupon validity.", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                return
            }

            // Map selected values
            const selectedIncludedOutlets = selections?.selectedIncludedOutlets?.map((option) => option.value) || [];
            const selectedExcludedOutlets = selections?.selectedExcludedOutlets?.map((option) => option.value) || [];
            const selectedApplicableCategories = selections?.selectedApplicableCategories?.map((option) => option.value) || [];
            const selectedApplicableProducts = selections?.selectedApplicableProducts?.map((option) => option.value) || [];
            const selectedApplicableLevels = selections?.selectedApplicableLevels?.map((option) => option.value) || [];
            const selectedDependentCategories = selections?.selectedDependentCategories?.map((option) => option.value) || [];
            const selectedDependentProducts = selections?.selectedDependentProducts?.map((option) => option.value) || [];
            const selectedExcludedProducts = selections?.selectedExcludedProducts?.map((option) => option.value) || [];
            const selectedPrimaryComboCategories = selections?.primaryComboCategories?.map((option) => option.value) || [];
            const selectedSecondaryComboCategories = selections?.secondaryComboCategories?.map((option) => option.value) || [];

            let updatedCouponData = {
                ...couponData,
                excludedOutlets: selectedExcludedOutlets,
                includedOutlets: selectedIncludedOutlets,
                applicableLevels: selectedApplicableLevels,
            };

            if (selectedPrimaryComboCategories.length > 0) {
                updatedCouponData.primaryComboCategories = selectedPrimaryComboCategories
            }
            if (selectedSecondaryComboCategories.length > 0) {
                updatedCouponData.secondaryComboCategories = selectedSecondaryComboCategories
            }
            if (selectedDependentCategories.length > 0) updatedCouponData.dependantCategories = selectedDependentCategories

            if (selectedDependentProducts.length > 0) updatedCouponData.dependantProducts = selectedDependentProducts

            if (selectedExcludedProducts.length > 0) updatedCouponData.excludedProducts = selectedExcludedProducts

            if (selectedApplicableProducts.length > 0) updatedCouponData.applicableProducts = selectedApplicableProducts

            if (selectedApplicableCategories.length > 0) updatedCouponData.applicableCategories = selectedApplicableCategories

            // Clean up empty arrays from `updatedCouponData`
            Object.keys(updatedCouponData).forEach((key) => {
                if (Array.isArray(updatedCouponData[key]) && updatedCouponData[key].length === 0) {
                    delete updatedCouponData[key];
                }
            });

            // Conditional Deletion Logic
            if (updatedCouponData?.discountAmount) {
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.applicableProducts;
                delete updatedCouponData.isSizeUpgradeCoupon;
                delete updatedCouponData.sizeUpgradeAddon;
                delete updatedCouponData.applicableQuantityType
            }

            if (updatedCouponData?.discountPercent) {
                delete updatedCouponData.discountAmount;
                delete updatedCouponData.applicableProducts;
                delete updatedCouponData.isSizeUpgradeCoupon;
                delete updatedCouponData.sizeUpgradeAddon;
                delete updatedCouponData.applicableQuantityType
            }

            if (updatedCouponData.isBogoCoupon) {
                delete updatedCouponData.applicableProducts;
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.discountAmount;
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
                delete updatedCouponData.isSizeUpgradeCoupon;
                delete updatedCouponData.sizeUpgradeAddon;
            } else {
                delete updatedCouponData.isBogoCoupon;
            }

            if (updatedCouponData.isSizeUpgradeCoupon) {
                delete updatedCouponData.discountPercent;
                delete updatedCouponData.discountAmount;
                delete updatedCouponData.discountPrice;
                delete updatedCouponData.applicableQuantityType;
            }

            if (Array.isArray(updatedCouponData.termsAndConditions) && updatedCouponData.termsAndConditions.length === 0) {
                delete updatedCouponData.termsAndConditions;
            }

            // Determine the final `newCouponData` based on the conditions
            let newCouponData = {
                ...updatedCouponData,
            };

            newCouponData = Object.fromEntries(
                Object.entries(newCouponData).filter(
                    ([_, value]) => value !== null && value !== undefined && value !== ""
                )
            );

            // Handle API Call
            const response = await axios.post("coupons", newCouponData, { headers });

            if (response?.data?.status === true) {
                const couponId = response?.data?.data?.dbResponse?._id;
                await handleSubmitUserCoupon(couponId);
                resetFields()
            }
        } catch (error) {
            let errorMessage = 'An error occurred';
            if (error?.response) {
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 412 || error?.response?.status === 404) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
            }
            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Adds a new user coupon allocation to the system.
    const handleSubmitUserCoupon = async (couponId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let selectedUsersData

            if (selectedUsers.length > 0) {
                selectedUsersData = selectedUsers.map((c) => c.value);
            } else {
                selectedUsersData = usersFromFile
            }

            if (selectedUsersData?.length === 0) {
                toast.error("Users Required", {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });

                return
            }

            const data = {
                users: selectedUsersData,
                coupon_id: couponId
            }

            const response = await axios.post("user-coupon-allocation", data, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                fetchData()
                setCouponData({
                    isBogoCoupon: false,
                    isSizeUpgradeCoupon: false,
                    sizeUpgradeAddon: "",
                    title: "",
                    description: "",
                    usageType: "",
                    couponCode: "",
                    discountPercent: "",
                    discountAmount: "",
                    validityStartDate: "",
                    validityEndDate: "",
                    discountPrice: "",
                    applicableQuantityType: "",
                    applicableProducts: [],
                    termsAndConditions: [], // Array to store terms
                    isComboCategoryCoupon: false,
                    maxAddonsAllowed: null,
                    maxDiscountAmount: "",
                    minOrderAmount: "",
                    isMrpDiscount: false
                });
                setPrimaryComboCategories([]);
                setSecondaryComboCategories([]);
                setExProductList([]);
                setSelectedDependentCategories([]);
                setSelectedDependentProducts([]);
                setSelectedExcludedProducts([])
                setSelectedCategories([])
                setSelectedProducts([])
                setSelectedUsers([]);
                setFromDate(null);
                setToDate(null);
                handleCloseAddNewOpenModal()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412 || error?.response?.status === 404
                ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const fetchData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("user-coupon-allocation", { headers });
            if (response?.data?.status === true) {
                response?.data?.data?.userCouponAllocations.forEach((item, index) => {
                    item.serialNumber = index + 1;
                });
                const responseData = response?.data?.data?.userCouponAllocations;
                console.log("responseData", responseData);

                setUserCouponAllocationData(responseData);
                setFilteredData(responseData)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getOutlets = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("outlet", { headers });

            if (response?.data?.status === true) {
                setOutlets(response?.data?.data?.outlets);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getLevels = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const handleOpenViewModal = (data) => {
        setOpenViewModal(true);
        setCouponUsers(data?.users);
        setCouponDetails(data?.coupon_id);
        console.log("data", data);
    };

    // Open Edit Modal and set Edit data 
    const handleOpenEditModal = (data) => {
        setUpdatingData(data);
        setOpenEditModal(true)
    }

    const getAddonProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product-addon?limit=1000&page=1", { headers });
            if (response?.data?.status === true) {
                const addOns = response?.data?.data?.productAddons
                // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
                const productsWithSizeAddon = addOns.filter((item) =>
                    item.addons.some((addon) => addon.addonTitle.toLowerCase() === 'size')
                );
                setAddonList(productsWithSizeAddon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Function for uploading a file
    const handleFileUpload = (file) => {
        if (!file) {
            alert("Please select a file.");
            return;
        }

        const allowedFileTypes = [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];
        const maxSizeInMB = 5;

        if (!allowedFileTypes.includes(file.type)) {
            alert("Invalid file type. Please upload a Excel file.");
            return;
        }

        if (file.size > maxSizeInMB * 1024 * 1024) {
            alert(`File size should not exceed ${maxSizeInMB} MB.`);
            return;
        }
        setFile(file);
        parseExcelFile(file);
    };

    // Parse Excel file and extract name, mobile fields
    const parseExcelFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const userIds = rows
                .filter((row, index) => index !== 0 && row[5] !== "ID") // Skip the first row or any row with "ID" in the _id column
                .map(row => row[5]) // Assuming _id is in column 5
                .filter(id => id); // Filter out any empty or undefined _id values

            setUsersFromFile(userIds);
        };
        reader.readAsArrayBuffer(file);
    };

    const filteredAddons = selections?.selectedApplicableProducts
        ? addonList.filter(rm => rm.product._id === selections?.selectedApplicableProducts[0]?.value)
        : [];
    const updatedFilteredAddons = filteredAddons?.map((item) => item.addons?.map((mm) => mm));

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
    };

    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };

    const getDayOfWeek = (index) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[index] || "Invalid day";
    };

    // Function to get paginated data
    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData.slice(startIndex, endIndex);
    };

    const handleChange = (key, value) => {
        setSelections((prev) => ({
            ...prev,
            [key]: value,
        }));

        if (key === "selectedApplicableCategories") {
            getProductsList(value);
        }
    };

    useEffect(() => {
        getUsersList();
        getProductsList();
        getCategoryList();
        fetchData();
        getAddonProductsList();
        getOutlets();
        getLevels();
    }, []);

    useEffect(() => {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : "";
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : "";

        setCouponData((prev) => ({
            ...prev,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
        }));
    }, [fromDate, toDate]);

    useEffect(() => {
        const result = userCouponAllocationData.filter((item) => {
            return (
                item?.coupon_id?.couponCode?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
                item?.coupon_id?.title?.toLowerCase().match(searchText.toLocaleLowerCase()) ||
                item?.coupon_id?.usageType?.toLowerCase().match(searchText.toLocaleLowerCase())
            );
        });
        setFilteredData(result);
    }, [searchText]);

    return (
        <CContainer>
            <div className="mb-3 text-end">
                <button
                    className="btn btn-warning"
                    onClick={handleAddNewOpenModal}
                    style={{ borderRadius: "18px" }}
                >
                    ADD NEW USER COUPON <FaPlus style={{ marginTop: "-4px" }} size={15} />
                </button>
            </div>

            <div>
                <DataTableComponent
                    columns={updatedColumns}
                    data={getPaginatedData()}
                    title="USER COUPON ALLOCATION LIST"
                    searchText={searchText}
                    onChange={handleSearchChange}
                    pagination
                    paginationServer={false}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onChangePage={handlePageChange}
                    totalRows={filteredData?.length}
                    paginationTotalRows={filteredData.length}
                />
            </div>

            <CModal
                alignment="center"
                visible={addNewOpenModal}
                onClose={handleCloseAddNewOpenModal}
                aria-labelledby="VerticallyCenteredExample"
                size='xl'
            >
                <CModalHeader>
                    <CModalTitle>ADD USER COUPON ALLOCATION</CModalTitle>
                </CModalHeader>
                <CForm onSubmit={handleSubmitCoupon}>
                    <CModalBody>
                        <CRow className="mb-3">
                            <CCol md={12} className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Upload File ( Excel )
                                </CFormLabel>
                                <CFormInput
                                    type="file"
                                    accept=".xls, .xlsx" // Only accept PDF and Excel files
                                    onChange={(e) => handleFileUpload(e.target.files[0])}
                                />
                            </CCol>

                            <CCol md={12} className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Users
                                </CFormLabel>
                                <Select
                                    isDisabled={file}
                                    isMulti
                                    components={animatedComponents}
                                    options={usersInfo}
                                    value={selectedUsers || []}
                                    onChange={handleSelectUsersChange}
                                    onInputChange={(query) => {
                                        debouncedGetUsersList(query);
                                    }}
                                    placeholder="Select Users"
                                    closeMenuOnSelect={false}
                                    styles={{
                                        ...customStyles,
                                        control: (base) => ({
                                            ...base,
                                            // minHeight: '100px', // Set the height
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>

                            <CCard className="mb-4">
                                <CCardBody>
                                    <CRow className="g-3">
                                        {checkboxes?.map(({ id, label, field }) => (
                                            <CCol md={3} key={id}>
                                                <CFormCheck
                                                    type="checkbox"
                                                    id={id}
                                                    label={label}
                                                    checked={couponData[field]}
                                                    onChange={handleCheckboxChange(field)}
                                                    className="custom-checkbox"
                                                />
                                            </CCol>
                                        ))}
                                    </CRow>
                                </CCardBody>
                            </CCard>

                            {/* TITLE FIELD  */}
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Title <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        placeholder="Title"
                                        name="title"
                                        value={couponData.title || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            {/* COUPON CODE  */}
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Coupon Code <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        placeholder="Coupon Code"
                                        name="couponCode"
                                        value={couponData.couponCode || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            {/* VALIDITY START DATE  */}
                            <CCol className='mb-3' md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Validity Start Date <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={handleFromDateChange}
                                        placeholderText="Start Date"
                                        className="form-control"
                                    />
                                </div>
                            </CCol>

                            {/* VALIDITY END DATE  */}
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Validity End Date <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        placeholderText="End Date"
                                        className="form-control"
                                    />
                                </div>
                            </CCol>

                            {/* USAGE TYPE  */}
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Usage Type <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                        name="usageType"
                                        value={couponData.usageType}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Usage</option>
                                        <option value="ONE-TIME">ONE-TIME</option>
                                        <option value="MULTIPLE">MULTIPLE</option>
                                    </CFormSelect>
                                </div>
                            </CCol>

                            {/* SELECT DAY OF THE WEEK  */}
                            <CCol md={3}>
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Select Day of a week
                                    </CFormLabel>
                                    <CFormSelect
                                        size="sm"
                                        onChange={handleInputChange}
                                        name="dayOfWeek"
                                        className="mb-3"
                                        aria-label="Small select example"
                                        value={couponData?.dayOfWeek}
                                    >
                                        <option value="">Select Day</option>
                                        <option value="0">SUNDAY</option>
                                        <option value="1">MONDAY</option>
                                        <option value="2">TUESDAY</option>
                                        <option value="3">WEDNESDAY</option>
                                        <option value="4">THURSDAY</option>
                                        <option value="5">FRIDAY</option>
                                        <option value="6">SATURDAY</option>
                                        <option value="all">ANY DAY OF THE WEEK</option>
                                    </CFormSelect>
                                </div>
                            </CCol>

                            {/* START TIME */}
                            <CCol md="3" lg="3">
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Start Time
                                    </CFormLabel>
                                    <CFormInput
                                        type="time"
                                        placeholder="Opening Time"
                                        className="form-control"
                                        name="startTime"
                                        value={couponData.startTime}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            {/* END TIME  */}
                            <CCol md="3" lg="3">
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        End Time
                                    </CFormLabel>
                                    <CFormInput
                                        type="time"
                                        placeholder="Closing Time"
                                        className="form-control"
                                        name="endTime"
                                        value={couponData.endTime}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </CCol>

                            {/* SELECT INCLUDED OUTLETS */}
                            <MultiSelectComponent
                                label="Select Included Outlets"
                                placeholder="Select Included Outlets"
                                options={outletData.filter(
                                    (outlet) =>
                                        !selections?.selectedExcludedOutlets?.some(
                                            (excludedOutlet) => excludedOutlet.value === outlet.value
                                        )
                                )}
                                value={selections?.selectedIncludedOutlets || []}
                                onChange={(value) => handleChange("selectedIncludedOutlets", value)}
                                isDisabled={!!selections?.selectedExcludedOutlets?.length}
                            />

                            {/* SELECT EXCLUDED OUTLETS */}
                            <MultiSelectComponent
                                label="Select Excluded Outlets"
                                placeholder="Select Excluded Outlets"
                                value={selections.selectedExcludedOutlets || []}
                                onChange={(value) => handleChange("selectedExcludedOutlets", value)}
                                options={outletData.filter(
                                    (outlet) =>
                                        !selections.selectedIncludedOutlets?.some(
                                            (includedOutlet) => includedOutlet.value === outlet.value
                                        )
                                )}
                                isDisabled={!!selections.selectedIncludedOutlets?.length}
                            />

                            {/* SELECT APPLICABLE LEVELS */}
                            <MultiSelectComponent
                                label="Select Applicable Levels"
                                placeholder="Select Applicable Levels"
                                value={selections.selectedApplicableLevels || []}
                                onChange={(value) => handleChange("selectedApplicableLevels", value)}
                                options={levelsData}
                            />

                            {/* SELECT APPLICABLE PRODUCTS ONLY FOR SIZE UPGRADE COUPON */}
                            {
                                couponData.isSizeUpgradeCoupon && <CCol md="6" className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Product <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        options={addonList.map((rm) => ({
                                            value: rm?.product?._id,
                                            label: rm?.product?.name,
                                        }))}
                                        value={
                                            selections.selectedApplicableProducts.length > 0
                                                ? addonList
                                                    .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                                                    .find((option) => option.value === selections.selectedApplicableProducts?.value)
                                                : null
                                        }
                                        onChange={(value) => handleChange("selectedApplicableProducts", value ? [value] : [])}
                                        classNamePrefix="react-select"
                                        isClearable
                                        placeholder="Select Product"
                                    />
                                </CCol>
                            }

                            {/* SELECT APPLICABLE PRODUCTS */}
                            {
                                !couponData.isSizeUpgradeCoupon && <MultiSelectComponent
                                    label=" Select Applicable Products"
                                    placeholder=" Select Applicable Products"
                                    value={selections.selectedApplicableProducts || []}
                                    onChange={(value) => handleChange("selectedApplicableProducts", value)}
                                    options={productsInfo}
                                    isDisabled={!!selections.selectedApplicableCategories?.length || couponData.isBogoCoupon || couponData?.isComboCategoryCoupon}
                                />
                            }

                            {/* SELECT APPLICABLE CATEGORIES */}
                            <MultiSelectComponent
                                label="Select Applicable Categories"
                                placeholder="Select Applicable Categories"
                                value={selections.selectedApplicableCategories || []}
                                onChange={(value) => handleChange("selectedApplicableCategories", value)}
                                options={categoriesData}
                                isDisabled={!!selections.selectedApplicableProducts?.length || couponData?.isSizeUpgradeCoupon || couponData?.isComboCategoryCoupon}
                            />

                            {/*SELECT DEPENDETNT CATEGORIES */}
                            <MultiSelectComponent
                                label="Select Dependent Categories"
                                placeholder="Select Dependent Categories"
                                value={selections.selectedDependentCategories || []}
                                onChange={(value) => handleChange("selectedDependentCategories", value)}
                                options={categoriesData}
                                isDisabled={couponData.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isComboCategoryCoupon}
                            />

                            {/* SELECT DEPENDENT PRODUCTS */}
                            <MultiSelectComponent
                                label="Select Dependent Products"
                                placeholder="Select Dependent Products"
                                value={selections.selectedDependentProducts || []}
                                onChange={(value) => handleChange("selectedDependentProducts", value)}
                                options={productsInfo}
                                isDisabled={couponData.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isComboCategoryCoupon}
                            />


                            {/* SELECT EXCLUDED PRODUCTS */}
                            <MultiSelectComponent
                                label="Select Excluded Products"
                                placeholder="Select Excluded Products"
                                value={selections.selectedExcludedProducts || []}
                                onChange={(value) => handleChange("selectedExcludedProducts", value)}
                                options={exProductsInfo}
                                isDisabled={couponData.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isComboCategoryCoupon || couponData?.isMrpDiscount}
                            />

                            {/* PRIMARY COMBO CATEGORIES */}
                            <MultiSelectComponent
                                label="Select Primary Combo Categories"
                                placeholder="Select Primary Combo Categories"
                                value={selections.primaryComboCategories || []}
                                onChange={(value) => handleChange("primaryComboCategories", value)}
                                options={categoriesData.filter(
                                    (category) =>
                                        !selections.secondaryComboCategories?.some(
                                            (cat) => cat.value === category.value
                                        )
                                )}
                                isDisabled={couponData.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isMrpDiscount}
                            />

                            {/* SECONDARY COMBO CATEGORIES  */}
                            <MultiSelectComponent
                                label="Select Secondary Combo Categories"
                                placeholder="Select Secondary Combo Categories"
                                value={selections.secondaryComboCategories || []}
                                onChange={(value) => handleChange("secondaryComboCategories", value)}
                                options={categoriesData.filter(
                                    (category) =>
                                        !selections.primaryComboCategories?.some(
                                            (cat) => cat.value === category.value
                                        )
                                )}
                                isDisabled={couponData.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isMrpDiscount}
                            />

                            {/*SELECT SIZE UPGRADE ADDON */}
                            <CCol md="3">
                                <CFormLabel className="fw-semibold">
                                    Select Size Upgrade Addon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="sizeUpgradeAddon"
                                    value={couponData.sizeUpgradeAddon}
                                    onChange={(e) => setCouponData((prev) => ({
                                        ...prev,
                                        sizeUpgradeAddon: e.target.value,
                                    }))}
                                    disabled={couponData?.isComboCategoryCoupon || couponData?.isBogoCoupon || couponData?.isMrpDiscount}
                                >
                                    <option value="">Select Size Upgrade Addon</option>
                                    <option key={updatedFilteredAddons[0]?.[0]?.addonId} value={updatedFilteredAddons[0]?.[0]?.addonId}>
                                        {updatedFilteredAddons[0]?.[0]?.addonTitle}
                                    </option>
                                </CFormSelect>
                            </CCol>

                            {/* HIDE COUPON  */}
                            <CCol className='mb-3' md={3}>
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={couponData.isHidden}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </CCol>

                            {/* Max Addons Allowed */}
                            <CCol md={3} className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Max Addons Allowed
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    placeholder="Max Addons Allowed"
                                    className="no-arrows"
                                    type="number"
                                    name="maxAddonsAllowed"
                                    value={couponData.maxAddonsAllowed || null}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) {
                                            handleInputChange(e);
                                        }
                                    }}
                                    disabled={couponData.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isMrpDiscount}
                                />
                            </CCol>

                            {/* Discount Percent */}
                            <CCol md={3}>
                                <CFormLabel className="fw-semibold">
                                    Discount Percent <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    max={100}
                                    disabled={
                                        couponData?.discountAmount || couponData?.discountPrice || couponData?.isBogoCoupon || couponData.isSizeUpgradeCoupon
                                    }
                                    placeholder="Discount Percent"
                                    className="no-arrows"
                                    type="number"
                                    name="discountPercent"
                                    value={couponData.discountPercent || ""}
                                    onChange={handleInputChange}
                                />
                            </CCol>

                            {/* Maximum Discount Amount */}
                            <CCol md={3}>
                                <CFormLabel className="fw-semibold">
                                    Max. Dis. Amount
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    disabled={couponData.isBogoCoupon || couponData.isSizeUpgradeCoupon || couponData.discountAmount !== "" || couponData.discountPrice}
                                    placeholder="Max. Dis. Amount"
                                    type="number"
                                    className="no-arrows"
                                    name="maxDiscountAmount"
                                    value={couponData.maxDiscountAmount}
                                    onChange={handleInputChange}
                                />
                            </CCol>

                            {/* Discount Amount */}
                            <CCol md={3}>
                                <CFormLabel className="fw-semibold">
                                    Discount Amount <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    step={0.01}
                                    disabled={
                                        couponData?.discountPercent || couponData?.discountPrice || couponData?.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isMrpDiscount
                                    }
                                    placeholder="Discount Amount"
                                    className="no-arrows"
                                    type="number"
                                    name="discountAmount"
                                    value={couponData.discountAmount ?? ""}
                                    onChange={handleInputChange}
                                />
                            </CCol>

                            {/* Discount Price */}
                            <CCol md={3}>
                                <CFormLabel className="fw-semibold">
                                    Discount Price <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    step={0.01}
                                    disabled={
                                        couponData?.discountAmount || couponData?.discountPercent || couponData?.isBogoCoupon || couponData.isSizeUpgradeCoupon || couponData?.isMrpDiscount
                                    }
                                    placeholder="Discount Price"
                                    className="no-arrows"
                                    type="number"
                                    name="discountPrice"
                                    value={couponData.discountPrice || ""}
                                    onChange={handleInputChange}
                                />
                            </CCol>

                            {/* Applicable Quantity Type */}
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Applicable Quantity Type <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                        name="applicableQuantityType"
                                        value={couponData.applicableQuantityType || ""}
                                        onChange={handleInputChange}
                                        disabled={
                                            couponData?.discountPercent || couponData?.discountAmount || couponData?.isBogoCoupon || couponData.isSizeUpgradeCoupon || couponData?.isMrpDiscount
                                        }
                                    >
                                        <option value="">Select Applicable Quantity Type</option>
                                        <option value="SINGLE">SINGLE</option>
                                        {
                                            !couponData?.isComboCategoryCoupon && <option value="MULTIPLE">MULTIPLE</option>
                                        }
                                    </CFormSelect>
                                </div>
                            </CCol>

                            {/* Min. Order Amount */}
                            <CCol md={3} lg={3} className='mt-3'>
                                <CFormLabel className="fw-semibold">
                                    Min. Order Amount
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    placeholder="Min. Order Amount"
                                    type="number"
                                    className="no-arrows"
                                    name="minOrderAmount"
                                    value={couponData.minOrderAmount}
                                    onChange={handleInputChange}
                                />
                            </CCol>

                            {/* Is Mrp Coupon? */}
                            <CCol md="3" className='mt-3' >
                                <CFormLabel className="fw-semibold">
                                    Is Mrp Coupon? <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isMrpDiscount"
                                    value={couponData.isMrpDiscount}
                                    onChange={handleInputChange}
                                    disabled={couponData?.isBogoCoupon || couponData?.isSizeUpgradeCoupon || couponData?.isComboCategoryCoupon}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </CCol>

                            {/* Description */}
                            <CCol md={12} className='mt-3'>
                                <CFormLabel className="fw-semibold">
                                    Description <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormTextarea
                                    rows={2}
                                    placeholder="Description"
                                    name="description"
                                    value={couponData.description || ""}
                                    onChange={handleInputChange}
                                />
                            </CCol>
                        </CRow>

                        <CRow>
                            {/* Other fields */}
                            <CCol md="12" className="mb-3">
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Terms and Conditions <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <div className="d-flex mb-2">
                                        <CFormInput
                                            placeholder="Add a term"
                                            value={currentTerm}
                                            onChange={(e) => setCurrentTerm(e.target.value)}
                                            className="me-2"
                                        />
                                        <CButton disabled={!currentTerm.trim()} onClick={addTerm}>ADD</CButton>
                                    </div>
                                    {couponData?.termsAndConditions?.length > 0 && (
                                        <ul className="list-group">
                                            {couponData?.termsAndConditions?.map((term, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                >
                                                    {term}
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger rounded-pill"
                                                        onClick={() => removeTerm(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <PrimaryButton
                            isLoading={isLoading}
                            title="ADD USER COUPON ALLOCATION"
                        />
                    </CModalFooter>
                </CForm>
            </CModal>

            <CModal
                alignment="center"
                visible={openViewModal}
                onClose={() => setOpenViewModal(false)}
                aria-labelledby="VerticallyCenteredExample"
                size='xl'
            >
                <CModalHeader>
                    <CModalTitle
                        id="VerticallyCenteredExample"
                        className="fs-7 fw-semibold"
                    >
                        VIEW USER COUPON ALLOCATION DETAILS
                    </CModalTitle>
                </CModalHeader>
                <CModalBody style={{ backgroundColor: "#f8f9fa" }}>
                    <CTable className="table table-bordered">
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell className="text-center" colSpan={7}>
                                    COUPON DETAILS
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow>
                                <CTableDataCell className='w-50 fw-semibold'>
                                    Title
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.title}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Description
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.description}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Coupon Code
                                </CTableDataCell>
                                <CTableDataCell className='fw-semibold' style={{ color: 'blueviolet' }}>
                                    {couponDetails?.couponCode}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Usage-Type
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.usageType}
                                </CTableDataCell>
                            </CTableRow>

                            {
                                couponDetails?.dayOfWeek === null && <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Applicable Days
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        On any day of the week
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.dayOfWeek && <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Applicable Days
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {couponDetails?.dayOfWeek !== undefined ? getDayOfWeek(couponDetails.dayOfWeek) : couponDetails?.dayOfWeek === 'all' ? 'On any day of the week' : "N/A"}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.maxAddonsAllowed && <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Maximum Addon Allowed
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {couponDetails?.maxAddonsAllowed}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    BOGO Coupon
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.isBogoCoupon ? <span className='text-success fw-semibold'>YES </span> : <span className='text-danger fw-semibold'>NO </span>}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Size Upgrade Coupon
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.sizeUpgradeAddon ? <span className='text-success fw-semibold'>YES </span> : <span className='text-danger fw-semibold'>NO </span>}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Combo Category Coupon
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.isComboCategoryCoupon ? <span className='text-success fw-semibold'>YES </span> : <span className='text-danger fw-semibold'>NO </span>}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    MRP Coupon
                                </CTableDataCell>
                                <CTableDataCell>
                                    {couponDetails?.isMrpDiscount ? <span className='text-success fw-semibold'>YES </span> : <span className='text-danger fw-semibold'>NO </span>}
                                </CTableDataCell>
                            </CTableRow>
                            {
                                couponDetails?.applicableCategories?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Applicable Categories
                                    </CTableDataCell>
                                    {
                                        couponDetails?.applicableCategories?.length > 0 && <CTableDataCell>
                                            {couponDetails?.applicableCategories.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.applicableProducts?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Applicable Products
                                    </CTableDataCell>
                                    {
                                        couponDetails?.applicableProducts?.length > 0 && <CTableDataCell>
                                            {couponDetails?.applicableProducts.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }

                            {
                                couponDetails?.applicableLevels?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Applicable Levels
                                    </CTableDataCell>
                                    {
                                        couponDetails?.applicableLevels?.length > 0 && <CTableDataCell>
                                            {couponDetails?.applicableLevels.map((item) => item?.title).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.dependantCategories?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Dependant Categories
                                    </CTableDataCell>
                                    {
                                        couponDetails?.dependantCategories?.length > 0 && <CTableDataCell>
                                            {couponDetails?.dependantCategories.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.dependantProducts?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Dependant Products
                                    </CTableDataCell>
                                    {
                                        couponDetails?.dependantProducts?.length > 0 && <CTableDataCell>
                                            {couponDetails?.dependantProducts.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.includedOutlets?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Included Outlets
                                    </CTableDataCell>
                                    {
                                        couponDetails?.includedOutlets?.length > 0 && <CTableDataCell>
                                            {couponDetails?.includedOutlets.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.excludedOutlets?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Excluded Outlets
                                    </CTableDataCell>
                                    {
                                        couponDetails?.excludedOutlets?.length > 0 && <CTableDataCell>
                                            {couponDetails?.excludedOutlets.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.primaryComboCategories?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Primary Combo Categories
                                    </CTableDataCell>
                                    {
                                        couponDetails?.primaryComboCategories?.length > 0 && <CTableDataCell>
                                            {couponDetails?.primaryComboCategories.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }
                            {
                                couponDetails?.secondaryComboCategories?.length > 0 &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Secondary Combo Categories
                                    </CTableDataCell>
                                    {
                                        couponDetails?.secondaryComboCategories?.length > 0 && <CTableDataCell>
                                            {couponDetails?.secondaryComboCategories.map((item) => item?.name).join(', ')}
                                        </CTableDataCell>
                                    }
                                </CTableRow>
                            }

                            {
                                couponDetails?.applicableQuantityType &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Applicable Quantity Type
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        {couponDetails?.applicableQuantityType}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.discountPrice &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Discount Price
                                    </CTableDataCell>
                                    <CTableDataCell className='text-success fw-semibold'>
                                        ₹. {couponDetails?.discountPrice}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.discountPercent &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Discount Percent
                                    </CTableDataCell>
                                    <CTableDataCell className='text-success fw-semibold'>
                                        {couponDetails?.discountPercent} % OFF
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.discountAmount &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Discount Amount
                                    </CTableDataCell>
                                    <CTableDataCell className='text-success fw-semibold'>
                                        ₹. {couponDetails?.discountAmount}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.minOrderAmount &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Minimum Order Amount
                                    </CTableDataCell>
                                    <CTableDataCell className='text-success fw-semibold'>
                                        ₹. {couponDetails?.minOrderAmount}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            {
                                couponDetails?.maxDiscountAmount &&
                                <CTableRow>
                                    <CTableDataCell className='fw-semibold'>
                                        Maximum Discount Amount
                                    </CTableDataCell>
                                    <CTableDataCell className='text-success fw-semibold'>
                                        ₹. {couponDetails?.maxDiscountAmount}
                                    </CTableDataCell>
                                </CTableRow>
                            }
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Start Time
                                </CTableDataCell>
                                <CTableDataCell className='fw-semibold' style={{ color: 'blueviolet' }}>
                                    {couponDetails?.startTime}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    End Time
                                </CTableDataCell>
                                <CTableDataCell className='fw-semibold' style={{ color: 'blueviolet' }}>
                                    {couponDetails?.endTime}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Valid From
                                </CTableDataCell>
                                <CTableDataCell>
                                    {formattedDate(couponDetails?.validityStartDate)}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell className='fw-semibold'>
                                    Valid Till
                                </CTableDataCell>
                                <CTableDataCell>
                                    {formattedDate(couponDetails?.validityEndDate)}
                                </CTableDataCell>
                            </CTableRow>
                        </CTableBody>
                    </CTable>

                    {couponDetails?.termsAndConditions?.length > 0 && (
                        <CTable className="table table-bordered">
                            <CTableHead>
                                <CTableRow>
                                    <CTableHeaderCell className="text-center" colSpan={7}>
                                        TERMS AND CONDITIONS
                                    </CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {couponDetails?.termsAndConditions?.map((term, index) => (
                                    <CTableRow key={index}>
                                        <CTableDataCell className="fw-semibold text-center">
                                            {index + 1}.
                                        </CTableDataCell>
                                        <CTableDataCell>{term}</CTableDataCell>
                                    </CTableRow>
                                ))}
                            </CTableBody>
                        </CTable>
                    )}

                    <CTable className="table table-bordered">
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell className="text-center" colSpan={7}>
                                    USERS DETAILS
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow>
                                <CTableHeaderCell className="text-center">
                                    SR. No
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    NAME
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    EMAIL ID
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    D.O.B
                                </CTableHeaderCell>
                                <CTableHeaderCell className="text-center">
                                    GENDER
                                </CTableHeaderCell>
                            </CTableRow>

                            {
                                couponUsers?.map((user, index) =>
                                    <CTableRow>
                                        <CTableDataCell className="text-center">
                                            {index + 1}.
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {user?.name}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {user?.email}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {user?.dob ? formattedDate(user?.dob) : "---"}
                                        </CTableDataCell>
                                        <CTableDataCell className="text-center">
                                            {user?.gender ? user.gender : "---"}
                                        </CTableDataCell>
                                    </CTableRow>
                                )
                            }
                        </CTableBody>
                    </CTable>

                </CModalBody>
                <CModalFooter style={{ backgroundColor: "#f8f9fa" }}>
                    <CButton
                        style={{ borderRadius: "18px" }}
                        className="btn btn-primary"
                        onClick={() => setOpenViewModal(false)}
                    >
                        CLOSE
                    </CButton>
                </CModalFooter>
            </CModal>

            <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteUserCouponAllocation} isLoading={isDeleteBtnLoading} title="DELETE USER COUPON ALLOCATION" />

            <Edit isVisible={openEditModal} selectedUsers={selectedUsers} setOpenEditModal={setOpenEditModal} setFile={setFile} setSelectedUsers={setSelectedUsers} setCouponData={setCouponData} setFromDate={setFromDate} setToDate={setToDate} setSelectedCategories={setSelectedCategories} setSelectedProducts={setSelectedProducts} userCouponId={userCouponId} fetchData={fetchData} selectedCategories={selectedCategories} selectedProducts={selectedProducts} addonList={addonList} getProductsList={getProductsList} usersInfo={usersInfo} handleSelectUsersChange={handleSelectUsersChange} debouncedGetUsersList={debouncedGetUsersList} categoriesData={categoriesData} handleSelectCategoryChange={handleSelectCategoryChange} productsInfo={productsInfo} handleSelectProductChange={handleSelectProductChange} editOutletData={editOutletData} outletData={outletData} levelsData={levelsData} updatingData={updatingData} setUserCouponId={setUserCouponId} exProductList={exProductList} />
        </CContainer>
    )
}

export default Add
