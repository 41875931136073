import React, { useEffect, useState } from 'react'
import { CButton, CCol, CForm, CFormCheck, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow, CFormTextarea } from '@coreui/react';
import { PrimaryButton } from '../../components';
import Select from "react-select";
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import MultiSelectComponent from '../../components/MultiSelectComponent';

const Edit = ({ isVisible, selectedUsers, setOpenEditModal, setFile, setSelectedUsers, setCouponData, setFromDate, setToDate, setSelectedCategories, setSelectedProducts, userCouponId, fetchData, selectedCategories, selectedProducts, addonList, getProductsList, usersInfo, handleSelectUsersChange, debouncedGetUsersList, categoriesData, handleSelectCategoryChange, productsInfo, handleSelectProductChange, editOutletData, levelsData, outletData, updatingData, setUserCouponId, exProductList }) => {
    const animatedComponents = makeAnimated();
    const accessToken = localStorage.getItem("authToken");
    const [editCouponData, setEditCouponData] = useState({
        isBogoCoupon: false,
        isSizeUpgradeCoupon: false,
        sizeUpgradeAddon: "",
        title: "",
        description: "",
        usageType: "",
        couponCode: "",
        discountPercent: "",
        dayOfWeek: "",
        discountAmount: "",
        validityStartDate: "",
        validityEndDate: "",
        startTime: "",
        endTime: "",
        discountPrice: "",
        applicableQuantityType: "",
        termsAndConditions: [], // Array to store terms
        isComboCategoryCoupon: false,
        maxAddonsAllowed: null,
        maxDiscountAmount: "",
        minOrderAmount: "",
        isMrpDiscount: false
    });
    const [currentTerm, setCurrentTerm] = useState('');
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [editSelections, setEditSelections] = useState({
        selectedIncludedOutlets: [],
        selectedExcludedOutlets: [],
        selectedApplicableLevels: [],
        primaryComboCategories: [],
        secondaryComboCategories: [],
        selectedDependentCategories: [],
        selectedDependentProducts: [],
        selectedExcludedProducts: [],
        selectedApplicableProducts: [],
        selectedApplicableCategories: []
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: '6px',
            minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d"
        }),
    };

    // Filters `addonList` by matching the product `_id` with the first value in `selectedProducts` if it's an array, or directly if it's a single value, otherwise returns an empty array.
    const editFilteredAddon = editSelections?.selectedApplicableProducts?.length
        ? addonList.filter((item) =>
            editSelections.selectedApplicableProducts.some(
                (selected) => item.product?._id === selected.value
            )
        )
        : [];

    console.log("editSelections?.selectedApplicableCategories", editSelections?.selectedApplicableCategories);


    const exProductsInfo = exProductList.map((pro) => ({
        value: pro?._id,
        label: `${pro?.name}`
    }));

    const assignExistingData = (data) => {
        setUserCouponId(data?._id);

        let selectedEditedUsers = data?.users || [];
        let mappedSelectedEditedUsers = selectedEditedUsers.map((m) => ({
            value: m?._id,
            label: m?.name
        }));
        setSelectedUsers(mappedSelectedEditedUsers);

        const formattedStartDate = data?.coupon_id?.validityStartDate ? format(data?.coupon_id?.validityStartDate, "yyyy-MM-dd") : null;

        const formattedEndDate = data?.coupon_id?.validityEndDate ? new Date(data?.coupon_id?.validityEndDate).toISOString().split("T")[0] : null;

        let selectedEditedApplicableCategories = data?.coupon_id?.applicableCategories ? data?.coupon_id?.applicableCategories : [];

        let mappedSelectedEditedApplicableCategories = selectedEditedApplicableCategories.map((m) => ({
            value: m?._id,
            label: m?.name
        }));

        let selectedEditedApplicableProducts = data?.coupon_id?.applicableProducts ? data?.coupon_id?.applicableProducts : [];

        let mappedSelectedEditedApplicableProducts = selectedEditedApplicableProducts.map((m) => ({
            value: m?._id,
            label: m?.name
        }));


        console.log("selectedEditedApplicableCategories", selectedEditedApplicableCategories);
        
        const formattedSelections = {};

        if (data?.coupon_id?.primaryComboCategories?.length || data?.coupon_id?.secondaryComboCategories?.length) {
            formattedSelections.primaryComboCategories = data?.coupon_id?.primaryComboCategories?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));

            formattedSelections.secondaryComboCategories = data?.coupon_id?.secondaryComboCategories?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));
        }

        if (data?.coupon_id?.includedOutlets?.length) {
            formattedSelections.selectedIncludedOutlets = data?.coupon_id?.includedOutlets?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));
        }

        if (data?.coupon_id?.applicableCategories?.length) {
            formattedSelections.selectedApplicableCategories = data?.coupon_id?.applicableCategories?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));
        }

        if (data?.coupon_id?.excludedOutlets?.length) {
            formattedSelections.selectedExcludedOutlets = data?.coupon_id?.excludedOutlets?.map((exc) => ({
                value: exc?._id,
                label: exc?.name,
            }));
        }

        if (data?.coupon_id?.applicableLevels?.length) {
            formattedSelections.selectedApplicableLevels = data?.coupon_id?.applicableLevels?.map((lvl) => ({
                value: lvl?._id,
                label: lvl?.title,
            }));
        }

        if (data?.coupon_id?.applicableProducts?.length) {
            formattedSelections.selectedApplicableProducts = data?.coupon_id?.applicableProducts?.map((lvl) => ({
                value: lvl?._id,
                label: lvl?.name,
            }));
        }
        if (data?.coupon_id?.dependantCategories?.length) {
            formattedSelections.selectedDependentCategories = data?.coupon_id?.dependantCategories?.map((lvl) => ({
                value: lvl?._id,
                label: lvl?.name,
            }));
        }
        if (data?.coupon_id?.dependantProducts?.length) {
            formattedSelections.selectedDependentProducts = data?.coupon_id?.dependantProducts?.map((lvl) => ({
                value: lvl?._id,
                label: lvl?.name,
            }));
        }
        if (data?.coupon_id?.excludedProducts?.length) {
            formattedSelections.selectedExcludedProducts = data?.coupon_id?.excludedProducts?.map((lvl) => ({
                value: lvl?._id,
                label: lvl?.name,
            }));
        }

        setEditSelections(formattedSelections);

        setEditCouponData({
            _id: data?.coupon_id?._id,
            title: data?.coupon_id?.title,
            isHidden: data?.coupon_id?.isHidden,
            couponCode: data?.coupon_id?.couponCode,
            description: data?.coupon_id?.description,
            validityStartDate: formattedStartDate,
            dayOfWeek: data?.coupon_id?.dayOfWeek,
            validityEndDate: formattedEndDate,
            startTime: data?.coupon_id?.startTime,
            endTime: data?.coupon_id?.endTime,
            termsAndConditions: data?.coupon_id?.termsAndConditions,
            isComboCategoryCoupon: data?.coupon_id?.isComboCategoryCoupon,
            isMrpDiscount: data?.coupon_id?.isMrpDiscount,
            usageType: data?.coupon_id?.usageType,
            ...(data?.coupon_id?.discountAmount !== null && { discountAmount: data?.coupon_id?.discountAmount }),
            ...(data?.coupon_id?.discountPercent !== null && { discountPercent: data?.coupon_id?.discountPercent }),
            ...(data?.coupon_id?.maxDiscountAmount !== null && { maxDiscountAmount: data?.coupon_id?.maxDiscountAmount }),
            ...(data?.coupon_id?.discountPrice !== null && { discountPrice: data?.coupon_id?.discountPrice }),
            ...(data?.coupon_id?.minOrderAmount !== null && { minOrderAmount: data?.coupon_id?.minOrderAmount }),
            ...(data?.coupon_id?.maxAddonsAllowed !== null && { maxAddonsAllowed: data?.coupon_id?.maxAddonsAllowed }),
            ...(data?.coupon_id?.applicableQuantityType !== null && { applicableQuantityType: data?.coupon_id?.applicableQuantityType }),
            ...(data?.coupon_id?.isBogoCoupon === true && { isBogoCoupon: data?.coupon_id?.isBogoCoupon }),
            ...(data?.coupon_id?.sizeUpgradeAddon !== null && { sizeUpgradeAddon: data?.coupon_id?.sizeUpgradeAddon, isSizeUpgradeCoupon: true })
        });
        setSelectedCategories(mappedSelectedEditedApplicableCategories);
        setSelectedProducts(mappedSelectedEditedApplicableProducts);
    }

    const handleChange = (key, value) => {
        setEditSelections((prev) => ({
            ...prev,
            [key]: value,
        }));

        if (key === "selectedApplicableCategories") {
            getProductsList(value);
        }
    };

    const addEditTerm = () => {
        if (!currentTerm.trim()) return;
        setEditCouponData((prevData) => ({
            ...prevData,
            termsAndConditions: Array.isArray(prevData.termsAndConditions)
                ? [...prevData.termsAndConditions, currentTerm]
                : [currentTerm], // Fallback to an array if it's not already one
        }));
        setCurrentTerm(''); // Clear input after adding
    };

    const removeEditTerm = (index) => {
        setEditCouponData((prevData) => ({
            ...prevData,
            termsAndConditions: Array.isArray(prevData.termsAndConditions)
                ? prevData.termsAndConditions.filter((_, i) => i !== index)
                : [], // Ensure it doesn't break if termsAndConditions is undefined
        }));
    };

    const handleEditValidStartDateChange = (date) => {
        if (date) {
            const formattedDate = format(new Date(date), "yyyy-MM-dd");
            setEditCouponData((prev) => ({
                ...prev,
                validityStartDate: formattedDate,
            }));
        } else {
            setEditCouponData((prev) => ({
                ...prev,
                validityStartDate: null,
            }));
        }
    };

    const handleEditValidEndDateChange = (date) => {
        if (date) {
            const formattedDate = format(new Date(date), "yyyy-MM-dd");
            setEditCouponData((prev) => ({
                ...prev,
                validityEndDate: formattedDate,
            }));
        } else {
            setEditCouponData((prev) => ({
                ...prev,
                validityEndDate: null,
            }));
        }
    };

    // Handles input changes for the edit form. Updates the state for `editCouponData` and `selectedProducts`
    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        // Update the editCouponData state
        setEditCouponData((prev) => ({
            ...prev,
            [name]: name === "applicableProducts" ? [value] : value,
        }));

        // Update the selectedProducts state
        if (name === "applicableProducts") {
            setSelectedProducts([{ value }]); // Ensuring it's an array with the selected value
        }
    };

    const submitEditForm = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            // const selectedCategoriesData = selectedCategories.map((c) => c.value);
            const selectedProductsData = selectedProducts.map((c) => c.value);

            const selectedIncludedOutlets = editSelections?.selectedIncludedOutlets?.map((option) => option.value) || [];
            const selectedExcludedOutlets = editSelections?.selectedExcludedOutlets?.map((option) => option.value) || [];
            const selectedApplicableLevels = editSelections?.selectedApplicableLevels?.map((option) => option.value) || [];
            const selectedPrimaryComboCategories = editSelections?.primaryComboCategories?.map((option) => option.value) || [];
            const selectedSecondaryComboCategories = editSelections?.secondaryComboCategories?.map((option) => option.value) || [];

            const selectedDependentCategories = editSelections?.selectedDependentCategories?.map((option) => option.value) || []

            const selectedDependentProducts = editSelections?.selectedDependentProducts?.map((option) => option.value) || []

            const selectedExcludedProducts = editSelections?.selectedExcludedProducts?.map((option) => option.value) || [];

            const selectedApplicableProducts = editSelections?.selectedApplicableProducts?.map((option) => option.value) || []

            const selectedApplicableCategories = editSelections?.selectedApplicableCategories?.map((option) => option.value) || []
 
            // Initialize updatedCouponData immediately
            const updatedCouponData = {
                ...editCouponData,
                applicableCategories: selectedApplicableCategories.length > 0 ? selectedApplicableCategories : null,
                primaryComboCategories: selectedPrimaryComboCategories.length > 0 ? selectedPrimaryComboCategories : null,
                secondaryComboCategories: selectedSecondaryComboCategories.length > 0 ? selectedSecondaryComboCategories : null,
                applicableLevels: selectedApplicableLevels.length > 0 ? selectedApplicableLevels : null,
                includedOutlets: selectedIncludedOutlets.length > 0 ? selectedIncludedOutlets : null,
                excludedOutlets: selectedExcludedOutlets.length > 0 ? selectedExcludedOutlets : null,
                dependantCategories: selectedDependentCategories.length > 0 ? selectedDependentCategories : null,
                dependantProducts: selectedDependentProducts.length > 0 ? selectedDependentProducts : null,
                excludedProducts: selectedExcludedProducts.length > 0 ? selectedExcludedProducts : null,
                applicableProducts: selectedApplicableProducts.length > 0 ? selectedApplicableProducts : null,
            };

            if (!updatedCouponData.excludedOutlets || updatedCouponData.excludedOutlets.length === 0) {
                updatedCouponData.excludedOutlets = null;
            }


            // Discount amount and percent conflict handling
            if (updatedCouponData?.discountAmount) delete updatedCouponData?.discountPercent;
            if (updatedCouponData?.discountPercent) delete updatedCouponData?.discountAmount;

            if (updatedCouponData.maxAddonsAllowed === "" || updatedCouponData.maxAddonsAllowed === undefined || updatedCouponData.maxAddonsAllowed === null) {
                updatedCouponData.maxAddonsAllowed = null
            }

            // if (updatedCouponData?.dayOfWeek === "" || updatedCouponData?.dayOfWeek === "all") {
            //     delete updatedCouponData.dayOfWeek
            // }


            Object.keys(updatedCouponData).forEach((key) => {
                // If 'dayOfWeek' is "all", set it to null
                if (key === 'dayOfWeek' && updatedCouponData[key] === "all") {
                    updatedCouponData[key] = null;
                }

                // Convert empty values to null
                if (updatedCouponData[key] === "" || updatedCouponData[key] === undefined || updatedCouponData[key] === null) {
                    updatedCouponData[key] = null;
                }

                // Remove keys with null values
                // if (updatedCouponData[key] === null || updatedCouponData[key] === undefined) {
                //     delete updatedCouponData[key];
                // }
            });

            console.log("updatedCouponData", updatedCouponData);

            const response = await axios.put("coupons", updatedCouponData, { headers });

            if (response?.status === 200) {
                const couponId = response?.data?.data?.updatedCoupon?._id;
                await handleEditSubmit(couponId);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = '';
                if (error.response?.status === 406) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
                } else if (error.response?.status === 404 || error.response?.status === 412) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error;
                }
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    // Close Edit Modal and set coupon data as empty.
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setCouponData({
            isBogoCoupon: false,
            isSizeUpgradeCoupon: false,
            sizeUpgradeAddon: "",
            title: "",
            description: "",
            usageType: "",
            couponCode: "",
            discountPercent: "",
            discountAmount: "",
            validityStartDate: "",
            validityEndDate: "",
            discountPrice: "",
            applicableQuantityType: "",
            applicableProducts: []
        });
        setFile(null);
        setFromDate(null);
        setToDate(null);
        setSelectedCategories([]);
        setSelectedProducts([]);
        setSelectedUsers([])
    }

    // Handles the submission of user-coupon allocation after successfully updating the coupon data.
    const handleEditSubmit = async (couponId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const selectedUsersData = selectedUsers.map((c) => c.value);
            const data = {
                _id: userCouponId,
                users: selectedUsersData,
                coupon_id: couponId
            }

            const response = await axios.put("user-coupon-allocation", data, {
                headers,
            });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                handleCloseEditModal()
                fetchData()
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412 || error?.response?.status === 404
                ) {
                    errorMessage = error?.response?.data?.data?.title;
                } else if (error.response?.status === 500 || error?.response?.status === 400) {
                    errorMessage = error?.response?.data?.error
                }

                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    useEffect(() => {
        if (updatingData && isVisible) {
            console.log('Updating Data:', updatingData?.users);
            assignExistingData(updatingData);
        }
    }, [updatingData, isVisible]);
    
    return (
        <CModal
            size="xl"
            alignment="center"
            visible={isVisible}
            onClose={handleCloseEditModal}
            aria-labelledby="VerticallyCenteredExample"
        >
            <CModalHeader>
                <CModalTitle>EDIT USER COUPON ALLOCATION DETAILS</CModalTitle>
            </CModalHeader>
            <CForm onSubmit={submitEditForm}>
                <CModalBody>
                    <CRow className="mb-3">
                        <CCol md={12} className='mb-3'>
                            <CFormLabel className="fw-semibold">
                                Select Users <span className="text-danger">*</span>
                            </CFormLabel>
                            <Select
                                isMulti
                                components={animatedComponents}
                                options={usersInfo}
                                value={selectedUsers || []}
                                onChange={handleSelectUsersChange}
                                onInputChange={(query) => {
                                    debouncedGetUsersList(query); // Fetch user list based on input query
                                }}
                                placeholder="Select Users"
                                closeMenuOnSelect={false}
                                styles={{
                                    ...customStyles,
                                    control: (base) => ({
                                        ...base,
                                        // minHeight: '100px', // Set the height
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "#e9ecef",
                                        primary: "#007bff",
                                    },
                                })}
                            />
                        </CCol>
                        <CCol md={3} className="mb-3">
                            <CFormCheck
                                type="checkbox"
                                id="isBogoCoupon"
                                label="Is BOGO Coupon ?"
                                checked={updatingData?.coupon_id?.isBogoCoupon}
                                disabled={updatingData?.coupon_id?.isComboCategoryCoupon || updatingData?.coupon_id?.sizeUpgradeAddon !== null}
                            />
                        </CCol>
                        <CCol md={3} className="mb-3">
                            <CFormCheck
                                disabled={updatingData?.coupon_id?.isBogoCoupon || updatingData?.coupon_id?.isComboCategoryCoupon}
                                type="checkbox"
                                id="isSizeUpgradeCoupon"
                                label="Is Size Upgrade Coupon ?"
                                checked={updatingData?.coupon_id?.sizeUpgradeAddon || updatingData?.coupon_id?.sizeUpgradeAddon !== null}
                            />
                        </CCol>
                        <CCol md={3} className="mb-3">
                            <CFormCheck
                                disabled={updatingData?.coupon_id?.isBogoCoupon || updatingData?.coupon_id?.sizeUpgradeAddon !== null}
                                checked={updatingData?.coupon_id?.isComboCategoryCoupon}
                                type="checkbox"
                                id="isComboCategoryCoupon"
                                label="Is Combo Category Coupon ?"
                            />
                        </CCol>
                        <CCol md={3} className="mb-3">
                            <CFormCheck
                                disabled={updatingData?.coupon_id?.isBogoCoupon || updatingData?.coupon_id?.sizeUpgradeAddon !== null || updatingData?.coupon_id?.isComboCategoryCoupon}
                                checked={updatingData?.coupon_id?.isMrpDiscount}
                                type="checkbox"
                                id="isComboCategoryCoupon"
                                label="Is MRP Coupon ?"
                            />
                        </CCol>

                        {/* TITLE  */}
                        <CCol md={3}>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Title <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Title"
                                    name="title"
                                    value={editCouponData.title || ""}
                                    onChange={handleEditInputChange}
                                />
                            </div>
                        </CCol>

                        {/* COUPON CODE  */}
                        <CCol md={3}>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Coupon Code <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormInput
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    value={editCouponData.couponCode || ""}
                                    onChange={handleEditInputChange}
                                />
                            </div>
                        </CCol>

                        {/* {
                            !editCouponData.sizeUpgradeAddon && !editCouponData.isComboCategoryCoupon && <>
                                <CCol md={3} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Categories <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        // isDisabled={selectedCategories.length === 0}
                                        isDisabled={
                                            selectedProducts?.length > 0 || editCouponData?.isBogoCoupon || editCouponData.isSizeUpgradeCoupon
                                        }
                                        components={animatedComponents}
                                        options={categoriesData}
                                        value={selectedCategories || []}
                                        onChange={handleSelectCategoryChange}
                                        placeholder="Select Categories"
                                        closeMenuOnSelect={false}
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>

                                <CCol md={3} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Products <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        components={animatedComponents}
                                        // isDisabled={selectedProducts.length === 0}
                                        isDisabled={selectedCategories?.length > 0 || editCouponData?.isBogoCoupon}
                                        options={productsInfo}
                                        value={selectedProducts || []}
                                        onChange={handleSelectProductChange}
                                        placeholder="Select Products"
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>

                            </>
                        }

                        {
                            editCouponData?.sizeUpgradeAddon && (
                                <>
                                    <CCol md="3" className="mb-3">
                                        <CFormLabel className="fw-semibold">
                                            Select Product <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <Select
                                            options={addonList.map((rm) => ({
                                                value: rm?.product?._id,
                                                label: rm?.product?.name,
                                            }))}
                                            value={
                                                selectedProducts
                                                    ? addonList
                                                        .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                                                        .find((option) => option.value === selectedProducts[0]?.value)
                                                    : null
                                            }
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    handleEditInputChange({
                                                        target: { name: "applicableProducts", value: selectedOption.value }
                                                    });
                                                } else {
                                                    handleEditInputChange({
                                                        target: { name: "applicableProducts", value: "" }
                                                    });
                                                }
                                            }}
                                            classNamePrefix="react-select"
                                            isClearable
                                            placeholder="Select Product"
                                        />
                                    </CCol>

                                    <CCol md="3">
                                        <CFormLabel className="fw-semibold">
                                            Select Size Upgrade Addon <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect
                                            name="sizeUpgradeAddon"
                                            value={editCouponData.sizeUpgradeAddon || ""}
                                            onChange={(e) =>
                                                setEditCouponData((prev) => ({
                                                    ...prev,
                                                    sizeUpgradeAddon: e.target.value,
                                                }))
                                            }
                                        >
                                            <option value="">Select Size Upgrade Addon</option>
                                            {editFilteredAddon.length > 0 &&
                                                editFilteredAddon[0]?.addons?.map((addon) => (
                                                    <option key={addon.addonId} value={addon.addonId}>
                                                        {addon.addonTitle}
                                                    </option>
                                                ))}
                                        </CFormSelect>
                                    </CCol>
                                </>
                            )
                        } */}

                        {/* VALIDITY START DATE */}
                        <CCol className='mb-3' md={3}>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity Start Date
                                </CFormLabel>
                                <DatePicker
                                    onChange={handleEditValidStartDateChange}
                                    placeholderText="Start Date"
                                    className="form-control"
                                    selected={editCouponData?.validityStartDate ? new Date(editCouponData?.validityStartDate) : null}
                                />
                            </div>
                        </CCol>

                        {/* VALIDITY END DATE */}
                        <CCol md={3}>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Validity End Date
                                </CFormLabel>
                                <DatePicker
                                    selected={editCouponData?.validityEndDate ? new Date(editCouponData?.validityEndDate) : null}
                                    onChange={handleEditValidEndDateChange}
                                    placeholderText="End Date"
                                    className="form-control"
                                />
                            </div>
                        </CCol>

                        {/* USAGE TYPE  */}
                        <CCol md={3}>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Usage Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="usageType"
                                    value={editCouponData.usageType || "ONE-TIME"}
                                    onChange={handleEditInputChange}
                                >
                                    <option value="">Select Usage</option>
                                    <option value="MULTIPLE">MULTIPLE</option>
                                    <option value="ONE-TIME">ONE-TIME</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        {/* SELECT DAY OF THE WEEK  */}
                        <CCol md={3}>
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Select Day of a week
                                </CFormLabel>
                                <CFormSelect
                                    size="sm"
                                    onChange={handleEditInputChange}
                                    name="dayOfWeek"
                                    className="mb-3"
                                    aria-label="Small select example"
                                    value={editCouponData?.dayOfWeek === null ? "all" : editCouponData?.dayOfWeek}
                                >
                                    <option value="">Select Day</option>
                                    <option value="0">SUNDAY</option>
                                    <option value="1">MONDAY</option>
                                    <option value="2">TUESDAY</option>
                                    <option value="3">WEDNESDAY</option>
                                    <option value="4">THURSDAY</option>
                                    <option value="5">FRIDAY</option>
                                    <option value="6">SATURDAY</option>
                                    <option value="all">ANY DAY OF THE WEEK</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        {/* START TIME */}
                        <CCol md="3" lg="3">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    Start Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    placeholder="Opening Time"
                                    className="form-control"
                                    name="startTime"
                                    value={editCouponData.startTime}
                                    onChange={handleEditInputChange}
                                />
                            </div>
                        </CCol>

                        {/* END TIME  */}
                        <CCol md="3" lg="3">
                            <div className="mb-3">
                                <CFormLabel className="fw-semibold">
                                    End Time
                                </CFormLabel>
                                <CFormInput
                                    type="time"
                                    placeholder="Closing Time"
                                    className="form-control"
                                    name="endTime"
                                    value={editCouponData.endTime}
                                    onChange={handleEditInputChange}
                                />
                            </div>
                        </CCol>

                        {/* SELECT INCLUDED OUTLETS  */}
                        <MultiSelectComponent
                            label="Select Included Outlets"
                            placeholder="Select Included Outlets"
                            options={editOutletData}
                            value={editSelections?.selectedIncludedOutlets || []}
                            onChange={(value) => handleChange("selectedIncludedOutlets", value)}
                            isDisabled={!!editSelections?.selectedExcludedOutlets?.length}
                        />

                        {/* SELECT EXCLUDED OUTLETS */}
                        <MultiSelectComponent
                            label="Select Excluded Outlets"
                            placeholder="Select Excluded Outlets"
                            options={outletData.filter(
                                (outlet) =>
                                    !editSelections.selectedIncludedOutlets?.some(
                                        (includedOutlet) => includedOutlet.value === outlet.value
                                    )
                            )}
                            value={editSelections.selectedExcludedOutlets || []}
                            onChange={(value) => handleChange("selectedExcludedOutlets", value)}
                            isDisabled={!!editSelections?.selectedIncludedOutlets?.length}
                        />

                        {/* SELECT APPLICABLE LEVELS  */}
                        <MultiSelectComponent
                            label="Select Applicable Levels"
                            placeholder="Select Applicable Levels"
                            value={editSelections?.selectedApplicableLevels || []}
                            onChange={(value) => handleChange("selectedApplicableLevels", value)}
                            options={levelsData}
                        />

                        {/* SELECT APPLICABLE PRODUCTS ONLY FOR SIZE UPGRADE COUPON */}
                        {
                            editCouponData.isSizeUpgradeCoupon && <CCol md="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Applicable Product <span className="text-danger">*</span>
                                </CFormLabel>
                                <Select
                                    options={addonList.map((rm) => ({
                                        value: rm?.product?._id,
                                        label: rm?.product?.name,
                                    }))}
                                    value={editSelections.selectedApplicableProducts}
                                    onChange={(value) => handleChange("selectedApplicableProducts", value ? [value] : [])}
                                    classNamePrefix="react-select"
                                    isClearable
                                    placeholder="Select Product"
                                />
                            </CCol>
                        }

                        {/* SELECT APPLICABLE PRODUCTS */}
                        {
                            !editCouponData.isSizeUpgradeCoupon && <MultiSelectComponent
                                label=" Select Applicable Products"
                                placeholder=" Select Applicable Products"
                                value={editSelections.selectedApplicableProducts || []}
                                onChange={(value) => handleChange("selectedApplicableProducts", value)}
                                options={productsInfo}
                                isDisabled={!!editSelections.selectedApplicableCategories?.length || editCouponData.isBogoCoupon || editCouponData?.isComboCategoryCoupon}
                            />
                        }

                        {/* SELECT APPLICABLE CATEGORIES */}
                        <MultiSelectComponent
                            label="Select Applicable Categories"
                            placeholder="Select Applicable Categories"
                            value={editSelections.selectedApplicableCategories || []}
                            onChange={(value) => handleChange("selectedApplicableCategories", value)}
                            options={categoriesData}
                            isDisabled={!!editSelections.selectedApplicableProducts?.length || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isComboCategoryCoupon}
                        />

                        {/*  SELECT DEPENDENT CATEGORIES */}
                        <MultiSelectComponent
                            label="Select Dependent Categories"
                            placeholder="Select Dependent Categories"
                            value={editSelections.selectedDependentCategories || []}
                            onChange={(value) => handleChange("selectedDependentCategories", value)}
                            options={categoriesData}
                            isDisabled={editCouponData.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isComboCategoryCoupon}
                        />

                        {/* SELECT DEPENDENT PRODUCTS  */}
                        <MultiSelectComponent
                            label="Select Dependent Products"
                            placeholder="Select Dependent Products"
                            value={editSelections?.selectedDependentProducts || []}
                            onChange={(value) => handleChange("selectedDependentProducts", value)}
                            options={productsInfo}
                            isDisabled={editCouponData.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isComboCategoryCoupon}
                        />

                        {/* SELECT EXCLUDED PRODUCTS  */}
                        <MultiSelectComponent
                            label="Select Excluded Products"
                            placeholder="Select Excluded Products"
                            value={editSelections?.selectedExcludedProducts}
                            onChange={(value) => handleChange("selectedExcludedProducts", value)}
                            options={exProductsInfo}
                            isDisabled={editCouponData.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isComboCategoryCoupon || editCouponData?.isMrpDiscount}
                        />


                        {/* PRIMARY COMBO CATEGORIES */}
                        <MultiSelectComponent
                            label="Select Primary Combo Categories"
                            placeholder="Select Primary Combo Categories"
                            value={editSelections?.primaryComboCategories || []}
                            onChange={(value) => handleChange("primaryComboCategories", value)}
                            options={categoriesData.filter(
                                (category) =>
                                    !editSelections.secondaryComboCategories?.some(
                                        (cat) => cat.value === category.value
                                    )
                            )}
                            isDisabled={editCouponData.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isMrpDiscount}
                        />

                        {/* SECONDARY COMBO CATEGORIES  */}
                        <MultiSelectComponent
                            label="Select Secondary Combo Categories"
                            placeholder="Select Secondary Combo Categories"
                            value={editSelections.secondaryComboCategories || []}
                            onChange={(value) => handleChange("secondaryComboCategories", value)}
                            options={categoriesData.filter(
                                (category) =>
                                    !editSelections.primaryComboCategories?.some(
                                        (cat) => cat.value === category.value
                                    )
                            )}
                            isDisabled={editCouponData.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isMrpDiscount}
                        />

                        {/*SELECT SIZE UPGRADE ADDON */}
                        <CCol md="3">
                            <CFormLabel className="fw-semibold">
                                Select Size Upgrade Addon <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="sizeUpgradeAddon"
                                value={editCouponData.sizeUpgradeAddon}
                                onChange={(e) =>
                                    setEditCouponData((prev) => ({
                                        ...prev,
                                        sizeUpgradeAddon: e.target.value,
                                    }))
                                }
                                disabled={editCouponData?.isComboCategoryCoupon || editCouponData?.isBogoCoupon || editCouponData?.isMrpDiscount}
                            >
                                <option value="">Select Size Upgrade Addon</option>
                                {editFilteredAddon.length > 0 &&
                                    editFilteredAddon.flatMap((item) =>
                                        item.addons.map((addon) => (
                                            <option key={addon.addonId} value={addon.addonId}>
                                                {addon.addonTitle}
                                            </option>
                                        ))
                                    )
                                }
                            </CFormSelect>
                        </CCol>


                        {/* HIDE COUPON  */}
                        <CCol md="3" className='mb-3'>
                            <CFormLabel className="fw-semibold">
                                Hide Coupon <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="isHidden"
                                value={editCouponData.isHidden}
                                onChange={handleEditInputChange}
                            >
                                <option value="">Select Option</option>
                                <option value="true">YES</option>
                                <option value="false">NO</option>
                            </CFormSelect>
                        </CCol>

                        {/* IS MRP COUPON  */}
                        <CCol md="3">
                            <CFormLabel className="fw-semibold">
                                Is Mrp Coupon? <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormSelect
                                name="isMrpDiscount"
                                value={editCouponData.isMrpDiscount}
                                onChange={handleEditInputChange}
                                disabled={editCouponData?.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData?.isComboCategoryCoupon}
                            >
                                <option value="">Select Option</option>
                                <option value="true">YES</option>
                                <option value="false">NO</option>
                            </CFormSelect>
                        </CCol>

                        {/* DISCOUNT PERCENT  */}
                        <CCol md={3} className={editCouponData?.isMrpDiscount ? "md-4" : "md-3"}>
                            <CFormLabel className="fw-semibold">
                                Discount Percent <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                max={100}
                                disabled={
                                    editCouponData?.discountAmount || editCouponData?.discountPercent === undefined
                                }
                                placeholder="Discount Percent"
                                className="no-arrows"
                                type="number"
                                name="discountPercent"
                                value={editCouponData.discountPercent || ""}
                                onChange={handleEditInputChange}
                            />
                        </CCol>

                        {/* Maximum Discount Amount */}
                        <CCol md={3}>
                            <CFormLabel className="fw-semibold">
                                Max. Dis. Amount
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                disabled={editCouponData.isBogoCoupon || editCouponData.isSizeUpgradeCoupon || !!editCouponData.discountAmount || editCouponData.discountPrice}
                                placeholder="Max. Dis. Amount"
                                type="number"
                                className="no-arrows"
                                name="maxDiscountAmount"
                                value={editCouponData.maxDiscountAmount}
                                onChange={handleEditInputChange}
                            />
                        </CCol>

                        {/* MINIMUM ORDER AMOUNT  */}
                        <CCol className='mb-3' md={3} lg={3}>
                            <CFormLabel className="fw-semibold">
                                Min. Order Amount
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                placeholder="Min. Order Amount"
                                type="number"
                                className="no-arrows"
                                name="minOrderAmount"
                                value={editCouponData.minOrderAmount}
                                onChange={handleEditInputChange}
                            />
                        </CCol>

                        {/* MAXIMUM ADDONS ALLOWED  */}
                        <CCol md={3}>
                            <CFormLabel className="fw-semibold">
                                Max Addons Allowed <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                placeholder="Max Addons Allowed"
                                className="no-arrows"
                                type="number"
                                name="maxAddonsAllowed"
                                value={
                                    editCouponData.maxAddonsAllowed === null || editCouponData.maxAddonsAllowed === undefined
                                        ? undefined
                                        : editCouponData.maxAddonsAllowed
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                        handleEditInputChange(e);
                                    }
                                }}
                                disabled={editCouponData?.isBogoCoupon || editCouponData?.isSizeUpgradeCoupon || editCouponData.isMrpDiscount}
                            />
                        </CCol>

                        {/* DISCOUNT AMOUNT  */}
                        <CCol md={3} className='mb-3'>
                            <CFormLabel className="fw-semibold">
                                Discount Amount <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                step={0.01}
                                disabled={
                                    editCouponData?.discountPercent || editCouponData?.discountAmount === undefined
                                }
                                placeholder="Discount Amount"
                                className="no-arrows"
                                type="number"
                                name="discountAmount"
                                value={editCouponData.discountAmount || ""}
                                onChange={handleEditInputChange}
                            />
                        </CCol>

                        {/* DISCOUNT PRICE  */}
                        <CCol md={3}>
                            <CFormLabel className="fw-semibold">
                                Discount Price <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormInput
                                min={0}
                                step={0.01}
                                disabled={
                                    editCouponData?.discountAmount || editCouponData?.discountPercent || editCouponData?.discountPrice === undefined
                                }
                                placeholder="Discount Price"
                                className="no-arrows"
                                type="number"
                                name="discountPrice"
                                value={editCouponData.discountPrice || ""}
                                onChange={handleEditInputChange}
                            />
                        </CCol>

                        {/* APPLICABLE QUANTITY TYPE  */}
                        <CCol md={3} className='mb-3'>
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Applicable Quantity Type <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="applicableQuantityType"
                                    value={editCouponData.applicableQuantityType || ""}
                                    onChange={handleEditInputChange}
                                    disabled={
                                        editCouponData?.discountPercent || editCouponData?.discountAmount || editCouponData?.applicableQuantityType === undefined
                                    }
                                >
                                    <option value="">Select Applicable Quantity Type</option>
                                    <option value="SINGLE">SINGLE</option>\
                                    {
                                        !editCouponData?.isComboCategoryCoupon && <option value="MULTIPLE">MULTIPLE</option>
                                    }
                                    <option value="MULTIPLE">MULTIPLE</option>
                                </CFormSelect>
                            </div>
                        </CCol>

                        {/* DESCRIPTION  */}
                        <CCol md={12}>
                            <CFormLabel className="fw-semibold">
                                Description <span className="text-danger">*</span>
                            </CFormLabel>
                            <CFormTextarea
                                rows={2}
                                placeholder="Description"
                                name="description"
                                value={editCouponData.description || ""}
                                onChange={handleEditInputChange}
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        {/* Other fields */}
                        <CCol md="12" className="mb-3">
                            <div>
                                <CFormLabel className="fw-semibold">
                                    Terms and Conditions <span className="text-danger">*</span>
                                </CFormLabel>
                                <div className="d-flex mb-2">
                                    <CFormInput
                                        placeholder="Add a term"
                                        value={currentTerm}
                                        onChange={(e) => setCurrentTerm(e.target.value)}
                                        className="me-2"
                                    />
                                    <CButton disabled={!currentTerm.trim()} onClick={addEditTerm}>ADD</CButton>
                                </div>
                                {editCouponData?.termsAndConditions?.length > 0 && (
                                    <ul className="list-group">
                                        {editCouponData?.termsAndConditions?.map((term, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item d-flex justify-content-between align-items-center"
                                            >
                                                {term}
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-danger rounded-pill"
                                                    onClick={() => removeEditTerm(index)}
                                                >
                                                    Remove
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <PrimaryButton
                        title="SAVE CHANGES"
                        isLoading={isEditBtnLoading}
                        style={{ borderRadius: "18px" }}
                    />
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

export default Edit
