import React from "react";
import { CNavGroup, CNavItem } from "@coreui/react";
import { FaRegDotCircle } from "react-icons/fa";
import { GrProjects } from "react-icons/gr";
import { RiAdminLine, RiOutlet2Line, RiCoupon3Line } from "react-icons/ri";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GrSystem } from "react-icons/gr";
import { TbShoppingCartStar } from "react-icons/tb";
import { SiAwslambda, SiMaterialdesignicons } from "react-icons/si";
import { LiaUsersSolid } from "react-icons/lia";
import { LiaUsersCogSolid } from "react-icons/lia";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { AiOutlineControl } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
import { TbSquareRoundedLetterM } from "react-icons/tb";
import { MdOutlineDiscount } from "react-icons/md";
import { FaBorderNone } from "react-icons/fa";
import { FaUsersRays } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { ImCancelCircle } from "react-icons/im";
import { MdSystemUpdateAlt } from "react-icons/md";

const _nav = [
  {
    component: CNavItem,
    name: "DASHBOARD",
    to: "/dashboard",
    icon: (
      <MdOutlineDashboardCustomize
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },

  {
    component: CNavGroup,
    name: "MASTERS",
    to: "/masters",
    icon: (
      <TbSquareRoundedLetterM
        size={21}
        style={{
          margin: "0 20px 0 7px"
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ROLES",
        to: "/masters/admin-roles",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "ASSET TYPE",
        to: "/masters/asset-type",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "STORAGE LOCATION",
        to: "/masters/storage-type",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VENDORS.",
        to: "/masters/vendors",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
    ]
  },

  {
    component: CNavGroup,
    name: "MATERIAL MANAGEMENT",
    to: "/material-mgt",
    icon: (
      <SiMaterialdesignicons
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "RM MAIN CATEGORY",
        to: "/material-mgt/rm-main-category",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RM SUB CATEGORY",
        to: "/material-mgt/rm-sub-category",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RAW MATERIALS",
        to: "/material-mgt/rawmaterials",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "REPORTS",
    to: "/reports",
    icon: (
      <TbReportAnalytics 
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "CUSTOMER REPORTS",
        to: "/reports/customer-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "USER ANALYTICS REPORTS",
        to: "/reports/user-analytics-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ORDER REPORTS",
        to: "/reports/order-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "COUPONS REPORTS",
        to: "/reports/coupons-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "KISOK USERS REPORT",
        to: "/reports/kiosk-user-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "PRODUCT SALES REPORT",
        to: "/reports/product-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADDON SALES REPORT",
        to: "/reports/addon-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "ITEM WISE SALES REPORT",
        to: "/reports/item-wise-sales-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "DAY SUMMARY REPORT",
        to: "/reports/day-summary-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "MONTH SUMMARY REPORT",
        to: "/reports/month-summary-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "USER COUPON REPORTS",
        to: "/reports/user-coupon-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RM REORDER LEV. REPORT",
        to: "/reports/rm-reorder-level-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "ASSET MANAGEMENT",
    to: "/raw-materials-management",
    icon: (
      <SiAwslambda
        size={18}
        style={{
          margin: "0 20px 0 7px"
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD ASSETS",
        to: "/asset-management/assets",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ASSET TRANSFERS",
        to: "/asset-management/assets-stock-transaction",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "MENU",
    to: "/menu",
    icon: (
      <GrProjects
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "MENU CATEGORY",
        to: "/menu/categories",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADD MENU",
        to: "/menu/add-menu",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "MENU ADDONS MGT.",
        to: "/menu/menu-addon",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "MENU CATALOG",
        to: "/menu/menu-catalog",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      
      {
        component: CNavItem,
        name: "MENU INGREDIENTS",
        to: "/menu/menu-ingredients",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "USER LEVELS",
    to: "/user-levels",
    icon: (
      <MdOutlineDashboardCustomize
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD LEVELS",
        to: "/user-levels/level",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADD REWARDS",
        to: "/user-levels/add-reward",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VIEW LEVEL REWARDS",
        to: "/user-levels/view",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "OUTLET MGT.",
    to: "/outlet-management",
    icon: (
      <RiOutlet2Line
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),

    items: [
      {
        component: CNavItem,
        name: "ADD OUTLETS",
        to: "/outlet-management/outlets",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADD OUTLET USERS",
        to: "/outlet-management/outletusers",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADD KIOSK USRES",
        to: "/outlet-management/kisok-outlet-user",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
    ],
  },

  {
    component: CNavGroup,
    name: "PURCHASE / OUTWARDS",
    to: "/purchase",
    icon: (
      <TbShoppingCartStar
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD PURCHASE",
        to: "/purchase/add-purchase",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "PURCHASE LIST",
        to: "/purchase/purchase-list",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "STOR LOC ST. TRANSFER",
        to: "/purchase/storage-location-stock-transfer",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "STOCK TRANSFER",
        to: "/purchase/stock-transfer",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "STOCK REPORTS",
        to: "/purchase/rm-stock-reports",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "RM DAMAGE ENTRIES",
        to: "/purchase/rm-damage-entries",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ]
  },

  {
    component: CNavGroup,
    name: "TRANSACTIONS.",
    to: "/transaction",
    icon: (
      <FaMoneyBillTransfer
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ASSET STK. TRANS",
        to: "/transaction/asset-stock-transaction-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "CENTRAL STK. TRANS.",
        to: "/transaction/central-stock-transaction-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "OUTLET STK. TRANS.",
        to: "/transaction/stock-transaction-report",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
    ]
  },

  {
    component: CNavGroup,
    name: "SYSTEM PREFERENCE",
    to: "/system-preference",
    icon: (
      <GrSystem 
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "SETTINGS",
        to: "/system-preference/settings",
        icon: (
          <FaRegDotCircle 
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
      {
        component: CNavItem,
        name: "APP VERSIONS",
        to: "/system-preference/version-control",
        icon: (
          <FaRegDotCircle 
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
            customclassname="nav-icon"
          />
        ),
      },
    ]
  },



  // {
  //   component: CNavGroup,
  //   name: "MASTERS",
  //   to: "/masters",
  //   icon: (
  //     <TbSquareRoundedLetterM
  //       size={21}
  //       style={{
  //         margin: "0 20px 0 7px"
  //       }}
  //     />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "ADMIN ROLES",
  //       to: "/masters/admin-roles",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //           customclassname="nav-icon"
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "ASSET TYPE",
  //       to: "/masters/asset-type",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "CATEGORY MGT.",
  //       to: "/masters/categories",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "LEVEL",
  //       to: "/masters/level",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //           customclassname="nav-icon"
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "RM MAIN CATEGORY",
  //       to: "/masters/rm-main-category",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "RM SUB CATEGORY",
  //       to: "/masters/rm-sub-category",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "RAW MATERIALS",
  //       to: "/masters/rawmaterials",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //   ],
  // },
  

  {
    component: CNavItem,
    name: "CUSTOMER SUPPORT MGT",
    to: "/customer-support",
    icon: (
      <RiCustomerService2Line
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },

  {
    component: CNavItem,
    name: "CANCELATION REQUEST",
    to: "/cancellation-request",
    icon: (
      <ImCancelCircle
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },

  


  
  // {
  //   component: CNavGroup,
  //   name: "CENTRAL INVENTORY",
  //   to: "/central-inventory",
  //   icon: (
  //     <TbShoppingCartStar
  //       size={18}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //     />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "ADD PROCUREMENT",
  //       to: "/central-inventory/add-procurement",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "PROCUREMENT REPORTS",
  //       to: "/central-inventory/procurements-list",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "OUTLET STOCK TRANSFER",
  //       to: "/central-inventory/stock-transfer",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: "CENTRAL TRANSACTIONS",
  //     //   to: "/central-inventory/central-transactions",
  //     //   icon: (
  //     //     <FaRegDotCircle
  //     //       size={10}
  //     //       style={{
  //     //         margin: "0 5px 0 7px",
  //     //       }}
  //     //     />
  //     //   ),
  //     // },
  //     {
  //       component: CNavItem,
  //       name: "STOCK REPORTS",
  //       to: "/central-inventory/rm-stock-reports",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 7px",
  //           }}
  //         />
  //       ),
  //     },
      
  //   ],
  // },

  // {
  //   component: CNavGroup,
  //   name: "COUPONS",
  //   to: "/coupons",
  //   icon: (
  //     <RiCoupon3Line
  //       size={18}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //       customclassname="nav-icon"
  //     />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "ADD",
  //       to: "/coupons/add",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 8px",
  //           }}
  //         />
  //       ),
  //     },
  //     {
  //       component: CNavItem,
  //       name: "VIEW",
  //       to: "/coupons/view",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 8px",
  //           }}
  //         />
  //       ),
  //     },
  //   ],
  // },

  {
    component: CNavGroup,
    name: "COUPONS",
    to: "/create-coupon",
    icon: (
      <RiCoupon3Line
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "ADD",
        to: "/create-coupon/add",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 8px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VIEW",
        to: "/create-coupon/view",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 8px",
            }}
          />
        ),
      },
    ],
  },

  {
    component: CNavItem,
    name: "USER COUPON ALLOCATION",
    to: "/user-coupon-allocation",
    icon: (
      <MdOutlineDiscount
        size={20}
        style={{
          margin: "0 20px 0 7px",
        }}
        customclassname="nav-icon"
      />
    ),
  },


  // {
  //   component: CNavGroup,
  //   name: "USER COUPON ALLOCATION",
  //   to: "/user-coupon-allocation",
  //   icon: (
  //     <MdOutlineDiscount 
  //       size={18}
  //       style={{
  //         margin: "0 20px 0 7px",
  //       }}
  //       customclassname="nav-icon"
  //     />
  //   ),
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: "ADD",
  //       to: "/user-coupon-allocation/add",
  //       icon: (
  //         <FaRegDotCircle
  //           size={10}
  //           style={{
  //             margin: "0 5px 0 8px",
  //           }}
  //         />
  //       ),
  //     },
  //   ],
  // },
  
  {
    component: CNavGroup,
    name: "CONTENT MANAGEMENT",
    to: "/content-management",
    icon: (
      <TbShoppingCartStar
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [
      {
        component: CNavItem,
        name: "STORIES",
        to: "/content-management/stories",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "POP-UP ADVERTISEMENT",
        to: "/content-management/in-app-advertisement",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "ADD ADVERTISEMENT",
        to: "/content-management/add-advertisement",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        ),
      },
      {
        component: CNavItem,
        name: "VIEW ADVERTISEMENT",
        to: "/content-management/view-advertisement",
        icon: (
          <FaRegDotCircle
            size={10}
            style={{
              margin: "0 5px 0 7px",
            }}
          />
        )
      },

    ],
  },

  {
    component: CNavGroup,
    name: "SYSTEM LOGS",
    to: "/system-logs",
    icon: (
      <MdSystemUpdateAlt
        size={18}
        style={{
          margin: "0 20px 0 7px",
        }}
      />
    ),
    items: [

    ]
  }

  
  
  
];

export default _nav;
