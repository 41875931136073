import React, { useEffect, useState } from "react";
import { DataTableComponent, PrimaryButton } from "../../components";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import { FaCog, FaEye, FaTrash } from "react-icons/fa";
import Select from 'react-select';
import DataTable from "react-data-table-component";
import ReactDatePicker from "react-datepicker";
import { Dropdown, Spinner } from "react-bootstrap";
import makeAnimated from "react-select/animated";

const ProcurementCatalog = () => {
  const accessToken = localStorage.getItem("authToken");
  const animatedComponents = makeAnimated();
  const [procurementsList, setProcurementsList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [singleProcurementDetails, setSingleProcurementDetails] = useState({});
  const [singleProcurementItemsDetails, setSingleProcurementItemsDetails] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [touched, setTouched] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedStorageLocation, setSelectedStorageLocation] = useState('');
  const [openAddProcurementItemModal, setOpenAddProcurementItemModal] =
    useState(false);
  const [procurementId, setProcurementId] = useState("");
  const [isAddItemBtnLoading, setIsAddItemBtnLoading] = useState(false);
  const [addProcurementItemDetails, setAddProcurementItemDetails] = useState({
    material: "",
    quantity: "",
    rate: "",
    taxPercent: "",
    taxAmount: "",
    taxable: "",
    grandTotal: "",
    unit: ""
  });
  const [rawMaterialList, setRawMaterialList] = useState([]);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isStatusBtnLoading, setIsStatusBtnLoading] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [openProcurementItemEditModal, setOpenProcurementItemEditModal] =
    useState(false);
  const [editProcurementItemDetails, setEditProcurementItemDetails] = useState(
    {}
  );
  const [isEditItemBtnLoading, setIsEditItemBtnLoading] = useState(false);
  const [openProcurementItemDeleteModal, setOpenProcurementDeleteModal] =
    useState(false);
  const [procurementItemIds, setProcurmentItemIds] = useState([]);
  const [isItemDeleteBtnLoading, setIsItemDeleteBtnLoading] = useState(false);
  const [openUpdateStatusItemModal, setOpenUpdateStatusItemModal] =
    useState(false);
  const [itemId, setItemId] = useState([]);
  const [isItemStatusBtnLoading, setIsItemStatusBtnLoading] = useState(false);
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [openEditStatusWithRemarksModal, setOpenEditStatusWithRemarksModal] =
    useState(false);
  const [remark, setRemark] = useState("");
  const [isEditwithRemarkBtnLoading, setIsEditWithRemarkBtnLoading] =
    useState(false);

  const [editValidationErrors, setEditValidationErrors] = useState({
    vendor: "",
    idNumber: "",
    additionalCharges: "",
    scannedInvoice: "",
  });

  const [validateRemark, setValidateRemark] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState('1');
  const [recordsPerPage, setRecordsPerPage] = useState('10');
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [totalRecords, setTotalRecords] = useState('');
  const [storageLocationList, setStorageLocationList] = useState([]);
  const [filterState, setFilterState] = useState("");
  const [filterCity, setFilterCity] = useState("");

  const [editProcurementItemValidationErrors, setEditProcurementItemValidationErrors] = useState({
    material: "",
    quantity: "",
    rate: "",
  });

  const rawMaterialOptions = rawMaterialList.map((rm) => ({
    value: rm._id,
    label: rm.name
  }));

  const [filterLoading, setFilterLoading] = useState(false);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.serialNumber ? row.serialNumber : "--------"}
        </div>
      ),
      width: "70px",
    },

    {
      name: "FINANCIAL YEAR",
      selector: (row) => row.financialYear,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.financialYear ? row.financialYear : "--------"}
        </div>
      ),
      width: "160px",
    },

    {
      name: "DATE",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.createdAt
            ? new Date(row.createdAt).toLocaleDateString()
            : "--------"}
        </div>
      ),
      width: "110px",
    },

    {
      name: "ID NUMBER",
      selector: (row) => row.idNumber,
      cell: (row) => (
        <div style={{ cursor: 'pointer' }}>
          <div className="fs-7">{row.idNumber ? row.idNumber : "--------"}</div>
        </div>
      ),
      width: "120px",
    },

    {
      name: "VENDOR NAME",
      selector: (row) => row.vendor.name,
      cell: (row) => (
        <div className="fs-7" style={{ cursor: 'pointer' }}>
          {row.vendor.name ? row.vendor.name : "--------"}
        </div>
      ),
      width: "200px",
    },

    {
      name: "VENDOR CONTACT",
      selector: (row) => row.vendor.email,
      cell: (row) => (
        <div style={{ cursor: 'pointer' }}>
          <a href="#" className="fs-7" style={{ textDecoration: "none" }}>
            {row.vendor.email ? row.vendor.email : "--------"}
          </a>{" "}
          <br />
          <a href="#" className="fs-7" style={{ textDecoration: "none" }}>
            {row.vendor.phone ? row.vendor.phone : "--------"}
          </a>
        </div>
      ),
      width: "220px",
    },

    {
      name: "STATUS",
      selector: (row) => row.taxable,
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          className={`fs-7 ${row.status === "PENDING" ? "text-danger" : "text-success"
            }`}
        >
          {row.status ? row.status : "--------"}
        </div>
      ),
      width: "100px",
    },

    {
      name: "ACTIONS",
      right: "true",
      cell: (row) => (
        // <div className="d-flex justify-content-around align-items-center">
        //   {row.status === "PENDING" && (
        //     <a
        //       href="#"
        //       className="text-danger"
        //       style={{ cursor: "pointer", textDecoration: "none" }}
        //       onClick={() => handleDeleteModal(row)}
        //     >
        //       <FaTrash /> Delete
        //     </a>
        //   )}
        //   {row.status === "PENDING" && (
        //     <a
        //       href="#"
        //       className="text-primary ms-2"
        //       style={{ cursor: "pointer", textDecoration: "none" }}
        //       onClick={() => handleOpenUpdateStatusModal(row)}
        //     >
        //       | <FaCog /> Update |
        //     </a>
        //   )}
        //   <a
        //     href="#"
        //     className="text-info ms-2"
        //     style={{ cursor: "pointer", textDecoration: "none" }}
        //     onClick={() => handleOpenViewModal(row)}
        //   >
        //     <FaEye /> View
        //   </a>
        // </div>

        <div>
          <Dropdown popperConfig={{ modifiers: [{ name: 'preventOverflow', options: { boundary: 'viewport' } }] }}>
            <Dropdown.Toggle variant="secondary" size="sm" id={`dropdown-${row?.idNumber}`}>
              Actions {" "}
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                zIndex: 9999, 
                position: 'absolute',
                left: 0,
                top: '100%',
              }}
            >
              {row.status === "PENDING" && (
                <>
                  <Dropdown.Item onClick={() => handleDeleteModal(row)} className="text-danger">
                    Delete
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleOpenUpdateStatusModal(row)} className="text-primary">
                    Update
                  </Dropdown.Item>
                </>
              )}
              <Dropdown.Item onClick={() => handleOpenViewModal(row)} className="text-success">
                View
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const updatedColumns = columns.map((column) => ({
    ...column,
    name: (
      <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
    ),
  }));

  const getProcurements = async (page, limit, sDate, eDate, vendor, status, search, sort) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      let url = `procurement?page=${page}&limit=${limit}&fromDate=${sDate?.toISOString().split("T")[0]}&toDate=${eDate?.toISOString().split("T")[0]}`;

      if(vendor) {
        url += `&vendorId=${vendor}`
      }

      if(status) {
        url += `&status=${status}`
      }

      if(sort) {
        url += `&sort=${sort}`
      }

      if(search) {
        url += `&search=${search}`
      }

      console.log("Procurements URL :", url);
      

      const response = await axios.get(url, { headers });

      // const response = await axios.get("procurement", { headers });
      if (response?.data?.status === true) {
        console.log("procurements response :", response?.data?.data);
        
       // Dynamically assign serial numbers
      const startIndex = (page - 1) * limit; // Calculate the starting index
      const procList = response?.data?.data?.procurements.map((item, index) => ({
        ...item,
        serialNumber: startIndex + index + 1, // Adjust based on the current page
      }));

        setProcurementsList(procList);
        setFilteredData(procList);
        setTotalRecords(response?.data?.data?.pagination?.totalRecords);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setProcurementsList([]);
        setFilteredData([]);
      }
    }
  };

  const handleOpenViewModal = (data) => {
    getSingleProcurementDetails(data?._id);
    console.log("Procurements data :", data);
    
    setOpenViewModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const getSingleProcurementDetails = async (procurementId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`procurement?_id=${procurementId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        setSingleProcurementDetails(response?.data?.data?.procurements);
        setSingleProcurementItemsDetails(
          response?.data?.data?.procurementItems
        );

        console.log("response", response);

      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    setOpenViewModal(false);
    setEditedData({
      _id: data._id,
      vendor: data.vendor._id,
      idNumber: data.idNumber,
      scannedInvoice: data.scannedInvoice,
      additionalCharges: data.additionalCharges,
    });
    setOpenEditModal(true);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setInvoiceFile(selectedFile);
    setEditValidationErrors((prevErrors) => ({
      ...prevErrors,
      scannedInvoice: "",
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    // if (name === "additionalCharges") {
    //   // Ensure the value is a positive integer, removing any non-digit characters
    //   sanitizedValue = value.replace(/[^0-9]/g, '');
    // }

    setEditedData((prevState) => ({ ...prevState, [name]: sanitizedValue }));
    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleIdNumberChange = (e) => {
    const { name, value } = e.target;
    // Allow only underscores (_) and alphanumeric characters
    const sanitizedValue = value.replace(/[^a-zA-Z0-9_]/g, '');
    setEditedData((prevState) => ({ ...prevState, [name]: sanitizedValue }));
    setEditValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    if (!editedData.vendor) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        vendor: "Vendor is required.",
      }));
      return;
    }

    if (!editedData.idNumber) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        idNumber: "ID Number is required.",
      }));
      return;
    }

    if (!editedData.additionalCharges) {
      setEditValidationErrors((prevErrors) => ({
        ...prevErrors,
        additionalCharges: "Additional Charges is required.",
      }));
      return;
    }

    if (Object.values(editValidationErrors).some((error) => error !== "")) {
      return;
    }

    setIsEditBtnLoading(true);

    try {
      const formData = new FormData();
      formData.append(`procurement[_id]`, editedData._id);
      formData.append(`procurement[vendor]`, editedData.vendor);
      formData.append(`procurement[idNumber]`, editedData.idNumber);
      formData.append(
        `procurement[additionalCharges]`,
        editedData.additionalCharges
      );
      formData.append(`procurement.scannedInvoice`, invoiceFile);

      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };
      const data = {
        procurement: editedData,
      };

      const response = await axios.put("procurement", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProcurements();
        getSingleProcurementDetails(editedData._id);
        setOpenEditModal(false);
        setOpenViewModal(true);
        setInvoiceFile(null);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleOpenAddProcurementItemModal = (data) => {
    setOpenViewModal(false);
    setOpenAddProcurementItemModal(true);
    setProcurementId(data._id);
  };

  const handleAddProcurementItemChanges = (fieldNameOrEvent, value) => {
    let fieldName;
    let fieldValue;

    // Check if the first argument is an event (standard input)
    if (fieldNameOrEvent?.target) {
      fieldName = fieldNameOrEvent.target.name;
      fieldValue = fieldNameOrEvent.target.value;
    } else {
      // Handle React Select case
      fieldName = fieldNameOrEvent;
      fieldValue = value;
    }

    if (fieldName === "material") {
      getSingleRawMaterialDetail(fieldValue);
    }

    setAddProcurementItemDetails((prevState) => {
      const updatedState = { ...prevState, [fieldName]: fieldValue };

      console.log("updatedState", updatedState);

      const { taxAmount, taxable, grandTotal } = calculateTaxAmount(
        updatedState.rate,
        updatedState.quantity,
        updatedState.taxPercent
      );
      return {
        ...updatedState,
        taxAmount,
        taxable,
        grandTotal,
      };
    });
  };

  const addProcurementItem = async (e) => {
    e.preventDefault();
    setIsAddItemBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append(`procurement[_id]`, procurementId);
      formData.append(
        `procurementItem[material]`,
        addProcurementItemDetails.material
      );
      formData.append(
        `procurementItem[quantity]`,
        addProcurementItemDetails.quantity
      );
      formData.append(`procurementItem[rate]`, addProcurementItemDetails.rate);

      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.put("procurement", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setAddProcurementItemDetails({
          material: "",
          quantity: "",
          rate: "",
        });
        getProcurements();
        getSingleProcurementDetails(procurementId);
        setOpenAddProcurementItemModal(false);
        setOpenViewModal(true);
        setProcurementId("");
      }
    } catch (error) {

      console.log(error.response);
      
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message || error?.response?.data?.data?.title;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsAddItemBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setProcurementId(data?._id);
  };

  const handleDeleteProcurement = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementId,
      };

      const response = await axios.delete(`procurement`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        setProcurementId("");
        getProcurements();
        setOpenDeleteModal(false);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleOpenUpdateStatusModal = (data) => {
    setOpenUpdateStatusModal(true);
    setProcurementId(data._id);
  };

  const handleProcurementUpdateStatus = async (e) => {
    e.preventDefault();
    setIsStatusBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementId,
        storageLocation: selectedStorageLocation
      };

      const response = await axios.put("procurement/status", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setProcurementId("");
        getProcurements();
        setOpenUpdateStatusModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsStatusBtnLoading(false);
    }
  };

  const handleOpenProcurementItemEditModal = (data) => {
    console.log("data", data);

    setOpenViewModal(false);
    setOpenProcurementItemEditModal(true);
    setProcurementId(singleProcurementDetails._id);

    setEditProcurementItemDetails({
      _id: data._id,
      material: data.material?._id || "", // Safely access material._id
      quantity: data.quantity || 0,
      rate: data.rate || 0,
      taxAmount: data?.taxAmount || 0,
      taxable: data?.taxable || 0,
      grandTotal: data?.grandTotal || 0,
      taxPercent: data?.material?.taxPercent || 0, // Safely access taxPercent
      unit: data?.material?.unit || "",            // Safely access unit
    });
  };


  // const handleEditProcurementItemChange = (name, value) => {
  //   setEditProcurementItemDetails((prevState) => {
  //     const updatedState = { ...prevState, [name]: value };

  //     // Recalculate amounts if rate, quantity, or taxPercent changes
  //     const { taxAmount, taxable, grandTotal } = calculateTaxAmount(
  //       updatedState.rate,
  //       updatedState.quantity,
  //       updatedState.taxPercent
  //     );

  //     return {
  //       ...updatedState,
  //       taxAmount,
  //       taxable,
  //       grandTotal,
  //     };
  //   });

  //   // Reset validation error for the field
  //   setEditProcurementItemValidationErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: "",
  //   }));
  // };

  const handleEditProcurementItemChange = (name, value) => {
    setEditProcurementItemDetails((prevState) => {
      const updatedState = { ...prevState, [name]: value };

      // If material is changed, fetch taxPercent and unit
      if (name === "material" && value) {
        getSingleRawMaterialDetail(value); // Fetch and update taxPercent and unit
      }

      // Recalculate amounts if rate, quantity, or taxPercent changes
      const { taxAmount, taxable, grandTotal } = calculateTaxAmount(
        updatedState.rate,
        updatedState.quantity,
        updatedState.taxPercent
      );

      return {
        ...updatedState,
        taxAmount,
        taxable,
        grandTotal,
      };
    });

    // Reset validation error for the field
    setEditProcurementItemValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };


  const handleEditProcurementItemSubmit = async (e) => {
    e.preventDefault();
    if (!editProcurementItemDetails.material) {
      setEditProcurementItemValidationErrors((prevErrors) => ({
        ...prevErrors,
        material: "Material is required.",
      }));
      return;
    }
    if (!editProcurementItemDetails.quantity) {
      setEditProcurementItemValidationErrors((prevErrors) => ({
        ...prevErrors,
        quantity: "Quantity is required.",
      }));
      return;
    }
    if (!editProcurementItemDetails.rate) {
      setEditProcurementItemValidationErrors((prevErrors) => ({
        ...prevErrors,
        rate: "Rate is required.",
      }));
      return;
    }
    setIsEditItemBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const formData = new FormData();
      formData.append(`procurement[_id]`, procurementId);
      formData.append(`procurementItem[_id]`, editProcurementItemDetails._id);
      formData.append(
        `procurementItem[material]`,
        editProcurementItemDetails.material
      );
      formData.append(
        `procurementItem[quantity]`,
        editProcurementItemDetails.quantity
      );
      formData.append(`procurementItem[rate]`, editProcurementItemDetails.rate);

      const response = await axios.put("procurement", formData, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProcurements();
        setOpenProcurementItemEditModal(false);
        setOpenViewModal(true);
        getSingleProcurementDetails(singleProcurementDetails._id);
        setProcurementId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditItemBtnLoading(false);
    }
  };

  const handleOpenProcurementItemDeleteModal = (data) => {
    setOpenViewModal(false);
    setOpenProcurementDeleteModal(true);
    setProcurementId(data.procurement);
    setProcurmentItemIds((prevIds) => [...prevIds, data._id]);
  };

  const handleDeleteProcurementItems = async (e) => {
    e.preventDefault();
    setIsItemDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const data = {
        procurementItemIds: procurementItemIds,
      };

      const response = await axios.delete("procurement", { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProcurements();
        setProcurmentItemIds([]);
        setOpenProcurementDeleteModal(false);
        getSingleProcurementDetails(procurementId);
        setOpenViewModal(true);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsItemDeleteBtnLoading(false);
    }
  };

  const handleOpenProcurementItemUpdateStatus = (data) => {
    setOpenViewModal(false);
    setOpenUpdateStatusItemModal(true);
    setItemId((prevIds) => [...prevIds, data._id]);
  };

  const handleProcurementItemUpdateStatus = async (e) => {
    e.preventDefault();
    setIsItemStatusBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        procurementItemIds: itemId,
        storageLocation: selectedStorageLocation,
      };

      const response = await axios.put("procurement/status", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        setProcurementId("");
        setItemId([]);
        getProcurements();
        setOpenUpdateStatusItemModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsItemStatusBtnLoading(false);
    }
  };

  const handleOpenEditStatusWithRemarksModal = (data) => {
    setProcurementId(data._id);
    setOpenViewModal(false);
    setOpenEditStatusWithRemarksModal(true);
  };

  const handleEditStatusWithRemarksSubmit = async (e) => {
    e.preventDefault();

    if (!remark) {
      setValidateRemark("Remark is required");
      return;
    }

    setIsEditWithRemarkBtnLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        storageLocation: selectedStorageLocation,
        procurementId: procurementId,
        remark: remark,
      };

      const response = await axios.put("procurement/status", data, { headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        getProcurements();
        getSingleProcurementDetails(procurementId);
        setOpenEditStatusWithRemarksModal(false);
        setOpenViewModal(true);
        setProcurementId("");
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditWithRemarkBtnLoading(false);
    }
  };

  const getVendors = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("vendor", { headers });
      if (response?.data?.status === true) {
        setVendors(response?.data?.data?.vendors);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getRawMaterials = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("raw-material", { headers });

      if (response?.data?.status === true) {
        setRawMaterialList(response?.data?.data?.rawMaterials);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getSingleRawMaterialDetail = async (materialId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`raw-material?_id=${materialId}`, {
        headers,
      });

      if (response?.data?.status === true) {
        const taxPercent = response?.data?.data?.rawMaterials?.taxPercent
        const unit = response?.data?.data?.rawMaterials?.unit
        setAddProcurementItemDetails((prevState) => ({
          ...prevState,
          taxPercent: taxPercent,
          unit: unit
        }));
        setEditProcurementItemDetails((prevState) => ({
          ...prevState,
          taxPercent: taxPercent,
          unit: unit
        }))
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const calculateTaxAmount = (rate, quantity, taxPercent) => {
    const taxable = quantity * rate;
    const taxAmount = (taxable * taxPercent) / 100;
    const grandTotal = taxAmount + taxable;
    return {
      taxAmount,
      taxable,
      grandTotal,
    };
  };

  const handleCloseAddProcurementItemModal = () => {
    setAddProcurementItemDetails({
      material: "",
      quantity: "",
      rate: "",
      taxPercent: "",
      taxAmount: "",
      taxable: "",
      grandTotal: "",
      unit: ""
    })
    setOpenAddProcurementItemModal(false);
  }

  // const handlePageChange = (page) => {
  //   setPagination({ ...pagination, page });
  // };

  // const handleRowsPerPageChange = (rowsPerPage) => {
  //   setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
  // };

  // Function to get paginated data
  const getPaginatedData = () => {
    const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
    const endIndex = startIndex + pagination.rowsPerPage;
    return filteredData?.slice(startIndex, endIndex);
  };


  const totals = singleProcurementItemsDetails?.reduce(
    (acc, item) => {
      const taxable = item?.quantity * item?.rate;
      const taxAmount = taxable * (item?.material?.taxPercent / 100);
      const additionalCharges = singleProcurementDetails?.additionalCharges || 0;
      const grandTotal = taxable + taxAmount + additionalCharges;

      acc.totalTaxable += taxable;
      acc.totalTaxAmount += taxAmount;
      acc.totalAdditionalCharges += additionalCharges;
      acc.totalGrandTotal += grandTotal;

      return acc;
    },
    { totalTaxable: 0, totalTaxAmount: 0, totalAdditionalCharges: 0, totalGrandTotal: 0 }
  );

  const handleEndDateChange = (date) => {
    if (date && startDate && date < startDate) {
      setError('End date cannot be earlier than start date');
    } else {
      setError('');
      setEndDate(date);
    }
  };

  const handleChange = (vendor) => {
    setSelectedVendor(vendor);
  }

  const handleSubmitFilters = async() => {
    setBtnLoading(true);
    const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
    const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

    getProcurements(currentPage, recordsPerPage, adjustedStartDate, adjustedEndDate, selectedVendor, selectedStatus, searchText, selectedSortBy);
    setBtnLoading(false);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);

    const adjustedStartDate = new Date(startDate?.setHours(23, 59, 59, 999)); // start of day
    const adjustedEndDate = new Date(endDate?.setHours(23, 59, 59, 999)); // end of day page, limit, sDate, eDate, vendor, status, search, sort
    getProcurements(page, recordsPerPage, adjustedStartDate, adjustedEndDate, selectedVendor, selectedStatus, searchText, selectedSortBy)
  }

  const handleRowsPerPageChange = (newPerPage) => {
    setRecordsPerPage(newPerPage);
    
    const adjustedStartDate = new Date(startDate?.setHours(23, 59, 59, 999)); // start of day
    const adjustedEndDate = new Date(endDate?.setHours(23, 59, 59, 999)); // end of day page, limit, sDate, eDate, vendor, status, search, sort
    getProcurements(currentPage, newPerPage, adjustedStartDate, adjustedEndDate, selectedVendor, selectedStatus, searchText, selectedSortBy)
  
  };

  // const getStorageLocation = async() => {
  //   try {
  //       const headers = {
  //           Authorization : `Bearer ${accessToken}`
  //       }
  //       const response = await axios.get('storage-location', { headers })
  //       setStorageLocationList(response?.data?.data?.storageLocations);
  //   } catch (error) {
  //       if (error.response && error.response?.data?.status === false) {
  //           let errorMessage = error.response.data.error;
  //           toast.error(errorMessage, {
  //             position: "top-right",
  //             autoClose: 3000,
  //             theme: "colored",
  //           });
  //       }
  //   }
  // };

  // Function to fetch storage locations with filters
  const handleFilter = async () => {
    setFilterLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const params = {};

      if (filterState) params.state = filterState;
      if (filterCity) params.city = filterCity;

      const response = await axios.get("storage-location", {
        headers,
        params, // Include filters as query parameters
      });
      console.log("Response :", response);
      
      setStorageLocationList(response?.data?.data?.storageLocations);
      if(filterState !== "" || filterCity !== "") {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setFilterLoading(false);
    }
  };

  const resetModalFields = () => {
    setFilterCity("");
    setFilterState("");
    setSelectedStorageLocation("");
    setFilterLoading(false); // Optional: Reset loading if needed
  };

  useEffect(() => {
    // Fetch all storage locations on initial load
    handleFilter();
  }, []);
  
  useEffect(() => {
    const now = new Date();
    const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );

    const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
    const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
    // Set the start date and end date
    setStartDate(start);
    setEndDate(end);
    // getProductData(start, end, selectedOutlet, selectedCategory);

    getProcurements(currentPage, recordsPerPage, start, end);
  }, []);

  useEffect(() => {
    getVendors();
    getRawMaterials();
    // getStorageLocation();
  }, []);

  useEffect(() => {
    const result = procurementsList.filter((item) => {
      return (
        (item.vendor.name &&
          item.vendor.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.vendor.email &&
          item.vendor.email.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.vendor.phone &&
          item.vendor.phone.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.scannedInvoice &&
          item.scannedInvoice
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (item.status &&
          item.status.toLowerCase().includes(searchText.toLowerCase())) ||
        (item.idNumber && item.idNumber.toString().includes(searchText)) ||
        (item.financialYear &&
          item.financialYear.toString().includes(searchText)) ||
        (item.additionalCharges &&
          item.additionalCharges.toString().includes(searchText)) ||
        (item.totalTaxAmount &&
          item.totalTaxAmount.toString().includes(searchText)) ||
        (item.taxable && item.taxable.toString().includes(searchText)) ||
        (item.grandTotal && item.grandTotal.toString().includes(searchText))
      );
    });
    setFilteredData(result);
  }, [procurementsList]);

  useEffect(() => {
    if(!searchText && touched){
      const adjustedStartDate = new Date(startDate?.setHours(23, 59, 59, 999)); // start of day
      const adjustedEndDate = new Date(endDate?.setHours(23, 59, 59, 999)); // end of day
      getProcurements(currentPage, recordsPerPage, adjustedStartDate, adjustedEndDate )
    }
  }, [ searchText ])

  return (
    <CContainer>
      {/* <-------------- NEW TABLE COMMENTED -----------> */}
      <CCard className="mb-3">
        <CCardHeader className="fw-semibold">PURCHASE / PROCUREMENT LIST</CCardHeader>
        <CCardBody>
          <CRow className="mb-2">
            <CCol md="3">
              <CFormLabel className="fw-semibold text-secondary">Select Vendor</CFormLabel>
              <Select
                options={vendors.map((vendor) => ({
                  value: vendor._id,
                  label: vendor.name,
                }))}
                value={
                  selectedVendor
                    ? {
                      value: selectedVendor,
                      label: vendors.find((vendor) => vendor._id === selectedVendor)?.name
                    }
                    : null
                }
                onChange={(selectedOption) => {
                  if(selectedOption) {
                    handleChange(selectedOption?.value)
                  } else {
                    handleChange('');
                  }
                }}
                classNamePrefix="react-select"
                isClearable
                placeholder="Select Vendor"
              />
            </CCol>
            <CCol md="3">
              <CFormLabel className='fw-semibold text-secondary'>Select Status</CFormLabel>
              <CFormSelect id='selectField' value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                  <option value="">Select Status</option>
                  <option value="RECEIVED">RECEIVED</option>
                  <option value="PENDING">PENDING</option>
              </CFormSelect>
            </CCol>
            <CCol md="2">
              <CFormLabel className='fw-semibold text-secondary'>Start Date <span className="text-danger">*</span></CFormLabel>
              <div style={{ display: "flex", flexDirection: "column" }}>
                  <ReactDatePicker
                    selected={startDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Start Date"
                    className="form-control"
                  />
              </div>
            </CCol>
            <CCol md="2">
              <CFormLabel className='fw-semibold text-secondary'>End Date <span className="text-danger">*</span></CFormLabel>
              <div style={{ display: "flex", flexDirection: "column" }}>
                  <ReactDatePicker
                    selected={endDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={handleEndDateChange}
                    placeholderText="Select End Date"
                    className="form-control"
                  />
              </div>
            </CCol>
            <CCol md="2">
              <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
              <CButton color="primary" className="w-100" onClick={handleSubmitFilters} 
                  disabled={btnLoading}
              >
                  {btnLoading ? <CSpinner size="sm" /> : 'Submit'} 
                  {/* Submit */}
              </CButton>
            </CCol>
            
          </CRow>

          <CRow className="mb-2">
            <CCol md="3">
              <CFormLabel className='fw-semibold text-secondary'>Sort By</CFormLabel>
              <CFormSelect id='selectField' value={selectedSortBy} onChange={(e) => setSelectedSortBy(e.target.value)}>
                  <option value="desc">DESCENDING</option>
                  <option value="asc">ASCENDING</option>
              </CFormSelect>
            </CCol>
            <CCol md="3">
              <CFormLabel className='fw-semibold text-secondary'>Search</CFormLabel>
              <CFormInput
                type="text"
                placeholder="Search By ID Number"
                onChange={(e) => {setSearchText(e.target.value); setTouched(true) } }
              />
            </CCol>
            <CCol md="3" lg="3" className="text-center">
                <CFormLabel className="text-white">.</CFormLabel>
                <CButton className="btn rounded-pill w-100" onClick={handleSubmitFilters} disabled={ searchText === "" } >
                  {searchBtnLoading ? <CSpinner size="sm" /> : "Search"}
                </CButton>
            </CCol>
          </CRow>

          <DataTable
            columns={updatedColumns}
            // title="PROCUREMENTS LIST"
            data={filteredData}
            striped
            pagination
            paginationServer
            paginationRowsPerPageOptions={[10, 20, 50]}
            totalRows={filteredData?.length}
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
          />
        </CCardBody>
      </CCard>

{/* <--------------- Old Table -------------> */}
        {/* <DataTableComponent
          columns={updatedColumns}
          title="PROCUREMENTS LIST"
          // data={filteredData}
          data={getPaginatedData()} // Use the paginated data here
          searchText={searchText}
          onChange={handleSearchChange}
          striped
          itemsPerPage={10}
          pagination
          totalRows={filteredData?.length}
          paginationTotalRows={filteredData?.length} // Total number of items
          onChangeRowsPerPage={handleRowsPerPageChange}
          onChangePage={handlePageChange}
          paginationServer={false} // Set to false for client-side pagination
        /> */}

      {openViewModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader style={{ backgroundColor: "#f2f2f2" }}>
            <h5 className="fw-semibold" style={{ marginLeft: "240px" }}>
              VIEW PROCUREMENT DETAILS
            </h5>
          </CModalHeader>

          <CModalBody>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colspan="6"
                    className="text-center fw-semibold"
                    style={{ borderBottom: "1.5px solid #000" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          flex: "1",
                        }}
                      >
                        PROCUREMENT DETAILS
                      </span>
                      {singleProcurementDetails.status === "PENDING" && (
                        <CDropdown
                          variant="btn-group"
                          direction="dropend"
                          className="cursor-pointer"
                        >
                          <CDropdownToggle color="secondary" size="sm">
                            Action
                          </CDropdownToggle>
                          <CDropdownMenu>
                            <CDropdownItem
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleEditModal(singleProcurementDetails)
                              }
                            >
                              Edit Procurement
                            </CDropdownItem>
                            <CDropdownItem
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleOpenEditStatusWithRemarksModal(
                                  singleProcurementDetails
                                )
                              }
                            >
                              Update Procurement Status with Remarks
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3" valign="top" width="50%">
                    <table
                      className="table table-striped table-condensed"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            colSpan="2"
                            className=" text-center fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              fontSize: "14px",
                            }}
                          >
                            PROCUREMENT DETAILS
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "13px" }}>
                        <tr>
                          <th
                            className="w-50 fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            ID Number
                          </th>
                          <td
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.idNumber}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Serial Number
                          </th>
                          <td
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.serialNo}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Financial Year
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.financialYear}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Status
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.status}
                          </td>

                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Additional Chargers
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            ₹ {singleProcurementDetails?.additionalCharges}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td colspan="3" valign="top">
                    <table
                      className="table table-striped table-condensed"
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        marginTop: "10px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            colspan="2"
                            className="text-center fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              fontSize: "14px",
                            }}
                          >
                            VENDOR DETAILS
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "13px" }}>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            GST
                          </th>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {" "}
                            {singleProcurementDetails?.vendor?.gstin}
                          </th>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Name
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.name}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Email
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.email}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Phone
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.phone}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Address
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.address}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            Pincode
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.pincode}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            City
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.city}
                            {singleProcurementDetails?.vendor?.state}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="fw-semibold"
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {" "}
                            State
                          </th>
                          <td
                            style={{
                              borderBottom: "1px solid #000",
                              padding: "8px",
                            }}
                          >
                            {singleProcurementDetails?.vendor?.state}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <CTable striped bordered className="mt-4">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell
                    className="fs-6 fw-semibold pt-2 pb-2 text-center"
                    colSpan="9"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span style={{ flex: "1" }}>PROCUREMENT ITEMS</span>
                      {singleProcurementDetails?.status === "PENDING" && (
                        <CButton
                          color="warning"
                          style={{ borderRadius: "18px" }}
                          onClick={() =>
                            handleOpenAddProcurementItemModal(
                              singleProcurementDetails
                            )
                          }
                        >
                          Add Item
                        </CButton>
                      )}
                    </div>
                  </CTableHeaderCell>
                </CTableRow>

                <CTableRow>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    #
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    MATERIAL NAME
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    QTY
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-center fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    RATE
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    TAXABLE
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold"
                    style={{ fontSize: "12px" }}
                  >
                    TAX AMOUNT
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold"
                    style={{ width: "100px", fontSize: "12px" }}
                  >
                    GRAND TOTAL
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold "
                    style={{ fontSize: "12px" }}
                  >
                    STATUS
                  </CTableHeaderCell>
                  <CTableHeaderCell
                    className="text-end fw-bold "
                    style={{ fontSize: "12px" }}
                  >
                    ACTIONS
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {singleProcurementItemsDetails &&
                  singleProcurementItemsDetails?.map((item, index) => (
                    <CTableRow key={item._id}>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {index + 1}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.material?.name}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.quantity}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px" }}
                      >
                        {item?.rate}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-end"
                        style={{ fontSize: "13px" }}
                      >
                        {
                          item?.rate *
                          item?.quantity}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-end"
                        style={{ fontSize: "13px" }}
                      >
                        {
                          ((item?.rate * item?.quantity) * (item?.material?.taxPercent / 100)).toFixed(2)
                        }

                      </CTableDataCell>
                      <CTableDataCell
                        className="text-end"
                        style={{ fontSize: "13px" }}
                      >
                        {(
                          (item?.rate * item?.quantity) +
                          ((item?.rate * item?.quantity) * (item?.material?.taxPercent / 100))
                        ).toFixed(2)}
                      </CTableDataCell>
                      <CTableDataCell
                        className="text-center"
                        style={{ fontSize: "13px", cursor: "pointer" }}
                      >
                        {item?.status}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        <CDropdown
                          variant="btn-group"
                          direction="dropend"
                          className="cursor-pointer"
                        >
                          <div style={{ display: 'inline-block', position: 'relative' }}>
                            {item.status !== "PENDING" && (
                              <CTooltip content="No actions can be performed" placement="top">
                                <span style={{ display: 'inline-block', cursor: 'not-allowed' }}>
                                  <CDropdownToggle color="secondary" size="sm" disabled>
                                    Action
                                  </CDropdownToggle>
                                </span>
                              </CTooltip>
                            )}
                            {item.status === "PENDING" && (
                              <CDropdownToggle color="secondary" size="sm">
                                Action
                              </CDropdownToggle>
                            )}
                          </div>
                          <CDropdownMenu>
                            {item.status === "PENDING" ? (
                              <CDropdownItem
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenProcurementItemEditModal(item)
                                }
                              >
                                Edit
                              </CDropdownItem>
                            ) : null}
                            {item.status === "PENDING" ? (
                              <CDropdownItem
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenProcurementItemDeleteModal(item)
                                }
                              >
                                Delete
                              </CDropdownItem>
                            ) : null}
                            {item.status === "PENDING" ? (
                              <CDropdownItem
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOpenProcurementItemUpdateStatus(item)
                                }
                              >
                                Update Status
                              </CDropdownItem>
                            ) : null}
                          </CDropdownMenu>
                        </CDropdown>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
              </CTableBody>
              <CTableFoot>
                <CTableRow>
                  <CTableDataCell colSpan="6" className="text-end">
                    <span style={{ fontWeight: "550", fontSize: "14px" }}>
                      <p style={{ fontSize: "13px" }}> Taxable</p>
                      <p style={{ fontSize: "13px" }}>+ Tax Amount</p>
                      <p style={{ fontSize: "10px" }}>(+ Additional Charges)</p>
                      <i style={{ borderTop: "1px solid #000", padding: "8px" }}>
                        = Grand Total
                      </i>
                    </span>
                  </CTableDataCell>

                  {/* Display the totals calculated by reduce */}
                  <CTableDataCell className="text-end">
                    <p style={{ fontSize: "13px", fontWeight: "600" }}>
                      {totals.totalTaxable.toFixed(2)}
                    </p>
                    <p style={{ fontSize: "13px", fontWeight: "600" }}>
                      + {totals.totalTaxAmount.toFixed(2)}
                    </p>
                    <p style={{ fontSize: "11px", fontWeight: "400" }}>
                      (+ {totals.totalAdditionalCharges.toFixed(2)})
                    </p>
                    <p
                      style={{
                        borderTop: "1px solid #000",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      = {totals.totalGrandTotal.toFixed(2)}
                    </p>
                  </CTableDataCell>
                </CTableRow>
              </CTableFoot>
            </CTable>
          </CModalBody>
          <CModalFooter>
            <a href={singleProcurementDetails?.scannedInvoice}>
              <CButton type="button" style={{ borderRadius: "18px" }}>
                Download Invoice
              </CButton>
            </a>

            <CButton
              style={{ borderRadius: "18px" }}
              className="btn btn-warning"
              onClick={() => setOpenViewModal(false)}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT PROCUREMENT DETAILS
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div>
                    <CFormLabel className="fw-semibold">
                      Select Vendor <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      value={editedData.vendor}
                      name="vendor"
                      className="mb-3"
                    >
                      <option>Select Vendor</option>
                      {vendors &&
                        vendors.map((rm) => (
                          <option key={rm._id} value={rm._id}>
                            {rm.name}
                          </option>
                        ))}
                    </CFormSelect>
                    <div className="text-danger">
                      {editValidationErrors.vendor}
                    </div>
                  </div>
                </CCol>
                <CCol className="col-md-5">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      ID Number <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="ID Number"
                      className="form-control"
                      name="idNumber"
                      value={editedData.idNumber}
                      onChange={handleIdNumberChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.idNumber}
                    </div>
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Scanned Invoice
                    </CFormLabel>
                    <CFormInput
                      type="file"
                      onChange={handleFileChange}
                      className="form-control"
                      id="formFile"
                    />
                  </div>
                  <div className="text-danger">
                    {editValidationErrors.scannedInvoice}
                  </div>
                </CCol>
                <CCol className="col-md-5">
                  {" "}
                  <CFormLabel className="fw-semibold">
                    Additional Charges <span className="text-danger">*</span>
                  </CFormLabel>
                  <div className="mb-3">
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="additionalCharges"
                      className="form-control no-arrows"
                      name="additionalCharges"
                      value={editedData.additionalCharges}
                      onChange={handleEditChange}
                    />
                    <div className="text-danger">
                      {editValidationErrors.additionalCharges}
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE PROCUREMENT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteProcurement}>
            <CModalBody>
              Are you sure you want to delete this procurement ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                Close
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openUpdateStatusModal ? (
        <CModal
          alignment="center"
          visible={openUpdateStatusModal}
          onClose={() => {
            resetModalFields(); // Reset all fields
            setOpenUpdateStatusModal(false);
          }}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleProcurementUpdateStatus}>
            <CModalBody>
              <p className="fw-semibold text-muted">
                Are you sure you want to update the status of this procurement?
              </p>
              <span className="text-success fw-semibold mt-1">
                Please select a storage location before confirming.
              </span>

              {/* Filters for State and City */}
              {/* <CRow className="mt-1 mb-3">
                <CCol md={5}>
                  <CFormLabel className="fw-semibold text-muted">City</CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Enter city"
                    value={filterCity}
                    onChange={(e) => setFilterCity(e.target.value)}
                  />
                </CCol>
                <CCol md={5}>
                  <CFormLabel className="fw-semibold text-muted">State</CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Enter state"
                    value={filterState}
                    onChange={(e) => setFilterState(e.target.value)}
                  />
                </CCol>
                <CCol md={2} className="text-end">
                <CFormLabel className="text-white">.</CFormLabel>
                  <CButton
                    color="primary"
                    onClick={handleFilter}
                    className="w-100"
                    style={{
                      borderRadius: "8px",
                    }}
                    disabled={filterLoading}
                  >
                    { filterLoading ? <Spinner size="sm" /> : "Show" }
                  </CButton>
                </CCol>
              </CRow> */}

              {/* Select Storage Location Field */}
              <CCol md={12} className="mt-1">
                <CFormLabel className="fw-semibold text-muted">Select Storage Location <span className="text-danger">*</span></CFormLabel>
                <Select
                  components={animatedComponents}
                  id="selectField"
                  name="storage_location"
                  value={storageLocationList
                    .map((storage) => ({
                      value: storage?._id,
                      label: `${storage?.name} -> ${storage?.city}, ${storage?.state}`,
                    }))
                    .find((option) => option.value === selectedStorageLocation)} // Match value format with options
                  onChange={(selectedOption) =>
                    setSelectedStorageLocation(selectedOption ? selectedOption.value : "")
                  }
                  options={storageLocationList.map((storage) => ({
                    value: storage?._id,
                    label: `${storage?.name} -> ${storage?.city}, ${storage?.state}`,
                  }))} // Map data to react-select's options
                  placeholder="Select a Storage Location"
                  isClearable
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: selectedStorageLocation ? base.borderColor : "red", // Red border for validation
                    }),
                  }}
                />
                {!selectedStorageLocation && (
                  <span className="text-danger d-block" style={{ fontSize: '13px' }}>
                    Storage location is required.
                  </span>
                )}
              </CCol>
            </CModalBody>

            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                Close
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isStatusBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openAddProcurementItemModal ? (
        <CModal
          size="lg"
          alignment="center"
          visible={openAddProcurementItemModal}
          onClose={handleCloseAddProcurementItemModal}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              ADD NEW PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={addProcurementItem}>
            <CModalBody>
              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel>
                      Select Material <span className="text-danger">*</span>
                    </CFormLabel>
                    <Select
                      size="sm"
                      name="material"
                      className="mb-3"
                      options={rawMaterialOptions}
                      value={
                        rawMaterialOptions.find(
                          (rm) => rm.value === addProcurementItemDetails.material
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        handleAddProcurementItemChanges("material", selectedOption?.value)
                      }
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Rate <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="rate"
                      value={addProcurementItemDetails.rate}
                      onChange={handleAddProcurementItemChanges}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Quantity <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      step="any"
                      type="number"
                      placeholder="Quantity"
                      className="form-control no-arrows"
                      name="quantity"
                      value={addProcurementItemDetails.quantity}
                      onChange={handleAddProcurementItemChanges}
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Unit <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Unit"
                      className="form-control no-arrows"
                      name="unit"
                      value={addProcurementItemDetails.unit}
                      disabled
                    />
                  </div>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Tax Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="Tax Percent"
                      className="form-control no-arrows"
                      name="taxPercent"
                      value={addProcurementItemDetails.taxPercent}
                      disabled
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Taxable <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="taxable"
                      // value={addProcurementItemDetails.taxable?.toFixed(2)}
                      value={parseFloat(addProcurementItemDetails.taxable)?.toFixed(2)}
                      disabled
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Tax Amount <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="taxAmount"
                      value={parseFloat(addProcurementItemDetails.taxAmount)?.toFixed(2)}
                      disabled
                    />
                  </div>
                </CCol>
                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Grand Total <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step="any"
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="grandTotal"
                      value={parseFloat(addProcurementItemDetails?.grandTotal).toFixed(2)}
                      disabled />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                isDisable={
                  !addProcurementItemDetails.material ||
                  !addProcurementItemDetails.quantity ||
                  !addProcurementItemDetails.rate
                }
                title="ADD PROCUREMENT ITEM"
                isLoading={isAddItemBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}


      <CModal
        size="lg"
        alignment="center"
        visible={openProcurementItemEditModal}
        onClose={() => setOpenProcurementItemEditModal(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalHeader>
          <CModalTitle id="VerticallyCenteredExample" className="fs-6">
            EDIT PROCUREMENT ITEM DETAILS
          </CModalTitle>
        </CModalHeader>
        <CForm onSubmit={handleEditProcurementItemSubmit}>
          <CModalBody>
            <CRow>
              <CCol className="col-md-4">
                <CFormLabel>
                  Select Material <span className="text-danger">*</span>
                </CFormLabel>
                <div className="mb-3">
                  <Select
                    size="sm"
                    name="material"
                    className="mb-3"
                    options={rawMaterialOptions}
                    value={
                      rawMaterialOptions.find(
                        (rm) => rm.value === editProcurementItemDetails.material
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleEditProcurementItemChange("material", selectedOption?.value)
                    }
                  />
                  <div className="text-danger">
                    {editProcurementItemValidationErrors.material}
                  </div>
                </div>
              </CCol>


              <CCol>
                <div className="mb-3">
                  <CFormLabel>
                    Rate <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={1}
                    type="number"
                    step="any"
                    placeholder="Rate"
                    className="form-control no-arrows"
                    name="rate"
                    value={editProcurementItemDetails.rate}
                    onChange={(e) => handleEditProcurementItemChange(e.target.name, e.target.value)}
                  />
                  <div className="text-danger">
                    {editProcurementItemValidationErrors.rate}
                  </div>
                </div>
              </CCol>
              <CCol>
                <div className="mb-3">
                  <CFormLabel>
                    Quantity <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={1}
                    type="number"
                    step="any"
                    placeholder="Quantity"
                    className="form-control no-arrows"
                    name="quantity"
                    value={editProcurementItemDetails.quantity}
                    onChange={(e) => handleEditProcurementItemChange(e.target.name, e.target.value)}
                  />
                  <div className="text-danger">
                    {editProcurementItemValidationErrors.quantity}
                  </div>
                </div>
              </CCol>

              <CCol>
                <div className="mb-3">
                  <CFormLabel>
                    Unit <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    disabled
                    type="text"
                    placeholder="Unit"
                    className="form-control no-arrows"
                    name="unit"
                    value={editProcurementItemDetails.unit}
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormLabel>
                  Tax Percent <span className="text-danger">*</span>
                </CFormLabel>
                <div className="mb-3">
                  <CFormInput
                    min={0}
                    step="any"
                    type="number"
                    placeholder="Tax Percent"
                    className="form-control no-arrows"
                    name="taxPercent"
                    value={editProcurementItemDetails.taxPercent}
                    disabled
                  />
                </div>
              </CCol>

              <CCol>
                <div className="mb-3">
                  <CFormLabel>
                    Taxable <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={0}
                    step="any"
                    type="number"
                    placeholder="In Rupees"
                    className="form-control no-arrows"
                    name="taxable"
                    value={editProcurementItemDetails.taxable?.toFixed(2)}
                    disabled />
                </div>
              </CCol>
              <CCol>
                <div className="mb-3">
                  <CFormLabel>
                    Tax Amount <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={0}
                    step="any"
                    type="number"
                    placeholder="In Rupees"
                    className="form-control no-arrows"
                    name="taxAmount"
                    value={editProcurementItemDetails.taxAmount?.toFixed(2)}
                    disabled />
                </div>
              </CCol>
              <CCol>
                <div className="mb-3">
                  <CFormLabel>
                    Grand Total <span className="text-danger">*</span>
                  </CFormLabel>
                  <CFormInput
                    min={0}
                    step="any"
                    type="number"
                    placeholder="In Rupees"
                    className="form-control no-arrows"
                    name="quantity"
                    value={editProcurementItemDetails.grandTotal?.toFixed(2)}
                    disabled
                  />
                </div>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter>
            <PrimaryButton
              title="SAVE CHANGES"
              isLoading={isEditItemBtnLoading}
            />
          </CModalFooter>
        </CForm>
      </CModal>


      {openProcurementItemDeleteModal ? (
        <CModal
          alignment="center"
          visible={openProcurementItemDeleteModal}
          onClose={() => setOpenProcurementDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteProcurementItems}>
            <CModalBody>
              Are you sure you want to delete this procurement Item ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenProcurementDeleteModal(false)}
                style={{
                  background: "#ef9a9a",
                  outline: "none",
                  border: "none",
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="CONFIRM"
                isLoading={isItemDeleteBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {/* {openUpdateStatusItemModal ? (
        <CModal
          alignment="center"
          visible={openUpdateStatusItemModal}
          onClose={() => setOpenUpdateStatusItemModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleProcurementItemUpdateStatus}>
            <CModalBody>
              Are you sure you want to update the status of this procurement
              Item?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusItemModal(false)}
                style={{
                  background: "#ef9a9a",
                  outline: "none",
                  border: "none",
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="CONFIRM"
                isLoading={isItemStatusBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null} */}

      {openUpdateStatusItemModal ? (
        <CModal
          alignment="center"
          visible={openUpdateStatusItemModal}
          onClose={() => setOpenUpdateStatusItemModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT ITEM
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleProcurementItemUpdateStatus}>
            <CModalBody>
              Are you sure you want to update the status of this procurement Item?

              {/* Filters for State and City */}
              {/* <CRow className="mt-1 mb-3">
                <CCol md={5}>
                  <CFormLabel className="fw-semibold text-muted">City</CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Enter city"
                    value={filterCity}
                    onChange={(e) => setFilterCity(e.target.value)}
                  />
                </CCol>
                <CCol md={5}>
                  <CFormLabel className="fw-semibold text-muted">State</CFormLabel>
                  <CFormInput
                    type="text"
                    placeholder="Enter state"
                    value={filterState}
                    onChange={(e) => setFilterState(e.target.value)}
                  />
                </CCol>
                <CCol md={2} className="text-end">
                  <CFormLabel className="text-white">.</CFormLabel>
                  <CButton
                    color="primary"
                    onClick={handleFilter}
                    className="w-100"
                    style={{
                      borderRadius: "8px",
                    }}
                    disabled={filterLoading}
                  >
                    {filterLoading ? <Spinner size="sm" /> : "Show"}
                  </CButton>
                </CCol>
              </CRow> */}

              {/* Add Select Storage Location Field */}
              <CCol md={12} className="mt-1">
                <CFormLabel className="fw-semibold text-muted">
                  Select Storage Location <span className="text-danger">*</span>
                </CFormLabel>
                <Select
                  components={animatedComponents}
                  id="selectField"
                  name="storage_location"
                  value={storageLocationList
                    .map((storage) => ({
                      value: storage?._id,
                      label: `${storage?.name} -> ${storage?.city}, ${storage?.state}`,
                    }))
                    .find((option) => option.value === selectedStorageLocation)} // Match value format with options
                  onChange={(selectedOption) =>
                    setSelectedStorageLocation(selectedOption ? selectedOption.value : "")
                  }
                  options={storageLocationList.map((storage) => ({
                    value: storage?._id,
                    label: `${storage?.name} -> ${storage?.city}, ${storage?.state}`,
                  }))} // Map data to react-select's options
                  placeholder="Select a Storage Location"
                  isClearable
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderColor: selectedStorageLocation ? base.borderColor : "red", // Red border for validation
                    }),
                  }}
                />
                {!selectedStorageLocation && (
                  <span className="text-danger d-block" style={{ fontSize: '13px' }}>
                    Storage location is required.
                  </span>
                )}
              </CCol>
            </CModalBody>

            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusItemModal(false)}
                style={{
                  outline: "none",
                  border: "none",
                  borderRadius: '16px'
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="UPDATE"
                isLoading={isItemStatusBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}


      {openEditStatusWithRemarksModal ? (
        <CModal
          alignment="center"
          visible={openEditStatusWithRemarksModal}
          onClose={() => setOpenEditStatusWithRemarksModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              UPDATE STATUS OF THE PROCUREMENT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditStatusWithRemarksSubmit}>
            {/* <CModalBody>
              Are you sure you want to update the status of this procurement ?
              <div className="mb-3 mt-4">
                <CFormLabel>Remark</CFormLabel>
                <CFormTextarea
                  rows={4}
                  onChange={(e) => {
                    setRemark(e.target.value);
                    setValidateRemark("");
                  }}
                />
                <div className="text-danger">{validateRemark}</div>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenUpdateStatusModal(false)}
                style={{
                  background: "#ef9a9a",
                  outline: "none",
                  border: "none",
                }}
              >
                Close
              </CButton>
              <PrimaryButton
                title="CONFIRM"
                isLoading={isEditwithRemarkBtnLoading}
              />
            </CModalFooter> */}

            <CForm onSubmit={handleEditStatusWithRemarksSubmit}>
              <CModalBody>
                Are you sure you want to update the status of this procurement?

                <div className="mb-3 mt-4">
                  <CFormLabel>Remark</CFormLabel>
                  <CFormTextarea
                    rows={4}
                    onChange={(e) => {
                      setRemark(e.target.value);
                      setValidateRemark("");
                    }}
                  />
                  <div className="text-danger">{validateRemark}</div>
                </div>

                {/* Filters for State and City */}
                {/* <CRow className="mt-1 mb-3">
                  <CCol md={5}>
                    <CFormLabel className="fw-semibold text-muted">City</CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Enter city"
                      value={filterCity}
                      onChange={(e) => setFilterCity(e.target.value)}
                    />
                  </CCol>
                  <CCol md={5}>
                    <CFormLabel className="fw-semibold text-muted">State</CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="Enter state"
                      value={filterState}
                      onChange={(e) => setFilterState(e.target.value)}
                    />
                  </CCol>
                  <CCol md={2} className="text-end">
                    <CFormLabel className="text-white">.</CFormLabel>
                    <CButton
                      color="primary"
                      onClick={handleFilter}
                      className="w-100"
                      style={{
                        borderRadius: "8px",
                      }}
                      disabled={filterLoading}
                    >
                      {filterLoading ? <Spinner size="sm" /> : "Show"}
                    </CButton>
                  </CCol>
                </CRow> */}

                {/* Add Select Storage Location Field */}
                <CCol md={12} className="mt-1">
                  <CFormLabel className="fw-semibold text-muted">
                    Select Storage Location <span className="text-danger">*</span>
                  </CFormLabel>
                  <Select
                    components={animatedComponents}
                    id="selectField"
                    name="storage_location"
                    value={storageLocationList
                      .map((storage) => ({
                        value: storage?._id,
                        label: `${storage?.name} -> ${storage?.city}, ${storage?.state}`,
                      }))
                      .find((option) => option.value === selectedStorageLocation)} // Match value format with options
                    onChange={(selectedOption) =>
                      setSelectedStorageLocation(selectedOption ? selectedOption.value : "")
                    }
                    options={storageLocationList.map((storage) => ({
                      value: storage?._id,
                      label: `${storage?.name} -> ${storage?.city}, ${storage?.state}`,
                    }))} // Map data to react-select's options
                    placeholder="Select a Storage Location"
                    isClearable
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderColor: selectedStorageLocation ? base.borderColor : "red", // Red border for validation
                      }),
                    }}
                  />
                  {!selectedStorageLocation && (
                    <span className="text-danger d-block" style={{ fontSize: '13px' }}>
                      Storage location is required.
                    </span>
                  )}
                </CCol>
              </CModalBody>

              <CModalFooter>
                <CButton
                  color="warning"
                  onClick={() => setOpenUpdateStatusModal(false)}
                  style={{
                    outline: "none",
                    border: "none",
                    borderRadius: '16px'
                  }}
                >
                  Close
                </CButton>
                <PrimaryButton
                  title="UPDATE"
                  isLoading={isEditwithRemarkBtnLoading}
                />
              </CModalFooter>
            </CForm>
          </CForm>
        </CModal>
      ) : null}

    </CContainer>
  );
};

export default ProcurementCatalog;
