import { CBadge, CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import React, { useState, useEffect } from 'react'
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import { DataTableComponent, PrimaryButton } from '../../components';
import { Dropdown } from 'react-bootstrap';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import makeAnimated from "react-select/animated";
import Select from "react-select";

const InAppAdvertisement = () => {

    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openImageModal, setOpenImageModal] = useState(false);
    const [openImageUploadModal, setOpenImageUploadModal] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [popUpData, setPopUpData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputData, setInputData] = useState({
        title: ''
    });
    const [editedData, setEditedData] = useState({});
    const [selectedExcludedOutlets, setSelectedExcludedOutlets] = useState([]);
    const [selectedEditedExcludedOutlets, setSelectedEditedExcludedOutlets] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedEditProduct, setSelectedEditProduct] = useState('');
    const [outlets, setOutlets] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [image, setImage] = useState(null);
    const [editImage, setEditImage] = useState(null);
    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [isImageEditBtnLoading, setIsImageEditBtnLoading] = useState(false);
    const [advertisementId, setAdvertisementId] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [editImageId, setEditImageId] = useState('');
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    
    const customStyles = {
      control: (provided, state) => ({
          ...provided,
          width: "100%",
          borderRadius: "6px",
          minWidth: "200px",
          borderColor: state.isFocused ? "#80bdff" : "#ced4da",
          boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
          "&:hover": {
              borderColor: state.isFocused ? "#80bdff" : "#ced4da",
          },
      }),
      placeholder: (provided) => ({
          ...provided,
          color: "#6c757d",
      }),
    };

    const columns = [
        {
          name: "#",
          selector: (row, index) => index + 1,
          cell: (row) => (
            <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>
          ),
          width: "100px",
          center: true,
        },
        {
          name: "TITLE",
          cell: (row) => (
            <div className="d-flex flex-column align-items-center gap-1">
              <span className='fw-semibold fs-6' style={{ color: 'purple' }}>{row?.title}</span>
              <span className="text-secondary" style={{ fontSize: '12px' }}>
                {row.createdAt
                  ? new Date(row.createdAt).toLocaleString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })
                  : "--------"
                }
              </span>
            </div>
          ),
          width: "200px",
        },
        {
          name: "IMAGE",
          cell: (row) => (
            <div className="d-flex flex-column align-items-center gap-2">
              <img
                className="fs-7"
                style={{
                  // width: "70px",
                  height: "80px",
                  borderRadius: "50%",
                  // marginTop: "8px",
                  // marginBottom: "8px",
                  border: "0.2px solid bluevoilet",
                  cursor: "pointer",
                }}
                src={row?.advertisementImage || "/dummy-image.png"}
                alt="category-image"
                onClick={() => handleImageClick(row)}
              />
            </div>
          ),
          width: "120px",
        },
        {
          name: "PRODUCT NAME",
          selector: (row) => row.sequenceNumber,
          cell: (row) => (
            <div className="fs-7 fw-semibold">{row?.productId?.name ? row?.productId?.name : "--------"}</div>
          ),
          width: "200px",
        },
        {
          name: "EXCLUDED OUTLETS",
          selector: (row) => row.name,
          cell: (row) => (
            <div>
              <div style={{ color: 'chocolate' }}>Excluded Outlets : {" "} 
                {row.excludedOutlets && row.excludedOutlets.length > 0
                  ? row.excludedOutlets.map(outlet => outlet.name.toLowerCase().trim()).join(', ')
                  : "No outlets excluded"}
              </div>
            </div>
          ),
          width: "250px",
        },
        {
          name: "STATUS",
          selector: (row) => row.name,
          cell: (row) => (
            <div className="fs-7 w-100">
              <CBadge className="w-100"
                size="sm"
                color={
                  row?.isActive === true
                    ? "success"
                    : row?.isActive === false
                    ? "danger"
                    : "info"
                }
              >{row?.isActive === true ? "ACTIVE" : "INACTIVE"}</CBadge>
            </div>
          ),
          width: "120px",
        },
        {
            name: "ACTIONS",
            right: "true",
            cell: (row) => (
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" size="sm">
                        Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => handleOpenViewModal(row)} className="text-danger">
                            <FaEye className="me-2" /> View
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={() => handleOpenEditModal(row)} className="text-primary">
                            <FaPencilAlt className="me-2" /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleOpenDeleteModal(row)} className="text-danger">
                            <FaTrash className="me-2" /> Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ),
            minWidth: "145px",
        },
    ];

    const updatedColumns = columns.map((column) => ({
        ...column,
        name: (
          <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
        ),
    }));

    const outletData = outlets?.map((outlet) => ({
      value: outlet._id,
      label: `${outlet.name} (${outlet.city})`,
    }));

    const getInAppAddvertisement = async() => {
        setLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            const response = await axios.get("in-app-advertisement", { headers });
            console.log("Adverstise ment data :", response?.data?.data);
            response?.data?.data?.inAppAdvertisement?.forEach((item, index) => {
              item.serialNumber = index + 1;
            });
            setPopUpData(response?.data?.data?.inAppAdvertisement);
            setFilteredData(response?.data?.data?.inAppAdvertisement);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const getOutlets = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const response = await axios.get("outlet", { headers });
  
        if (response?.data?.status === true) {
          
          let filteredOutlets = response?.data?.data?.outlets?.filter((act) => act?.status === "ACTIVE");
          
          setOutlets(filteredOutlets);
        }
      } catch (error) {
        if (error.response && error.response?.data?.status === false) {
          let errorMessage = error.response.data.error;
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      }
    };

    const getProductsList = async (id) => {
      try {
          const headers = {
              Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("product", { headers });
          if (response?.data?.status === true) {
              console.log("Product Response :", response?.data?.data?.products);
              setProductsList(response?.data?.data?.products);
          }
      } catch (error) {
          if (error.response && error.response?.data?.status === false) {
              let errorMessage = error.response.data.error;
              toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
              });
          }
      }
    };

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        setSelectedProduct('');
        setSelectedExcludedOutlets([]);
        setInputData({
          title: ""
        });
        setImage(null);
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleImageChange = (e) => {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const selectedImage = e.target.files[0];
  
      if (selectedImage) {
        const extension = selectedImage.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          toast.error("Invalid file format. Upload only jpg or png.", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          return;
        } else {
          setImage(selectedImage);
        }
      }
    };

    const handleSelectChange = (selected) => {
      setSelectedExcludedOutlets(selected);
    };

    const handleSelectProductChange = (selected) => {
      setSelectedProduct(selected);      
    };

    const handleSubmit = async(e) => {
      setIsAddBtnLoading(true);
      e.preventDefault();
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`
        }
        const formData = new FormData();
        console.log("Input Data :", inputData);

        let selectedOutlets = selectedExcludedOutlets?.map((out) => out?.value);

        let newInputData = {
          ...inputData,
          excludedOutlets: selectedOutlets,
          productId: selectedProduct?.value
        }

        formData.append('title', newInputData.title);
        formData.append('productId', newInputData.productId);
        formData.append('advertisementImage', image);
        
        // Append each excluded outlet as excludedOutlets[0], excludedOutlets[1], etc.
        if (selectedOutlets?.length > 0) {
          selectedOutlets?.forEach((outlet, index) => {
            formData.append(`excludedOutlets[${index}]`, outlet);
          });
        }
        
        const response = await axios.post('in-app-advertisement', formData, { headers });
        console.log("Response :", response);
        if (response?.data?.status === true) {
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          e.target.reset();
          getInAppAddvertisement();
          setSelectedProduct('');
          setSelectedExcludedOutlets([]);
          setInputData({
            title: ""
          });
          setImage(null);
          setOpenAddModal(false);
        }
        
      } catch (error) {
        if (error?.response) {
          let errorMessage = '';
          if (
            error.response?.status === 406
          ) {
            errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
          }
          else if (
            error.response?.status === 412
          ) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error
          }else if(error?.response?.status === 404) {
            errorMessage = error?.response?.data?.error
          }
  
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } finally {
        setIsAddBtnLoading(false);
      }
    };

    const handleOpenEditModal = (data) => {
      console.log("Data :", data);
      setOpenEditModal(true);
      setEditedData({
        title: data?.title,
        isActive: data?.isActive,
        _id: data?._id
      })
      // Ensure selectedEditProduct is in { value, label } format
      setSelectedEditProduct(
        data?.productId ? { value: data.productId._id, label: data.productId.name } : null
      );
      let selectedExOutlet = data?.excludedOutlets;
      let mappedSelectedExOutlet = selectedExOutlet?.map((m) =>
        ({
          value: m?._id,
          label: m?.name
        }))
      setSelectedEditedExcludedOutlets(mappedSelectedExOutlet);
    };

    const handleCloseEditModal = () => {
      setOpenEditModal(false);
    };

    const handleEditChange = (e) => {
      const { name, value } = e.target;
      setEditedData((prevData) => ({ ...prevData, [name]: value }))
    };

    const handleEditSelectChange = (selected) => {
      setSelectedEditedExcludedOutlets(selected);
    };

    const handleEditSelectProductChange = (selected) => {
      setSelectedEditProduct(selected);
    };

    const handleEditSubmit = async (e) => {
      e.preventDefault();
      setIsEditBtnLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`
        }
    
        const selectedEditOutlets = selectedEditedExcludedOutlets?.map((out) => out?.value) || [];
        
        let newEditedData = {
          ...editedData,
          excludedOutlets: selectedEditOutlets.length > 0 ? selectedEditOutlets : null,
          productId: selectedEditProduct?.value
        }
        
        const formData = new FormData();
        formData.append('_id', newEditedData._id);
        formData.append('title', newEditedData.title);
        formData.append('isActive', newEditedData.isActive);
        formData.append('productId', newEditedData.productId);
        
        // Handle excluded outlets
        if (selectedEditOutlets.length > 0) {
          selectedEditOutlets.forEach((outlet, index) => {
            formData.append(`excludedOutlets[${index}]`, outlet);
          });
        } else {
          formData.append('excludedOutlets', 'null'); // Send null if no outlets
        }
    
        const response = await axios.put('in-app-advertisement', formData, { headers }); // Note: changed from newEditedData to formData
        if (response?.data?.status === true) {
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          getInAppAddvertisement();
          setOpenEditModal(false);
        }
      } catch (error) {
        if (error?.response) {
          let errorMessage = '';
          if (error.response?.status === 406) {
            errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error;
          }
          else if (error.response?.status === 412) {
            errorMessage = error?.response?.data?.data?.message;
          } else if (error.response?.status === 500) {
            errorMessage = error?.response?.data?.error;
          }
    
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } finally {
        setIsEditBtnLoading(false);
      }
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const handleImageClick = (data) => {
      setOpenImageModal(true);
      setSelectedImage(data);
    };

    const handleCloseImageModal = () => {
      setOpenImageModal(false);
    };

    const handleImageUploadModal = (data) => {
      setEditImage(data?.advertisementImage);
      setEditImageId(data?._id);
      setOpenImageUploadModal(true);
      setOpenImageModal(false);
    };

    const handleEditImageChange = (e) => {
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const selectedImage = e.target.files[0];
  
      if (selectedImage) {
        const extension = selectedImage.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          toast.error("Invalid file format. Upload only jpg or png.", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          return;
        } else {
          setEditImage(selectedImage);
        }
      }
    };

    const handleEditImage = async (e) => {
        e.preventDefault();
        setIsImageEditBtnLoading(true);
        try {
          const formData = new FormData();
          formData.append("advertisementImage", editImage);
          formData.append("_id", editImageId);
    
          const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          };
    
          const response = await axios.put(
            "in-app-advertisement",
            formData,
            { headers }
          );
    
          if (response?.data?.status === true) {
            toast.success(response?.data?.data?.message, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
            getInAppAddvertisement();
            setOpenImageUploadModal(false);
          }
        } catch (error) {
          if (error?.response?.status === 404) {
            toast.error(error.response.data.error, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
          if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response?.data?.data?.message;
    
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        } finally {
          setIsImageEditBtnLoading(false);
        }
    };

    const handleOpenViewModal = (data) => {

    };

    const handleOpenDeleteModal = (data) => {
      setOpenDeleteModal(true);
      setAdvertisementId(data?._id);
    };

    const handleCloseDeleteModal = () => {
      setOpenDeleteModal(false);
    };

    const handleDeletePopup = async(e) => {
      e.preventDefault();
      setIsDeleteBtnLoading(true);
      try {
        const headers = {
          Authorization: `Bearer ${accessToken}`
        }
        const response = await axios.delete(`in-app-advertisement?_id=${advertisementId}`, { headers });
        if (response?.data?.status === true) {
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          getInAppAddvertisement();
          setOpenDeleteModal(false);
        }
      } catch (error) {
        if (error.response.status === 403) {
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
  
        if (error.response && error.response?.data?.status === false) {
          let errorMessage = error.response.data.error;
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      } finally {
        setIsDeleteBtnLoading(false);
      }
    };

    const handlePageChange = (page) => {
      setPagination({ ...pagination, page });
    };
  
    const handleRowsPerPageChange = (rowsPerPage) => {
      setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };

    // Function to get paginated data
    const getPaginatedData = () => {
      const startIndex = (pagination?.page - 1) * pagination?.rowsPerPage;
      const endIndex = startIndex + pagination?.rowsPerPage;
      return filteredData?.slice(startIndex, endIndex);
    };  

    useEffect(() => {
        getInAppAddvertisement();
        getOutlets();
        getProductsList();
    }, []);

    useEffect(() => {
      const result = popUpData?.filter((item) => {
        return (
          item?.title?.toLowerCase().match(searchText.toLocaleLowerCase()),
          item?.productId?.name?.toLowerCase().match(searchText.toLocaleLowerCase())
        );
      });
      setFilteredData(result);
    }, [searchText]);

    return (
        <CContainer>
            <CRow className='mb-3'>
                <CCol className='text-end'>
                    <button
                        className="btn btn-warning w-25"
                        onClick={handleOpenAddModal}
                        style={{ borderRadius: "18px" }}
                    >
                        ADD NEW IN APP ADVERTISEMENT <FaPlus style={{ marginTop: "-4px" }} size={15} />
                    </button>
                </CCol>
            </CRow>

            <CRow>
                <CCol>
                    <DataTableComponent 
                        columns={updatedColumns}
                        title="POP-UP ADVERTISEMENT LIST"
                        searchText={searchText}
                        onChange={handleSearchChange}
                        // data={filteredData}
                        data={getPaginatedData()} // Use the paginated data here
                        pagination
                        totalRows={filteredData?.length}
                        paginationTotalRows={filteredData?.length} // Total number of items
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        paginationServer={false}
                    />
                </CCol>
            </CRow>

            {openAddModal ? (
                <CModal
                  size="lg"
                  alignment="center"
                  visible={openAddModal}
                  onClose={handleCloseAddModal}
                  aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle>ADD POP-UP ADVERTISEMENT</CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                        <CModalBody>
                            <CRow className='mb-2'>
                                <CCol md="6" lg="6">
                                    <CFormLabel className="fw-semibold">
                                      Pop-Up Title <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                      type="text"
                                      id="exampleFormControlInput1"
                                      placeholder="Pop-up Title"
                                      className="form-control"
                                      name="title"
                                      value={inputData.title}
                                      onChange={handleChange}
                                    />
                                </CCol>
                                <CCol md="6">
                                    <CFormLabel className="fw-semibold">
                                        Select Menu <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        options={productsList?.map((rm) => ({
                                            value: rm?._id,
                                            label: rm?.name,
                                        }))}
                                        name='productId'
                                        value={selectedProduct}
                                        onChange={handleSelectProductChange}
                                        classNamePrefix="react-select"
                                        isClearable
                                        placeholder="Select Menu"
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                              <CCol className="col-md-6">
                                <CFormLabel className="fw-semibold">
                                    Select Excluded Outlets
                                </CFormLabel>
              
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selectedExcludedOutlets}
                                    onChange={handleSelectChange}
                                    options={outletData}
                                    styles={customStyles}
                                    placeholder="Select Excluded Outlets" // You can also use a dynamic placeholder from props or state
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                              </CCol>
                              <CCol md="6" lg="6">
                                <div className="mb-3">
                                  <CFormLabel className="fw-semibold">
                                    Choose Image <span className="text-danger">*</span>
                                  </CFormLabel>
                                  <CFormInput
                                    type="file"
                                    onChange={handleImageChange}
                                    className="form-control"
                                    id="formFile"
                                    accept="image/*"
                                  />
                                </div>
                              </CCol>
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                          <PrimaryButton
                            // isDisable={disableButton()}
                            title="ADD POPUP ADVERTISEMENT"
                            isLoading={isAddBtnLoading}
                          />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null }

            {openEditModal ? (
                <CModal
                  size="lg"
                  alignment="center"
                  visible={openEditModal}
                  onClose={handleCloseEditModal}
                  aria-labelledby="VerticallyCenteredExample"
                >
                    <CModalHeader>
                        <CModalTitle>UPDATE POP-UP ADVERTISEMENT</CModalTitle>
                    </CModalHeader>
                    <CForm onSubmit={handleEditSubmit}>
                        <CModalBody>
                            <CRow className='mb-2'>
                                <CCol md="6" lg="6">
                                    <CFormLabel className="fw-semibold">
                                      Pop-Up Title <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                      type="text"
                                      id="exampleFormControlInput1"
                                      placeholder="Pop-up Title"
                                      className="form-control"
                                      name="title"
                                      value={editedData.title}
                                      onChange={handleEditChange}
                                    />
                                </CCol>
                                <CCol md="6">
                                    <CFormLabel className="fw-semibold">
                                        Select Menu <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        options={productsList?.map((rm) => ({
                                            value: rm?._id,
                                            label: rm?.name,
                                        }))}
                                        name='productId'
                                        value={selectedEditProduct}
                                        onChange={handleEditSelectProductChange}
                                        classNamePrefix="react-select"
                                        isClearable
                                        placeholder="Select Menu"
                                    />
                                </CCol>
                            </CRow>

                            <CRow>
                              <CCol className="col-md-6">
                                <CFormLabel className="fw-semibold">
                                    Select Excluded Outlets
                                </CFormLabel>
              
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selectedEditedExcludedOutlets}
                                    onChange={handleEditSelectChange}
                                    options={outletData}
                                    styles={customStyles}
                                    placeholder="Select Excluded Outlets" // You can also use a dynamic placeholder from props or state
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                              </CCol>
                              <CCol md="6">
                                <CFormLabel className="fw-semibold">
                                  Select Status <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                  size="sm"
                                  onChange={handleEditChange}
                                  name="isActive"
                                  value={editedData?.isActive}
                                >
                                  <option value="">Select Status</option>
                                  <option value="true">ACTIVE</option>
                                  <option value="false">INACTIVE</option>
                                </CFormSelect>
                              </CCol>
                            </CRow>
                        </CModalBody>
                        <CModalFooter>
                          <PrimaryButton
                            // isDisable={disableButton()}
                            title="UPDATE POPUP ADVERTISEMENT"
                            isLoading={isEditBtnLoading}
                          />
                        </CModalFooter>
                    </CForm>
                </CModal>
            ) : null }

            {openDeleteModal ? (
              <CModal
                alignment="center"
                visible={openDeleteModal}
                onClose={handleCloseDeleteModal}
                aria-labelledby="VerticallyCenteredExample"
              >
                <CModalHeader>
                  <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                    DELETE POPUP ADVERTISEMENT
                  </CModalTitle>
                </CModalHeader>
                <CForm onSubmit={handleDeletePopup}>
                  <CModalBody>
                    Are you sure you want to delete this PopUp Advertisement ?
                  </CModalBody>
                  <CModalFooter>
                    <CButton
                      color="warning"
                      onClick={handleCloseDeleteModal}
                      style={{
                        borderRadius: "18px",
                      }}
                    >
                      CLOSE
                    </CButton>
                    <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
                  </CModalFooter>
                </CForm>
              </CModal>
            ) : null}

            {openImageModal ? (
              <CModal visible={openImageModal} onClose={handleCloseImageModal}>
                <CModalHeader closeButton style={{ backgroundColor: "#F5F5F5" }}>
                  <CModalTitle>Image Preview</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  {selectedImage ? (
                    <>
                      <img
                        src={selectedImage?.advertisementImage}
                        alt="preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                      <hr className='mt-0 mb-0' />
                      <button
                        className="btn btn-primary rounded-pill mt-2"
                        variant="primary"
                        onClick={() =>
                          handleImageUploadModal({
                            advertisementImage: selectedImage?.advertisementImage,
                            _id: selectedImage?._id,
                          })
                        }
                      >
                        Change Image
                      </button>
                    </>
                  ) : (
                    <>
                      <p>No Image Profile. Upload a Picture.</p>
                      <button className="btn btn-primary rounded-pill mt-2">
                        Upload Image
                      </button>
                    </>
                  )}
                </CModalBody>
              </CModal>
            ) : null }

            {openImageUploadModal ? (
              <CModal
                alignment="center"
                visible={openImageUploadModal}
                onClose={() => setOpenImageUploadModal(false)}
                aria-labelledby="VerticallyCenteredExample"
              >
                <CModalHeader>
                  <CModalTitle id="VerticallyCenteredExample" className="fs-6">
                    UPDATE IMAGE
                  </CModalTitle>
                </CModalHeader>
                <CForm onSubmit={handleEditImage}>
                  <CModalBody>
                    <div className="mb-3">
                      <CFormLabel>
                        Choose File <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        type="file"
                        onChange={handleEditImageChange}
                        className="form-control"
                        id="formFile"
                      />
                    </div>
                  </CModalBody>
                  <CModalFooter>
                    <PrimaryButton
                      style={{ borderRadius: "18px" }}
                      title="SAVE CHANGES"
                      isLoading={isImageEditBtnLoading}
                    />
                  </CModalFooter>
                </CForm>
              </CModal>
            ) : null}
        </CContainer>
    )
}

export default InAppAdvertisement