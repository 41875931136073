import React, { useEffect, useState } from 'react';
import Select from "react-select";
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { CButton, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import { DeleteModal, PrimaryButton } from '../../components';
import DatePicker from "react-datepicker";
import makeAnimated from "react-select/animated";
import { format } from 'date-fns';
import { Spinner } from 'react-bootstrap';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderRadius: '6px',
        minWidth: "200px",
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#6c757d"
    }),
};

const ViewCoupon = () => {
    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [couponsData, setCouponsData] = useState([]);
    const [activeTab, setActiveTab] = useState("ACTIVE");
    const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
    const [couponId, setCouponId] = useState("");
    const [productsList, setProductsList] = useState([]);
    const [singleCouponDetails, setSingleCouponDetails] = useState({});
    const [outlets, setOutlets] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedExcludedOutlets, setSelectedExcludedOutlets] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("ALL");
    const [searchText, setSearchText] = useState('');
    const [addonList, setAddonList] = useState([]);
    const [selectedIncludedOutlets, setSelectedIncludedOutlets] = useState([]);
    const [levels, setLevels] = useState([]);
    const [searchActiveData, setSearchActiveData] = useState([]);
    const [searchInactiveData, setSearchInactiveData] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [currentTerm, setCurrentTerm] = useState('');
    const [openEditModal, setOpenEditModal] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);
    const [productAddonsList, setProductAddonsList] = useState([]);
    const [addonValueList, setAddonValueList] = useState([]);
    const [addonTitleId, setAddonTitleId] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState([]);
    const [selectedAddonValues, setSelectedAddonValues] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState(null);
    const [couponData, setCouponData] = useState({});
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [editData, setEditData] = useState({});
    const [exProductList, setExProductList] = useState([]);
    const [isSearchBtnLoading, setIsSearchBtnLoading] = useState(false);
    const [selections, setSelections] = useState({
        selectedIncludedOutlets: [],
        selectedExcludedOutlets: [],
        selectedApplicableCategories: [],
        selectedApplicableProducts: [],
        selectedAddons: [],
        selectedAddonValues: [],
        selectedDependentCategories: [],
        selectedDependentProducts: [],
        selectedExcludedProducts: [],
        selectedApplicableLevels: [],
        primaryComboCategories: [],
        secondaryComboCategories: []
    });
    const [sizeCouponProducts, setSizeCouponProducts] = useState({
        applicableProducts: []
    })

    const [editFilteredAddons, setEditFilteredAddons] = useState(null)

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;

        // Update the editCouponData state
        setSizeCouponProducts((prev) => ({
            ...prev,
            [name]: name === "applicableProducts" ? [value] : value,
        }));

        // Update the selectedProducts state
        if (name === "applicableProducts") {
            setSelectedProducts([{ value }]); // Ensuring it's an array with the selected value
        }
    };

    const activeCoupons = couponsData
        .filter((coupon) => {
            if (!coupon.validityEndDate && !coupon.validityStartDate) {
                return true;
            }
            const validityEndDate = new Date(coupon.validityEndDate);
            return validityEndDate >= new Date();
        })
        .sort((a, b) => {
            if (!a.validityEndDate && !b.validityEndDate) return 0;
            if (!a.validityEndDate) return -1;
            if (!b.validityEndDate) return 1;
            return new Date(a.validityEndDate) - new Date(b.validityEndDate);
        });

    const inactiveCoupons = couponsData
        .filter((coupon) => {
            if (!coupon.validityEndDate && !coupon.validityStartDate) {
                return false;
            }
            const validityEndDate = new Date(coupon.validityEndDate);
            return validityEndDate < new Date();
        })
        .sort((a, b) => {
            if (!a.validityEndDate && !b.validityEndDate) return 0;
            if (!a.validityEndDate) return -1;
            if (!b.validityEndDate) return 1;
            return new Date(a.validityEndDate) - new Date(b.validityEndDate);
        });

    const outletData = outlets
        .filter(
            (outlet) =>
                !selectedExcludedOutlets.some((selected) => selected.value === outlet._id) && // Exclude selected Excluded outlets
                !selectedIncludedOutlets.some((selected) => selected.value === outlet._id) // Exclude selected Included outlets
        )
        .map((outlet) => ({
            value: outlet._id,
            label: `${outlet.name} (${outlet.city})`,
        }));

    const categoriesList = categoriesData.map((category) => ({
        value: category._id,
        label: `${category.name}`
    }));

    const productsInfo = productsList.map((product) => ({
        value: product?._id,
        label: `${product?.name}`
    }));

    const exProductInfo = exProductList.map((pro) => ({
        value: pro?._id,
        label: `${pro?.name}`
    }))

    const addonsInfo = productAddonsList?.map((add) => ({
        value: add?._id,
        label: <span>
            {add?.addonTitle} <span className=''>{" -> "}</span> <span style={{ color: "grey" }}> {add?.product?.name}</span>
        </span>
    }))

    const addonsValueInfo = addonValueList?.flatMap((addon) =>
        addon?.originalAddonValues?.map((original) => ({
            adTitleId: addon._id,
            value: original?._id, // Assuming originalAddonValues have an _id property
            label: (
                <span>
                    {original?.value || "No Name"}{" "}
                    <span className="">{" -> "}</span> <span style={{ color: "grey" }}> {addon?.addonTitle}</span> <span className="">{" >> "}</span> <span style={{ color: "grey" }}> {addon?.product?.name}</span>
                </span>
            ),
        }))
    );

    const levelsData = levels.map((level) => ({
        value: level._id,
        label: `${level.sequenceNo}. ${level.title}`
    }))

    const handleSelectAddonValueChange = (val) => {
        // Map through the array of selected addon values
        let combinedData = val.map((addon) => ({
            name: addon?.label?.props?.children[0], // Extract the label
            adValueId: addon?.value,                       // Extract the value
            adTitleId: addon?.adTitleId
        }));
        setSelectedAddonValues(combinedData)
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const getAllCouponsData = async (search) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            let endPoint
            if (search) {
                endPoint = `coupons?search=${search}`
            } else {
                endPoint = `coupons`
            }


            const response = await axios.get(endPoint, { headers });

            if (response?.data?.status === true) {
                setCouponsData(response?.data?.data?.coupons);
            }
        } catch (error) {
            console.log("Error :", error);

            if (error.response?.data?.status === false) {
                let errorMessage = error.response?.data?.data?.message || "Resource not found";
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const filterCoupons = (coupons) => {
        if (selectedFilter === "ALL") {
            return coupons;
        }
        return coupons.filter((coupon) => {
            if (selectedFilter === "BOGO" && coupon?.isBogoCoupon) return true;
            if (selectedFilter === "UNIVERSAL" && coupon?.applicableProducts.length === 0 && coupon?.applicableQuantityType === null && coupon?.applicableCategories.length === 0 && coupon?.minOrderAmount === null && coupon?.isBogoCoupon === false && coupon?.primaryComboCategories?.length === 0 && coupon?.secondaryComboCategories?.length === 0) return true;
            if (selectedFilter === "CATEGORY" && coupon?.applicableCategories !== null && coupon?.applicableCategories.length > 0 && coupon?.applicableQuantityType === null && coupon?.primaryComboCategories?.length === 0 && coupon?.secondaryComboCategories?.length === 0) return true;
            if (selectedFilter === "PRODUCT" && coupon?.applicableProducts !== null && coupon?.applicableProducts.length > 0 && coupon?.applicableQuantityType === null && !coupon?.sizeUpgradeAddon && coupon?.primaryComboCategories?.length === 0 && coupon?.secondaryComboCategories?.length === 0) return true;
            if (selectedFilter === "SIZEUPGRADE" && coupon?.sizeUpgradeAddon) return true;
            if (selectedFilter === "CARTCOUPON" && coupon?.applicableProducts.length === 0 && coupon?.applicableQuantityType === null && coupon?.applicableCategories.length === 0 && coupon?.isBogoCoupon === false && coupon?.minOrderAmount !== null && coupon?.primaryComboCategories?.length === 0 && coupon?.secondaryComboCategories?.length === 0) return true;
            if (selectedFilter === "SPECIALPRODUCT" && coupon?.applicableProducts?.length > 0 && coupon?.applicableQuantityType !== null && coupon?.primaryComboCategories?.length === 0 && coupon?.secondaryComboCategories?.length === 0) return true;
            if (selectedFilter === "SPECIALCATEGORY" && coupon?.applicableCategories?.length > 0 && coupon?.applicableQuantityType !== null && coupon?.primaryComboCategories?.length === 0 && coupon?.secondaryComboCategories?.length === 0) return true;
            if (selectedFilter === "COMBOCOUPON" && coupon?.primaryComboCategories?.length > 0 && coupon?.secondaryComboCategories?.length > 0) return true;
        });
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const handleDeleteCoupon = async (e) => {
        e.preventDefault();
        setIsDeleteBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const data = {
                _id: couponId,
            };

            const response = await axios.delete(`coupons`, { data, headers });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getAllCouponsData(searchText);
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }

            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
            setOpenDeleteModal(false);
        } finally {
            setIsDeleteBtnLoading(false);
        }
    };

    const convertDate = (dateString) => {
        if (!dateString) {
            return "Invalid Date";
        }

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const dateParts = dateString.split("-");
        if (dateParts.length !== 3) {
            return "Invalid Date";
        }

        const year = dateParts[0];
        const month = parseInt(dateParts[1], 10) - 1;
        const day = dateParts[2];

        if (isNaN(month) || isNaN(day) || isNaN(year)) {
            return "Invalid Date";
        }

        const formattedDate = `${day} ${months[month]} ${year}`;

        return formattedDate;
    }

    const updatedDate = (date) => {
        const formattedDate = date?.split("T")[0];
        return formattedDate;
    };

    const handleOpenViewModal = (data) => {
        getSingleCouponDetails(data?._id);
        setOpenViewModal(true);
    };

    const handleDeleteModal = async (data) => {
        setOpenDeleteModal(true);
        setCouponId(data._id);
    };

    const handleEditStartDate = (date) => {
        if (date) {
            const formattedDate = format(new Date(date), "yyyy-MM-dd");
            setEditData((prev) => ({
                ...prev,
                validityStartDate: formattedDate,
            }));
        } else {
            setEditData((prev) => ({
                ...prev,
                validityStartDate: null,
            }));
        }
    };

    const handleEditEndDate = (date) => {
        if (date) {
            const formattedDate = format(new Date(date), "yyyy-MM-dd");
            setEditData((prev) => ({
                ...prev,
                validityEndDate: formattedDate,
            }));
        } else {
            setEditData((prev) => ({
                ...prev,
                validityEndDate: null,
            }));
        }
    };

    const getSingleCouponDetails = async (couponId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get(`coupons?_id=${couponId}`, { headers });

            if (response?.data?.status === true) {
                console.log("Single Coupons Information :", response?.data?.data?.coupons);

                setSingleCouponDetails(response?.data?.data?.coupons);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Open the Edit Modal and initialize the state with the current values for editing
    const handleOpenEditModal = (data) => {
        setOpenEditModal(true);
        setSelectedCheckbox(data);
        setCouponData(data);

        const formattedStartDate = data?.validityStartDate
            ? new Date(data.validityStartDate).toISOString().split("T")[0]
            : null;
        const formattedEndDate = data?.validityEndDate
            ? new Date(data.validityEndDate).toISOString().split("T")[0]
            : null;

        if (data?.primaryComboCategories.length > 0 || data?.secondaryComboCategories.length > 0) {
            setSelectedCheckbox('isComboCoupon')
        }

        if (data?.isBogoCoupon) {
            setSelectedCheckbox('isBogoCoupon');
        }

        if (
            (data?.discountAmount === null || data?.discountAmount === undefined) &&
            (data?.applicableQuantityType === null || data?.applicableQuantityType === undefined) &&
            (data?.discountPrice === null || data?.discountPrice === undefined) &&
            (data?.sizeUpgradeAddon === null || data?.sizeUpgradeAddon === undefined) &&
            (Array.isArray(data?.applicableCategories) && data?.applicableCategories.length === 0) &&
            (Array.isArray(data?.applicableProducts) && data?.applicableProducts.length === 0) &&
            (Array.isArray(data?.dependantCategories) && data?.dependantCategories.length === 0) &&
            (Array.isArray(data?.dependantProducts) && data?.dependantProducts.length === 0) &&
            (Array.isArray(data?.excludedProducts) && data?.excludedProducts.length === 0) &&
            (Array.isArray(data?.primaryComboCategories) && data?.primaryComboCategories.length === 0) &&
            (Array.isArray(data?.secondaryComboCategories) && data?.secondaryComboCategories.length === 0) &&
            data?.isBogoCoupon === false
        ) {
            setSelectedCheckbox('isUniversalCoupon');
        }


        if (data?.discountPercent !== null && data?.minOrderAmount !== null) {
            if (data?.discountAmount === null && data?.discountPrice === null && data?.applicableQuantityType === null && data?.sizeUpgradeAddon === null && data?.applicableCategories.length == 0 && data?.applicableProducts.length === 0 && data?.dependantCategories.length === 0 && data?.dependantProducts.length === 0 && data?.excludedProducts.length === 0 && data?.isBogoCoupon === false && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
                setSelectedCheckbox('isCartCoupon');
            }
        } else if (data?.discountAmount !== null && data?.minOrderAmount !== null) {
            if (data?.discountPercent === null && data?.maxDiscountAmount === null && data?.discountPrice === null && data?.applicableQuantityType === null && data?.sizeUpgradeAddon === null && data?.applicableCategories.length == 0 && data?.applicableProducts.length === 0 && data?.dependantCategories.length === 0 && data?.dependantProducts.length === 0 && data?.excludedProducts.length === 0 && data?.isBogoCoupon === false && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
                setSelectedCheckbox('isCartCoupon');
            }
        }

        if (data?.applicableCategories.length === 0 && data?.dependantCategories.length === 0 && data?.dependantProducts.length === 0 && data?.excludedProducts.length === 0 && data?.isBogoCoupon === false && data?.sizeUpgradeAddon !== null && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
            setSelectedCheckbox('isSizeUpgradeCoupon');
        }

        if (data.applicableCategories.length === 0 && data.applicableProducts.length !== 0 && data?.excludedProducts.length === 0 && data?.sizeUpgradeAddon === null && data.discountPrice === null && data.applicableQuantityType === null && data?.isBogoCoupon === false && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
            if (data?.discountPercent !== null && data?.discountAmount === null) {
                setSelectedCheckbox('isProductCoupon')
            } else if (data?.discountAmount !== null && data?.discountPercent === null && data?.maxDiscountAmount === null) {
                setSelectedCheckbox('isProductCoupon')
            }
        }

        if (data.applicableCategories.length !== 0 && data.applicableProducts.length === 0 && data.sizeUpgradeAddon === null && data.discountPrice === null && data.applicableQuantityType === null && data?.isBogoCoupon === false && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
            if (data?.discountPercent !== null && data?.discountAmount === null) {
                setSelectedCheckbox('isCategoryCoupon')
            } else if (data?.discountAmount !== null && data?.discountPercent === null && data?.maxDiscountAmount === null) {
                setSelectedCheckbox('isCategoryCoupon')
            }
        }

        if (data.applicableProducts.length === 0 && data.discountPercent === null && data.discountAmount === null && data.maxDiscountAmount === null && data.discountPrice !== null && data.applicableQuantityType !== null && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
            setSelectedCheckbox('isSpecialCategoryCoupon')
        }

        if (data.applicableCategories.length === 0 && data.discountPercent === null && data.discountAmount === null && data.maxDiscountAmount === null && data.discountPrice !== null && data.applicableQuantityType !== null && data?.primaryComboCategories.length === 0 && data?.secondaryComboCategories.length === 0) {
            setSelectedCheckbox('isSpecialProductCoupon')
        }

        const formattedSelections = {};


        if (data?.primaryComboCategories?.length || data?.secondaryComboCategories?.length) {
            formattedSelections.primaryComboCategories = data?.primaryComboCategories?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));

            formattedSelections.secondaryComboCategories = data?.secondaryComboCategories?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));
        }

        if (data?.includedOutlets?.length) {
            formattedSelections.selectedIncludedOutlets = data?.includedOutlets?.map((inc) => ({
                value: inc?._id,
                label: inc?.name,
            }));
        }

        if (data?.excludedOutlets?.length) {
            formattedSelections.selectedExcludedOutlets = data?.excludedOutlets?.map((exc) => ({
                value: exc?._id,
                label: exc?.name,
            }));
        }

        if (data?.applicableCategories?.length) {
            formattedSelections.selectedApplicableCategories = data?.applicableCategories?.map((cat) => ({
                value: cat?._id,
                label: cat?.name,
            }));
            let exProductValue = data?.applicableCategories?.map((cat) => ({
                value: cat?._id,
                label: cat?.name,
            }));
            getProductsList(exProductValue);
        }

        if (data?.applicableProducts?.length) {
            formattedSelections.selectedApplicableProducts = data?.applicableProducts?.map((prod) => ({
                value: prod?._id,
                label: prod?.name,
            }));
        }

        if (data?.applicableProducts?.length) {
            sizeCouponProducts.applicableProducts = data?.applicableProducts?.map((prod) => ({
                value: prod?._id,
                label: prod?.name,
            }));
        }

        let selectedProducts = data?.applicableProducts ? data?.applicableProducts : [];
        let mappedSelectedEditedApplicableProducts = selectedProducts?.map((m) => ({
            value: m?._id,
            label: m?.name
        }));

        // Always set `selectedAddons` and `selectedAddonValues` as empty arrays
        // formattedSelections.selectedAddons = [];
        // formattedSelections.selectedAddonValues = [];

        if (data?.dependantCategories?.length) {
            formattedSelections.selectedDependentCategories = data?.dependantCategories?.map((depCat) => ({
                value: depCat?._id,
                label: depCat?.name,
            }));
        }

        if (data?.dependantProducts?.length) {
            formattedSelections.selectedDependentProducts = data?.dependantProducts?.map((depProd) => ({
                value: depProd?._id,
                label: depProd?.name,
            }));
        }

        if (data?.excludedProducts?.length) {
            formattedSelections.selectedExcludedProducts = data?.excludedProducts?.map((excProd) => ({
                value: excProd?._id,
                label: excProd?.name,
            }));
        }

        if (data?.applicableLevels?.length) {
            formattedSelections.selectedApplicableLevels = data?.applicableLevels?.map((lvl) => ({
                value: lvl?._id,
                label: lvl?.title,
            }));
        }

        setSelectedProducts(mappedSelectedEditedApplicableProducts)
        setSelections(formattedSelections);

        console.log("data", data);


        const validFields = {
            _id: data?._id,
            isHidden: data?.isHidden,
            title: data?.title,
            couponCode: data?.couponCode,
            description: data?.description,
            usageType: data?.usageType,
            discountPercent: data?.discountPercent,
            discountPrice: data?.discountPrice,
            applicableQuantityType: data?.applicableQuantityType,
            discountAmount: data?.discountAmount,
            maxDiscountAmount: data?.maxDiscountAmount,
            validityStartDate: formattedStartDate,
            validityEndDate: formattedEndDate,
            termsAndConditions: data?.termsAndConditions,
            startTime: data?.startTime,
            endTime: data?.endTime,
            dayOfWeek: data?.dayOfWeek,
            minOrderAmount: data?.minOrderAmount,
            sizeUpgradeAddon: data?.sizeUpgradeAddon,
            isComboCategoryCoupon: data?.isComboCategoryCoupon,
            maxAddonsAllowed: data?.maxAddonsAllowed,
            isMrpDiscount: data?.isMrpDiscount
        };

        const filteredFields = Object.fromEntries(
            Object.entries(validFields).filter(([_, value]) => value !== null)
        );

        setEditData(filteredFields);
    }

    // CLOSE THE EDIT MODAL
    const handleCloseEditModal = () => {
        setOpenEditModal(false)
    }

    const handleEditFormInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "dayOfWeek" && (value === "null" || value === null)) {
            setEditData((prev) => ({ ...prev, [name]: null }))
        } else {
            setEditData((prev) => ({ ...prev, [name]: value }))
        }
    }

    const handleChange = (key, value) => {
        setSelections((prev) => ({
            ...prev,
            [key]: value,
        }));

        if (key === "selectedApplicableCategories") {
            getProductsList(value);
        }
    };

    //  SUBMIT EDIT FORM
    const handleSubmitEditForm = async (e) => {
        e.preventDefault();
        setIsEditBtnLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            // Map selected values
            const selectedIncludedOutlets = selections?.selectedIncludedOutlets?.map((option) => option.value) || [];
            const selectedExcludedOutlets = selections?.selectedExcludedOutlets?.map((option) => option.value) || [];
            const selectedApplicableCategories = selections?.selectedApplicableCategories?.map((option) => option.value) || [];
            const selectedApplicableProducts = selections?.selectedApplicableProducts?.map((option) => option.value) || [];
            const selectedApplicableLevels = selections?.selectedApplicableLevels?.map((option) => option.value) || [];
            const selectedDependentCategories = selections?.selectedDependentCategories?.map((option) => option.value) || [];
            const selectedDependentProducts = selections?.selectedDependentProducts?.map((option) => option.value) || [];
            const selectedExcludedProducts = selections?.selectedExcludedProducts?.map((option) => option.value) || [];
            const selectedPrimaryComboCategories = selections?.primaryComboCategories?.map((option) => option.value) || [];
            const selectedSecondaryComboCategories = selections?.secondaryComboCategories?.map((option) => option.value) || [];

            let selectedProductsData
            if (selectedCheckbox === 'isSizeUpgradeCoupon') {
                selectedProductsData = sizeCouponProducts?.applicableProducts.map((c) => c);
            }

            // Construct editData
            let updatedEditData = {
                ...editData,
                includedOutlets: selectedIncludedOutlets,
                excludedOutlets: selectedExcludedOutlets,
                applicableLevels: selectedApplicableLevels,
            };

            if (selectedCheckbox === 'isSizeUpgradeCoupon' && selectedProductsData?.length > 0) {
                updatedEditData = {
                    ...updatedEditData,
                    applicableProducts: selectedProductsData
                }
            }

            if (selectedPrimaryComboCategories.length > 0) {
                updatedEditData.primaryComboCategories = selectedPrimaryComboCategories
            }
            if (selectedSecondaryComboCategories.length > 0) {
                updatedEditData.secondaryComboCategories = selectedSecondaryComboCategories
            }

            // Conditionally add properties only if they have values
            if (selectedCheckbox==='isBogoCoupon') {
                updatedEditData.applicableCategories = selectedApplicableCategories.length === 0 ? null: selectedApplicableCategories;
            }

            if (selectedApplicableProducts.length > 0 && selectedCheckbox !== 'isSizeUpgradeCoupon') {
                updatedEditData.applicableProducts = selectedApplicableProducts;
            }

            if (selectedDependentCategories.length > 0) {
                updatedEditData.dependantCategories = selectedDependentCategories;
            }
            if (selectedDependentProducts.length > 0) {
                updatedEditData.dependantProducts = selectedDependentProducts;
            }

            // if (selectedExcludedProducts.length > 0) {
            //     updatedEditData.excludedProducts = selectedExcludedProducts;
            // }

            // if (selectedCheckbox === 'isSpecialCategoryCoupon' || selectedCheckbox === 'isCategoryCoupon') {
            //     updatedEditData.excludedProductList = selectedExcludedProducts;
            // }

            if (selectedCheckbox === 'isSpecialCategoryCoupon' || selectedCheckbox === 'isCategoryCoupon') {
                updatedEditData = {
                    ...updatedEditData,
                    excludedProductList: selectedExcludedProducts.length === 0 ? null : selectedExcludedProducts
                };
            }

            if (selectedCheckbox === 'isSpecialCategoryCoupon' || selectedCheckbox === "isSpecialProductCoupon") {
                delete updatedEditData.excludedProducts
            }

            // Remove `null` or `undefined` fields, but keep empty arrays
            Object.keys(updatedEditData).forEach((key) => {
                if (key === 'dayOfWeek') {
                    return;
                }

                if (updatedEditData[key] === null || updatedEditData[key] === undefined) {
                    delete updatedEditData[key];
                }

                if (updatedEditData[key] === "" || updatedEditData[key] === undefined) {
                    updatedEditData[key] = null
                }
            });

            // Axios call
            const response = await axios.put("coupons", updatedEditData, { headers });

            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getAllCouponsData(searchText);
                handleCloseEditModal();
                // setSelections({
                //     selectedIncludedOutlets: [],
                //     selectedExcludedOutlets: [],
                //     selectedApplicableCategories: [],
                //     selectedApplicableProducts: [],
                //     selectedAddons: [],
                //     selectedAddonValues: [],
                //     selectedDependentCategories: [],
                //     selectedDependentProducts: [],
                //     selectedExcludedProducts: [],
                //     selectedApplicableLevels: [],
                // });
                // setSelectedProduct("")
                // setSelectedProducts([]);
                // setSizeCouponProducts({
                //     applicableProducts: []
                // })
            }
        } catch (error) {
            if (error?.response) {
                let errorMessage = '';
                if (
                    error.response?.status === 406
                ) {
                    errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                }
                else if (
                    error.response?.status === 412 || error.response.status === 404
                ) {
                    errorMessage = error?.response?.data?.data?.message || error?.response?.data?.data?.title;
                } else if (error.response?.status === 500) {
                    errorMessage = error?.response?.data?.error
                }
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsEditBtnLoading(false);
        }
    };

    const handleCheckboxChange = (id) => {
        setSelectedCheckbox((prev) => (prev === id ? null : id));
        // setCouponData((prevData) => ({
        //     ...prevData,
        //     isBogoCoupon: id === 'isBogoCoupon',
        // }));
    };

    const getCategoryProductAddons = async (categ, prod, addIds) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }

            let categories = categ?.map((opt) => opt?.value);
            let products = prod?.map((opt) => opt?.value);

            console.log("Categories :", categories);
            console.log("Products :", products);

            // Construct the payload with arrays
            const payload = {
                applicableCategories: categories,
                applicableProducts: products,
            };

            if (payload?.applicableCategories?.length === 0) {
                delete payload?.applicableCategories
            }

            if (payload?.applicableProducts?.length === 0) {
                delete payload?.applicableProducts
            }

            console.log("Payload to send:", payload);

            const response = await axios.post("/coupons/get-dependant-product-category-addons", payload, { headers });
            setProductAddonsList(response?.data?.data?.addons);
            let filteredAddonValues = response?.data?.data?.addons?.filter((val) =>
                addIds?.includes(val?._id) // Check if val._id exists in addIds
            );
            setAddonValueList(filteredAddonValues);
        } catch (error) {
            console.log("Error :", error);
        }
    }

    const handleSelectProductChange = (prods) => {
        setSelectedProducts(prods);
        getCategoryProductAddons(selectedCategories, prods);
    }

    const handleSelectAddonsChange = (add) => {
        // Extract all addon values into the `addonTitleId` array
        const selectedIds = add.map((item) => item.value);
        getCategoryProductAddons(selectedCategories, selectedProducts, selectedIds);
        // Update the state with the selected IDs and addons
        setAddonTitleId(selectedIds);

        // Map through the array of selected addon values
        let combinedAddonTitleData = add.map((addon) => ({
            name: addon?.label?.props?.children[0], // Extract the label
            id: addon?.value                       // Extract the value
        }));
        setSelectedAddons(combinedAddonTitleData)
    }

    const handleSelectCategoryChange = (cats) => {
        setSelectedCategories(cats);
        getCategoryProductAddons(cats, selectedProducts);
    }

    // Fetches the category list from the server and updates state. 
    const getCategoryList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("masters/category/get", { headers });

            if (response?.data?.status === true) {
                setCategoriesData(response?.data?.data?.categories);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Fetches the products list from the server and updates state.
    const getProductsList = async (catId) => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);
                let excludedProducts = response?.data?.data?.products?.filter((fi) =>
                    catId.some((category) => category.value === fi?.category?._id)
                );
                setExProductList(excludedProducts);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // Fetches the outlet list from the server and updates state.
    const getOutlets = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("outlet", { headers });

            if (response?.data?.status === true) {
                setOutlets(response?.data?.data?.outlets);
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const getLevels = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("level", { headers });
            if (response?.data?.status === true) {
                const levels = response?.data?.data?.levels;
                setLevels(levels)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    const addTerm = () => {
        if (!currentTerm.trim()) return;

        setEditData((prevData) => ({
            ...prevData,
            termsAndConditions: [...(prevData?.termsAndConditions || []), currentTerm],
        }));

        setCurrentTerm(''); // Clear input after adding
    };

    const removeTerm = (index) => {
        setEditData((prevData) => ({
            ...prevData,
            termsAndConditions: (prevData?.termsAndConditions || []).filter(
                (_, i) => i !== index
            ),
        }));
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    // Sets the end date for the coupon, or logs an error if date is invalid.
    const handleToDateChange = (date) => {
        if (date) {
            setToDate(date);
        } else {
            console.error("Received null or undefined date");
        }
    };

    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
    };

    const handleSearchProducts = async (e) => {
        e.preventDefault();
        setIsSearchBtnLoading(true);
        // setClicked(true);

        // // Convert searchText to lowercase for case-insensitive search
        // const lowerCaseSearchText = searchText.toLowerCase();

        // // Filter the coupons based on title or couponCode
        // const searchedActiveCoupons = filteredActiveCoupons?.filter((coupon) =>
        //     coupon?.title?.toLowerCase().includes(lowerCaseSearchText) ||
        //     coupon?.couponCode?.toLowerCase().includes(lowerCaseSearchText)
        // );

        // const searchedInActiceCoupons = filteredInactiveCoupons?.filter((coupon) =>
        //     coupon?.title?.toLowerCase().includes(lowerCaseSearchText) ||
        //     coupon?.couponCode?.toLowerCase().includes(lowerCaseSearchText)
        // );

        // setSearchActiveData(searchedActiveCoupons);
        // setSearchInactiveData(searchedInActiceCoupons);

        await getAllCouponsData(searchText);
        setIsSearchBtnLoading(false);
    };

    const getAddonProductsList = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product-addon?limit=1000&page=1", { headers });
            if (response?.data?.status === true) {
                const addOns = response?.data?.data?.productAddons
                // const sizeAddons = addOns.filter((item) => item?.addonTitle === "Size");
                const productsWithSizeAddon = addOns.filter((item) =>
                    item.addons.some((addon) => addon.addonTitle.toLowerCase() === 'size')
                );
                setAddonList(productsWithSizeAddon)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };


    const filteredAddons = selectedProduct
        ? addonList.filter(rm => rm.product._id === selectedProduct)
        : [];
    const updatedFilteredAddons = filteredAddons?.map((item) => item.addons?.map((mm) => mm));

    const editFilteredAddon = selectedProducts
        ? addonList.filter((item) =>
            Array.isArray(selectedProducts)
                ? item.product?._id === selectedProducts[0]?.value
                : item.product?._id === selectedProducts
        )
        : [];

    const filteredActiveCoupons = clicked ? searchActiveData : filterCoupons(activeCoupons);

    const filteredInactiveCoupons = clicked ? searchInactiveData : filterCoupons(inactiveCoupons);


    useEffect(() => {
        getAllCouponsData();
        getCategoryList();
        getProductsList();
        getOutlets();
        getLevels();
        getAddonProductsList()
    }, []);

    useEffect(() => {
        if (searchText === "") {
            setClicked(false);
            getAllCouponsData();
        }
    }, [searchText]);

    return (
        <CContainer>
            {/* Dropdown for Filtering */}
            <CRow className="mb-3">
                <CCol md="5" lg="6" >
                    <CFormLabel className="fw-semibold">Filter By Coupon Type</CFormLabel>
                    <CFormSelect
                        aria-label="Filter coupons"
                        onChange={handleFilterChange}
                        value={selectedFilter}
                        className="ms-2 text-center"
                        style={{ textAlignLast: 'center' }}
                    >
                        <option value="ALL">ALL COUPONS</option>
                        <option value="CARTCOUPON">CART COUPON</option>
                        <option value="BOGO">BOGO COUPON</option>
                        <option value="PRODUCT">PRODUCT COUPON</option>
                        <option value="CATEGORY">CATEGORY COUPON</option>
                        <option value="UNIVERSAL">UNIVERSAL COUPON</option>
                        <option value="SIZEUPGRADE">SIZE UPGRADE COUPON</option>
                        <option value="SPECIALPRODUCT">SPECIAL PRODUCT COUPON</option>
                        <option value="SPECIALCATEGORY">SPECIAL CATEGORY COUPON</option>
                        <option value="COMBOCOUPON">COMBO COUPON</option>
                    </CFormSelect>
                </CCol>
                <CCol md="5" lg="4">
                    <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                    <CFormInput
                        type="text"
                        placeholder="Search By Coupon Name Or Coupon Code"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </CCol>
                <CCol md="2" lg="2" className="text-center">
                    <CFormLabel className="fw-semibold text-white">.</CFormLabel>
                    <CButton className="btn rounded-pill w-100" disabled={!searchText} onClick={handleSearchProducts}>
                        {isSearchBtnLoading ? <Spinner size='sm' /> : "Search"}
                    </CButton>
                </CCol>
            </CRow>

            {/* TABS CODE  */}
            <div
                className="d-flex justify-content-center gap-2 mb-2"
                style={{ cursor: "pointer" }}
            >
                <div
                    color="none"
                    className={`w-50 ${activeTab === "ACTIVE" ? "text-secondary" : "text-dark"
                        } text-center p-2 fw-semibold`}
                    onClick={() => handleTabChange("ACTIVE")}
                    style={{
                        borderBottom:
                            activeTab === "ACTIVE"
                                ? "2px solid #bcbcbd"
                                : "1px solid #bcbcbd",
                        transition: "border-bottom 0.3s ease-in-out",
                        background: activeTab === "ACTIVE" ? "#e7e9eb" : "none",
                        border: "1px solid #bcbcbd",
                        borderRadius: "5px",
                    }}
                >
                    ACTIVE
                </div>
                <div
                    color="none"
                    className={`w-50 ${activeTab === "INACTIVE" ? "text-secondary" : "text-dark"
                        } text-center p-2 fw-semibold`}
                    onClick={() => handleTabChange("INACTIVE")}
                    style={{
                        borderBottom:
                            activeTab === "INACTIVE"
                                ? "2px solid #bcbcbd"
                                : "1px solid #bcbcbd",
                        transition: "border-bottom 0.3s ease-in-out",
                        background: activeTab === "INACTIVE" ? "#e7e9eb" : "none",
                        border: "1px solid #bcbcbd",
                        borderRadius: "5px",
                    }}
                >
                    INACTIVE
                </div>
            </div>

            {activeTab === "ACTIVE" && (
                <CRow>
                    {filteredActiveCoupons?.map((item, index) => (
                        <CCol key={index} sm="12" md="6" lg="4" xl="3" className="mb-4">
                            <div className="coupon-card shadow-sm rounded border">
                                <div className="coupon-header py-3 text-center border-bottom">
                                    <h6 className="coupon-title ">{item?.title}</h6>
                                </div>
                                <div className="coupon-body p-3 mb-4">
                                    <h6 className="coupon-discount text-center text-success fw-bold mb-3">
                                        {item?.discountAmount !== null && `₹ ${item?.discountAmount} OFF`}
                                        {item?.discountPrice !== null && `₹ ${item?.discountPrice}`}
                                        {item?.discountPercent !== null && `${item?.discountPercent}% OFF`}
                                        {item?.sizeUpgradeAddon && "Size Upgrade Available"}
                                        {item?.isBogoCoupon && (
                                            <span className="ms-2">BOGO</span>
                                        )}
                                    </h6>
                                    <div className="coupon-details text-muted" style={{ fontSize: "14px" }}>
                                        {item?.applicableCategories?.length === 0 &&
                                            item?.applicableProducts?.length === 0 &&
                                            !item?.isBogoCoupon ? (
                                            <p>Applies to all items in our store.</p>
                                        ) : null}
                                        {item?.applicableCategories?.length > 0 && (
                                            <p>
                                                <span className='fw-semibold text-muted' style={{ fontSize: '14px' }}>Applicable Categories:</span>{" "}
                                                {item?.applicableCategories.map((cat, index) => (
                                                    <span key={index}>
                                                        {cat?.name}
                                                        {index < item.applicableCategories.length - 1 ? ", " : "."}
                                                    </span>
                                                ))}
                                            </p>
                                        )}
                                        {item?.applicableProducts?.length > 0 && (
                                            <p>
                                                <span className='fw-semibold text-muted' style={{ fontSize: '14px' }}>Applicable Products:</span>{" "}
                                                {item?.applicableProducts.map((prod, index) => (
                                                    <span key={index}>
                                                        {prod?.name}
                                                        {index < item.applicableProducts.length - 1 ? ", " : "."}
                                                    </span>
                                                ))}
                                            </p>
                                        )}
                                    </div>
                                    <p className="validity text-center mt-3" style={{ fontSize: "13px" }}>
                                        {item?.validityEndDate ? (
                                            <>
                                                <strong className='text-muted'>Valid until:</strong>{" "}
                                                <span className="text-danger">
                                                    {new Intl.DateTimeFormat("en-GB", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                        timeZone: "UTC",
                                                    }).format(new Date(item.validityEndDate))}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="text-warning">Valid for unlimited duration</span>
                                        )}
                                    </p>
                                    <div className="text-center mt-2" style={{ border: '2px dashed #dfeef5' }}>
                                        <p>
                                            <span className='fw-semibold text-muted'>Coupon Code:</span>{" "}
                                            <span className="fw-semibold" style={{ color: '#fc006b' }}>{item?.couponCode}</span>
                                        </p>
                                    </div>
                                    <div className="coupon-hidden-status text-center mt-3">
                                        <span className="fw-semibold text-muted" style={{ fontSize: "14px" }}>
                                            Coupon Hidden :{" "}
                                            <span
                                                className={`${item?.isHidden ? "text-danger" : "text-success"
                                                    }`}
                                            >
                                                {item?.isHidden ? "YES" : "NO"}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="coupon-hidden-status text-center mb-0">
                                        <span className="fw-semibold text-muted" style={{ fontSize: "14px" }}>Mrp Coupon :{" "}
                                            <span
                                                className={`${item?.isMrpDiscount ? "text-danger" : "text-success"
                                                    }`}
                                            >
                                                {item?.isMrpDiscount ? "YES" : "NO"}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="coupon-footer py-2 d-flex justify-content-around" style={{ backgroundColor: '#ededed' }}>
                                    <button
                                        className="btn btn-primary btn-sm px-3"
                                        onClick={() => handleOpenViewModal(item)}
                                    >
                                        Details
                                    </button>
                                    <button
                                        className="btn btn-warning btn-sm px-3"
                                        onClick={() => handleOpenEditModal(item)}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm px-3"
                                        onClick={() => handleDeleteModal(item)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </CCol>
                    ))}
                </CRow>
            )}

            {activeTab === "INACTIVE" && (
                <CRow>
                    {/* filteredInactiveCoupons, inactiveCoupons */}
                    {filteredInactiveCoupons?.map((item, index) => (
                        <CCol key={index} sm="12" md="6" lg="4" xl="3" className="mb-4">
                            <div className="coupon-card shadow-sm rounded border">
                                <div className="coupon-header py-3 text-center border-bottom">
                                    <h6 className="coupon-title ">{item?.title}</h6>
                                </div>
                                <div className="coupon-body p-3 mb-4">
                                    <h6 className="coupon-discount text-center text-success fw-bold mb-3">
                                        {item?.discountAmount !== null && `₹ ${item?.discountAmount} OFF`}
                                        {item?.discountPrice !== null && `₹ ${item?.discountPrice}`}
                                        {item?.discountPercent !== null && `${item?.discountPercent}% OFF`}
                                        {item?.sizeUpgradeAddon && "Size Upgrade Available"}
                                        {item?.isBogoCoupon && (
                                            <span className="ms-2">BOGO</span>
                                        )}
                                    </h6>
                                    <div className="coupon-details text-muted" style={{ fontSize: "14px" }}>
                                        {item?.applicableCategories?.length === 0 &&
                                            item?.applicableProducts?.length === 0 &&
                                            !item?.isBogoCoupon ? (
                                            <p>Applies to all items in our store.</p>
                                        ) : null}
                                        {item?.applicableCategories?.length > 0 && (
                                            <p>
                                                <span className='text-muted fw-semibold'>Applicable Categories: </span>{" "}
                                                {item?.applicableCategories.map((cat, index) => (
                                                    <span key={index}>
                                                        {cat?.name}
                                                        {index < item.applicableCategories.length - 1 ? ", " : "."}
                                                    </span>
                                                ))}
                                            </p>
                                        )}
                                        {item?.applicableProducts?.length > 0 && (
                                            <p>
                                                <span className='fw-semibold text-muted'>Applicable Products: </span>{" "}
                                                {item?.applicableProducts.map((prod, index) => (
                                                    <span key={index}>
                                                        {prod?.name}
                                                        {index < item.applicableProducts.length - 1 ? ", " : "."}
                                                    </span>
                                                ))}
                                            </p>
                                        )}
                                    </div>
                                    <p className="validity text-center mt-3" style={{ fontSize: "13px" }}>
                                        {item?.validityEndDate ? (
                                            <>
                                                <strong className='text-muted'>Valid until:</strong>{" "}
                                                <span className="text-danger">
                                                    {new Intl.DateTimeFormat("en-GB", {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                        timeZone: "UTC",
                                                    }).format(new Date(item.validityEndDate))}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="text-warning">Valid for unlimited duration</span>
                                        )}
                                    </p>
                                    <div className="text-center mt-2" style={{ border: '2px dashed #dfeef5' }}>
                                        <p>
                                            <span className='text-muted fw-semibold'>Coupon Code:</span>{" "}
                                            <span className="fw-semibold" style={{ color: '#fc006b' }}>{item?.couponCode}</span>
                                        </p>
                                    </div>
                                    <div className="coupon-hidden-status text-center mt-3">
                                        <p className="fw-semibold text-muted" style={{ fontSize: "14px" }}>
                                            Coupon Hidden:{" "}
                                            <span
                                                className={`${item?.isHidden ? "text-danger" : "text-success"
                                                    }`}
                                            >
                                                {item?.isHidden ? "YES" : "NO"}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="coupon-footer py-2 d-flex justify-content-around" style={{ backgroundColor: '#ededed' }}>
                                    <button
                                        className="btn btn-primary btn-sm px-3"
                                        onClick={() => handleOpenViewModal(item)}
                                    >
                                        Details
                                    </button>
                                    <button
                                        className="btn btn-warning btn-sm px-3"
                                        onClick={() => handleOpenEditModal(item)}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm px-3"
                                        onClick={() => handleDeleteModal(item)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </CCol>
                    ))}
                </CRow>
            )}


            {/* DELETE MODAL  */}
            <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteCoupon} isLoading={isDeleteBtnLoading} title="DELETE COUPON" />

            {/* VIEW COUPON DETAILS MODAL  */}
            <CModal
                alignment="center"
                visible={openViewModal}
                onClose={() => setOpenViewModal(false)}
                aria-labelledby="VerticallyCenteredExample"
                size="lg"
            >
                <div style={{ padding: "10px", borderRadius: "8px", margin: "10px" }}>
                    <h5 className='text-center' style={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        color: "#4CAF50", // Vibrant green for positivity and attention
                        textDecoration: "none", // Removed underline for a cleaner look
                        marginBottom: "15px",
                        textTransform: "uppercase",
                        letterSpacing: "1.5px",
                        background: "linear-gradient(90deg, #4CAF50, #009688)", // Gradient for more visual impact
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                    }}>
                        {singleCouponDetails?.title || "Amazing Coupon Offer"}
                    </h5>

                    <div className='text-center w-100 mb-1' style={{
                        display: "inline-block",
                        backgroundColor: "#09a5f7",
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "20px",
                        padding: "5px 10px",
                        borderRadius: "16px",
                        letterSpacing: "2px",
                        textTransform: "uppercase",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)"
                    }}>
                        {"★"} {singleCouponDetails?.couponCode || "COUPON123"} {"★"}
                    </div>

                    {singleCouponDetails?.discountAmount || singleCouponDetails?.discountPercent || singleCouponDetails?.discountPrice ? (
                        <div style={{ marginBottom: "10px", color: "#28a745", fontWeight: "500", fontSize: "1.3em", textAlign: "center" }}>
                            {singleCouponDetails?.discountAmount
                                ? `${singleCouponDetails.discountAmount} Rs OFF`
                                : singleCouponDetails?.discountPercent
                                    ? `${singleCouponDetails.discountPercent}% OFF`
                                    : `₹. ${singleCouponDetails.discountPrice}`}
                        </div>
                    ) : null}

                    <div style={{ borderTop: "1px solid #ddd", paddingTop: "15px", marginTop: "10px" }}>
                        <CRow className='mb-2'>
                            <CCol md={6}>
                                <h6>
                                    <span className="fw-semibold text-muted">Coupon Code :</span>{" "}
                                    <span className="text-info" style={{ fontSize: "15px" }}>
                                        {singleCouponDetails?.couponCode || "N/A"}
                                    </span>
                                </h6>
                            </CCol>

                            <CCol md={6}>
                                <h6>
                                    <span className="fw-semibold text-muted">Usage Type :</span>{" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.usageType || "N/A"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        <CRow className='mb-2'>
                            <CCol md={6}>
                                <h6>
                                    <span className="fw-semibold text-muted">Is Coupon Hidden :</span>{" "}
                                    {singleCouponDetails?.isHidden ? (
                                        <span className="text-danger fw-semibold">YES</span>
                                    ) : (
                                        <span className="text-success fw-semibold">NO</span>
                                    )}
                                </h6>
                            </CCol>

                            <CCol md={6}>
                                <h6 className="text-muted">
                                    <span className="fw-semibold text-muted">Addon Name :</span>{" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.sizeUpgradeAddon?.addonTitle || "N/A"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        <CRow className='mb-2'>
                            <CCol md={6}>
                                <h6>
                                    <span className="fw-semibold text-muted">Applicable Quantity Type :</span>{" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.applicableQuantityType || "N/A"}
                                    </span>
                                </h6>
                            </CCol>

                            {
                                singleCouponDetails?.discountPercent && (
                                    <CCol md="6">
                                        <h6 className="fw-semibold text-muted">
                                            Discount Percent : {" "}
                                            <span className="text-success">
                                                {singleCouponDetails?.discountPercent} % OFF
                                            </span>
                                        </h6>
                                    </CCol>
                                )
                            }
                        </CRow>

                        <CRow className='mb-2'>
                            <CCol md="6" >
                                <h6 className="fw-semibold text-muted">
                                    Valid From : {" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.validityStartDate
                                            ? convertDate(updatedDate(singleCouponDetails.validityStartDate))
                                            : "No Validity"}
                                    </span>
                                </h6>
                            </CCol>
                            <CCol md="6">
                                <h6 className="fw-semibold text-muted">
                                    Valid Until : {" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.validityEndDate
                                            ? convertDate(updatedDate(singleCouponDetails.validityEndDate))
                                            : "No Validity"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        <CRow className='mb-2'>
                            <CCol md="6" >
                                <h6 className="fw-semibold text-muted" >
                                    Start Time : {" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.startTime
                                            ? singleCouponDetails?.startTime
                                            : "No Start Time"}
                                    </span>
                                </h6>
                            </CCol>
                            <CCol md="6">
                                <h6 className="fw-semibold text-muted">
                                    End Time : {" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.validityEndDate
                                            ? singleCouponDetails?.endTime
                                            : "No End Time"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        <CRow className='mb-2'>
                            <CCol md="6" >
                                <h6 className="fw-semibold text-muted" >
                                    Day Of Week : {" "}
                                    <span className="text-secondary">
                                        {singleCouponDetails?.dayOfWeek !== undefined && singleCouponDetails?.dayOfWeek !== null ? ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][singleCouponDetails?.dayOfWeek] : "No Specific Day Or Everyday"}
                                    </span>
                                </h6>
                            </CCol>
                            <CCol md="6">
                                <h6 className="fw-semibold text-muted">
                                    Discount Amount : {" "}
                                    <span className="text-success">
                                        {singleCouponDetails?.discountAmount ? `₹. ${singleCouponDetails?.discountAmount}` : "No Discount Amt"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        <CRow className='mb-2'>
                            <CCol md="6" >
                                <h6 className="fw-semibold text-muted" >
                                    Min Dis. Amount : {" "}
                                    <span className="text-secondary text-success">
                                        {singleCouponDetails?.minOrderAmount ? `₹. ${singleCouponDetails?.minOrderAmount}` : "No Min Order Amt"}
                                    </span>
                                </h6>
                            </CCol>
                            <CCol md="6">
                                <h6 className="fw-semibold text-muted">
                                    Max Dis. Amount : {" "}
                                    <span className="text-secondary text-success">
                                        {singleCouponDetails?.maxDiscountAmount ? `₹. ${singleCouponDetails?.maxDiscountAmount}` : "No Max Dis. Amt"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol>
                                <h6 className="fw-semibold text-muted">
                                    Max Addons Allowed : {" "}
                                    <span className='text-secondary'>
                                        {singleCouponDetails?.maxAddonsAllowed === null ? "No Restrictions" : singleCouponDetails?.maxAddonsAllowed}
                                    </span>
                                </h6>
                            </CCol>
                            <CCol>
                                <h6 className="fw-semibold text-muted">
                                    Is MRP Coupon : {" "}
                                    <span className='text-secondary'>
                                        {singleCouponDetails?.isMrpDiscount === true ? "YES" : "NO"}
                                    </span>
                                </h6>
                            </CCol>
                        </CRow>

                        {
                            singleCouponDetails?.applicableProducts?.length === 0 && (
                                <CCol
                                    md={12}
                                    className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                    style={{
                                        fontSize: "1em",
                                        color: "#444",
                                        background: "#f9f9f9",
                                        padding: "8px",
                                        borderRadius: "8px",
                                        border: '1px dashed purple'
                                    }}
                                >
                                    {singleCouponDetails?.applicableCategories?.length > 0 ? (
                                        <div className='d-flex algin-items-center'>
                                            <h6 style={{ marginRight: "8px", fontSize: '13px' }} className='fw-semibold text-muted mt-2'>Applicable Categories : </h6>
                                            {singleCouponDetails.applicableCategories.map((cat, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        background: "#e8f5e9",
                                                        color: "#388e3c",
                                                        borderRadius: "16px",
                                                        padding: "4px 8px",
                                                        fontWeight: "500",
                                                        marginRight: "5px",
                                                        fontSize: '11px',
                                                        alignSelf: 'center'
                                                    }}
                                                >
                                                    {cat.name}
                                                </span>
                                            ))}
                                        </div>
                                    ) : (
                                        <span
                                            className="text-success fw-semibold"
                                            style={{
                                                fontSize: '11px',
                                                alignSelf: 'center',
                                                background: "#e8f5e9",
                                                color: "#388e3c",
                                                padding: "10px",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            On All Categories In Our Store
                                        </span>
                                    )}
                                </CCol>
                            )
                        }

                        {
                            singleCouponDetails?.applicableLevels?.length > 0 && (
                                <CCol
                                    md={12}
                                    className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                    style={{
                                        fontSize: "1em",
                                        color: "#444",
                                        background: "#f9f9f9",
                                        padding: "8px",
                                        borderRadius: "8px",
                                        border: '1px dashed green'
                                    }}
                                >
                                    <div className='d-flex align-items-center'>
                                        <h6 className='text-muted fw-semibold mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Applicable Levels : </h6>
                                        {singleCouponDetails.applicableLevels.map((level, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    background: "#e3f2fd",
                                                    color: "#1976d2",
                                                    borderRadius: "16px",
                                                    padding: "5px 10px",
                                                    fontWeight: "500",
                                                    marginRight: "5px",
                                                    fontSize: '11px',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                {level.title}
                                            </span>
                                        ))}
                                    </div>
                                </CCol>
                            )
                        }

                        {singleCouponDetails?.applicableCategories?.length === 0 && (
                            <CCol
                                md={12}
                                className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    border: '1px dashed purple'
                                }}
                            >
                                {singleCouponDetails?.applicableProducts?.length > 0 ? (
                                    <div className='d-flex align-items-center'>
                                        <h6 className='text-muted mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Applicable Products : </h6>
                                        {singleCouponDetails.applicableProducts.map((product, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    background: "#e3f2fd",
                                                    color: "#1976d2",
                                                    borderRadius: "16px",
                                                    padding: "5px 10px",
                                                    fontWeight: "500",
                                                    marginRight: "5px",
                                                    fontSize: '11px',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                {product?.name}
                                            </span>
                                        ))}
                                    </div>
                                ) : (
                                    <span
                                        className="text-success fw-semibold"
                                        style={{
                                            fontSize: '11px',
                                            alignSelf: 'center',
                                            background: "#e8f5e9",
                                            color: "#388e3c",
                                            padding: "10px",
                                            borderRadius: "8px",
                                        }}
                                    >
                                        On All Products In Our Store
                                    </span>
                                )}
                            </CCol>
                        )}

                        {singleCouponDetails?.excludedOutlets?.length > 0 && (
                            <CCol
                                md={12}
                                className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    border: '1px dashed red'
                                }}
                            >
                                <div className='d-flex align-items-center'>
                                    <h6 className='text-muted mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Excluded Outlets : </h6>
                                    {singleCouponDetails.excludedOutlets.map((outlet, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#ffebee",
                                                color: "#d32f2f",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {outlet?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        {singleCouponDetails?.excludedProducts?.length > 0 && (
                            <CCol
                                md={12}
                                className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    marginBottom: "10px",
                                    border: '1px dashed red'
                                }}
                            >
                                <div className='d-flex align-items-center'>
                                    <h6 className='fw-semibold text-muted mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Excluded Products : </h6>
                                    {singleCouponDetails.excludedProducts.map((product, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#fce4ec",
                                                color: "#c2185b",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {product?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        {singleCouponDetails?.dependantProducts?.length > 0 && (
                            <CCol
                                md={12}
                                className="d-flex flex-wrap align-items-center gap-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    marginBottom: "10px",
                                    border: '1px dashed #00a6ff'
                                }}
                            >
                                <div className='d-flex align-items-center'>
                                    <h6 className='text-muted fw-semibold mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Dependant Products : </h6>
                                    {singleCouponDetails.dependantProducts.map((product, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#ede7f6",
                                                color: "#512da8",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {product?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        {singleCouponDetails?.dependantCategories?.length > 0 && (
                            <CCol
                                md={12}
                                className="d-flex flex-wrap align-items-center gap-2 m-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    marginBottom: "10px",
                                    border: '1px dashed #0099ff'
                                }}
                            >
                                <div className='d-flex align-items-center'>
                                    <h6 className='text-muted fw-semibold mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Dependant Categories : </h6>
                                    {singleCouponDetails.dependantCategories.map((category, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#e8f5e9",
                                                color: "#388e3c",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {category?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        {singleCouponDetails?.includedOutlets?.length > 0 && (
                            <CCol md={12}
                                className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    marginBottom: "10px",
                                    border: '1px dashed green'
                                }}
                            >
                                <div className='d-flex algin-items-center'>
                                    <h6 className='text-muted fw-semibold mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Included Outlets : </h6>
                                    {singleCouponDetails.includedOutlets.map((outlet, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#e8f5e9",
                                                color: "#388e3c",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {outlet?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        {singleCouponDetails?.primaryComboCategories?.length > 0 && (
                            <CCol md={12}
                                className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    marginBottom: "10px",
                                    border: '1px dashed green'
                                }}
                            >
                                <div className='d-flex algin-items-center'>
                                    <h6 className='text-muted fw-semibold mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Primary Combo Categories : </h6>
                                    {singleCouponDetails.primaryComboCategories.map((outlet, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#e8f5e9",
                                                color: "steelblue",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {outlet?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        {singleCouponDetails?.secondaryComboCategories?.length > 0 && (
                            <CCol md={12}
                                className="d-flex flex-wrap align-items-center gap-2 mb-2"
                                style={{
                                    fontSize: "1em",
                                    color: "#444",
                                    background: "#f9f9f9",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    marginBottom: "10px",
                                    border: '1px dashed green'
                                }}
                            >
                                <div className='d-flex algin-items-center'>
                                    <h6 className='text-muted fw-semibold mt-2' style={{ marginRight: "8px", fontSize: '13px' }}>Secondary Combo Categories : </h6>
                                    {singleCouponDetails.secondaryComboCategories.map((outlet, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: "#e8f5e9",
                                                color: "burlywood",
                                                borderRadius: "16px",
                                                padding: "5px 10px",
                                                fontWeight: "500",
                                                marginRight: "5px",
                                                fontSize: '11px',
                                                alignSelf: 'center'
                                            }}
                                        >
                                            {outlet?.name}
                                        </span>
                                    ))}
                                </div>
                            </CCol>
                        )}

                        <CRow className='mt-2 mb-2'>
                            <h6 className="text-muted fw-semibold">
                                Description : <span className="text-secondary" style={{ fontSize: '14px' }}>{singleCouponDetails?.description}</span>
                            </h6>
                        </CRow>

                        {singleCouponDetails?.termsAndConditions?.length > 0 && (
                            <CRow className="mb-4">
                                <h6 className="text-primary fw-semibold mb-1">Terms and Conditions:</h6>
                                <div className="ms-3 text-secondary">
                                    <ul style={{ fontSize: "14px", paddingLeft: "25px", margin: 0, lineHeight: "1.6" }}>
                                        {singleCouponDetails.termsAndConditions.map((term, index) => (
                                            <li key={index} style={{ marginBottom: "8px" }}>
                                                {term}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </CRow>
                        )}

                    </div>
                </div>
            </CModal>

            {/* EDIT COUPON DETAILS MODAL  */}
            <CModal
                alignment="center"
                visible={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="VerticallyCenteredExample"
                size="xl"
            >
                <CModalHeader>
                    <CModalTitle>EDIT COUPON DETAILS</CModalTitle>
                </CModalHeader>

                <CForm onSubmit={handleSubmitEditForm}>
                    <CModalBody>
                        <CRow className="mb-3">
                            {[
                                { id: 'isBogoCoupon', label: 'BOGO COUPON', description: 'BOGO coupon allows you to buy one and get another one free.' },
                                { id: 'isUniversalCoupon', label: 'UNIVERSAL COUPON', description: 'Universal coupon can be applied to any item in the store.' },
                                { id: 'isCartCoupon', label: 'CART COUPON', description: 'Cart coupon gives a discount on the entire cart.' },
                                { id: 'isSizeUpgradeCoupon', label: 'SIZE UPGRADE COUPON', description: 'Size upgrade coupon lets you upgrade the product size for free or a discount.' },
                                { id: 'isProductCoupon', label: 'PRODUCT COUPON', description: 'Product coupon provides a discount on a specific product.' },
                                { id: 'isCategoryCoupon', label: 'CATEGORY COUPON', description: 'Category coupon gives a discount on all items in a particular category.' },
                                { id: 'isSpecialCategoryCoupon', label: 'SPECIAL CATEGORY COUPON', description: 'Special category coupon applies to selected special categories only.' },
                                { id: 'isSpecialProductCoupon', label: 'SPECIAL PRODUCT COUPON', description: 'Special product coupon offers discounts on special, featured products.' },
                                { id: 'isComboCoupon', label: 'COMBO COUPON', description: 'Combo Coupon type allow you to create a coupon in which two products from two category group can be sold at specific price or specific discount can be applied.' },
                            ].map((button) => (
                                <CCol md={3} className="mb-3" key={button.id}>
                                    <CButton
                                        color={selectedCheckbox === button.id ? 'primary' : 'secondary'}
                                        variant={selectedCheckbox === button.id ? 'solid' : 'outline'}
                                        disabled={selectedCheckbox && selectedCheckbox !== button.id}
                                        // onClick={() => handleCheckboxChange(button.id)}
                                        className={`toggle-button ${selectedCheckbox === button.id ? 'selected' : ''}`}
                                    >
                                        {button.label}
                                    </CButton>
                                </CCol>
                            ))}

                            {/* Description */}
                            <CCol xs={12} md={12} lg={12} className='d-flex justify-content-center'>
                                {selectedCheckbox ? (
                                    <span className='fw-semibold text-white' style={{ fontSize: '15px', backgroundColor: '#3AB857', padding: '5px 15px', borderRadius: '18px' }}>
                                        {
                                            [
                                                { id: 'isBogoCoupon', description: 'Description - BOGO coupon allows you to buy one and get another one free.' },
                                                { id: 'isUniversalCoupon', description: 'Description - Universal coupon can be applied to any item in the store.' },
                                                { id: 'isCartCoupon', description: 'Description - Cart coupon gives a discount on the entire cart.' },
                                                { id: 'isSizeUpgradeCoupon', description: 'Description - Size upgrade coupon lets you upgrade the product size for free or a discount.' },
                                                { id: 'isProductCoupon', description: 'Description - Product coupon provides a discount on a specific product.' },
                                                { id: 'isCategoryCoupon', description: 'Description - Category coupon gives a discount on all items in a particular category.' },
                                                { id: 'isSpecialCategoryCoupon', description: 'Description - Special category coupon applies to selected special categories only.' },
                                                { id: 'isSpecialProductCoupon', description: 'Description - Special product coupon offers discounts on special, featured products.' },
                                                { id: 'isComboCoupon', label: 'COMBO COUPON', description: 'Special product coupon offers discounts on special, featured products.' },
                                            ].find((item) => item.id === selectedCheckbox)?.description
                                        }
                                    </span>
                                ) : <span className='fw-semibold text-muted' style={{ fontSize: '15px', backgroundColor: '#E0FFD9', padding: '5px 15px', borderRadius: '18px' }}>Please Select a Coupon Type From Above</span>}
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Title <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput placeholder="Title" name="title"
                                        value={editData.title || ""}
                                        onChange={handleEditFormInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Coupon Code <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        placeholder="Coupon Code"
                                        name="couponCode"
                                        value={editData.couponCode || ""}
                                        onChange={(e) =>
                                            handleEditFormInputChange({
                                                target: {
                                                    name: e.target.name,
                                                    value: e.target.value.toUpperCase(),
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </CCol>

                            <CCol className='mb-3' md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Validity Start Date
                                    </CFormLabel>
                                    <DatePicker
                                        selected={editData?.validityStartDate ? new Date(editData?.validityStartDate) : null}
                                        onChange={handleEditStartDate}
                                        placeholderText="Start Date"
                                        className="form-control"
                                    />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Validity End Date
                                    </CFormLabel>
                                    <DatePicker
                                        onChange={handleEditEndDate}
                                        placeholderText="Validity Start Date"
                                        className="form-control"
                                        selected={editData?.validityEndDate ? new Date(editData?.validityEndDate) : null}
                                    />
                                </div>
                            </CCol>

                            <CCol md={3}>
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Usage Type <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                        name="usageType"
                                        value={editData.usageType}
                                        onChange={handleEditFormInputChange}
                                    >
                                        <option value="">Select Usage</option>
                                        <option value="ONE-TIME">ONE-TIME</option>
                                        <option value="MULTIPLE">MULTIPLE</option>
                                    </CFormSelect>
                                </div>
                            </CCol>

                            <CCol>
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Select Day of a week
                                    </CFormLabel>
                                    <CFormSelect
                                        size="sm"
                                        onChange={handleEditFormInputChange}
                                        name="dayOfWeek"
                                        className="mb-3"
                                        aria-label="Small select example"
                                        value={editData?.dayOfWeek}
                                    >
                                        <option value="">Select Day</option>
                                        <option value="0">SUNDAY</option>
                                        <option value="1">MONDAY</option>
                                        <option value="2">TUESDAY</option>
                                        <option value="3">WEDNESDAY</option>
                                        <option value="4">THURSDAY</option>
                                        <option value="5">FRIDAY</option>
                                        <option value="6">SATURDAY</option>
                                        <option value="null">ANY DAY OF THE WEEK</option>
                                    </CFormSelect>
                                </div>
                            </CCol>

                            <CCol md="3" lg="3">
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        Start Time
                                    </CFormLabel>
                                    <CFormInput
                                        type="time"
                                        placeholder="Opening Time"
                                        className="form-control"
                                        name="startTime"
                                        value={editData?.startTime}
                                        onChange={handleEditFormInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol md="3" lg="3">
                                <div className="mb-3">
                                    <CFormLabel className="fw-semibold">
                                        End Time
                                    </CFormLabel>
                                    <CFormInput
                                        type="time"
                                        placeholder="Closing Time"
                                        className="form-control"
                                        name="endTime"
                                        value={editData?.endTime}
                                        onChange={handleEditFormInputChange}
                                    />
                                </div>
                            </CCol>

                            <CCol md="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Included Outlets
                                </CFormLabel>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selections?.selectedIncludedOutlets || []}
                                    onChange={(value) => handleChange("selectedIncludedOutlets", value)}
                                    options={outletData.filter(
                                        (outlet) =>
                                            !selections?.selectedExcludedOutlets?.some(
                                                (excludedOutlet) => excludedOutlet.value === outlet.value
                                            )
                                    )}
                                    styles={customStyles}
                                    placeholder="Select Included Outlets"
                                    isDisabled={!!selections?.selectedExcludedOutlets?.length} // Disable if excluded outlets are selected
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>

                            <CCol md="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Excluded Outlets
                                </CFormLabel>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selections.selectedExcludedOutlets || []}
                                    onChange={(value) => handleChange("selectedExcludedOutlets", value)}
                                    options={outletData.filter(
                                        (outlet) =>
                                            !selections.selectedIncludedOutlets?.some(
                                                (includedOutlet) => includedOutlet.value === outlet.value
                                            )
                                    )}
                                    styles={customStyles}
                                    placeholder="Select Excluded Outlets"
                                    isDisabled={!!selections?.selectedIncludedOutlets?.length} // Disable if included outlets are selected
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>

                            {/* Select Applicable Categories */}
                            {
                                (selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === 'isBogoCoupon') &&
                                (<CCol md={6} className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Categories
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        isDisabled={selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isSpecialProductCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isProductCoupon"}
                                        components={animatedComponents}
                                        options={categoriesList}
                                        value={selections.selectedApplicableCategories || []}
                                        onChange={(value) => handleChange("selectedApplicableCategories", value)}
                                        placeholder="Select Applicable Categories"
                                        closeMenuOnSelect={false}
                                        styles={customStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>)
                            }

                            {
                                selectedCheckbox === 'isSizeUpgradeCoupon' && <CCol md="6" className='mb-3'>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Product <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <Select
                                        options={addonList.map((rm) => ({
                                            value: rm?.product?._id,
                                            label: rm?.product?.name,
                                        }))}
                                        value={
                                            selectedProducts
                                                ? addonList
                                                    .map((rm) => ({ value: rm?.product?._id, label: rm?.product?.name }))
                                                    .find((option) => option.value === selectedProducts[0]?.value)
                                                : null
                                        }
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                handleEditInputChange({
                                                    target: { name: "applicableProducts", value: selectedOption.value }
                                                });
                                            } else {
                                                handleEditInputChange({
                                                    target: { name: "applicableProducts", value: "" }
                                                });
                                            }
                                        }}
                                        classNamePrefix="react-select"
                                        isClearable
                                        placeholder="Select Product"
                                    />
                                </CCol>
                            }

                            {
                                (selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === "isProductCoupon") &&
                                (<CCol md={6}>
                                    <CFormLabel className="fw-semibold">
                                        Select Applicable Products {selectedCheckbox === "isSizeUpgradeCoupon" ? (<span className='fw-semibold' style={{ fontSize: '12px', color: 'blueviolet' }}>(Select Only One Product For Size Upgrade Coupon)</span>) : null}
                                    </CFormLabel>
                                    <Select
                                        isMulti
                                        isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isCartCoupon"}
                                        components={animatedComponents}
                                        options={productsInfo.filter(
                                            (product) =>
                                                !selections.selectedExcludedProducts?.some(
                                                    (excludedProduct) => excludedProduct.value === product.value
                                                )
                                        )}

                                        value={selections.selectedApplicableProducts || []}
                                        onChange={(value) => handleChange("selectedApplicableProducts", value)}
                                        placeholder="Select Applicable Products"
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#e9ecef",
                                                primary: "#007bff",
                                            },
                                        })}
                                    />
                                </CCol>)
                            }



                            {/* <CCol md="6" lg="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Addons
                                </CFormLabel>
                                <Select
                                    isMulti
                                    isDisabled={selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isSizeUpgradeCoupon"}
                                    components={animatedComponents}
                                    value={selections.selectedAddons || []}
                                    onChange={(value) => handleChange("selectedAddons", value)}
                                    options={addonsInfo}
                                    styles={customStyles}
                                    placeholder="Select Addons"
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>

                            <CCol md="6" lg="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Addon Values
                                </CFormLabel>
                                <Select
                                    isMulti
                                    isDisabled={selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isSizeUpgradeCoupon"}
                                    components={animatedComponents}
                                    value={selections?.selectedAddonValues || []}
                                    onChange={(value) => handleChange("selectedAddonValues", value)}
                                    options={addonsValueInfo}
                                    styles={customStyles}
                                    placeholder="Select Addons"
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol> */}

                            {
                                (selectedCheckbox === "isSpecialCategoryCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === "isProductCoupon") &&
                                <>
                                    <CCol md="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Select Dependent Categories
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            components={animatedComponents}
                                            value={selections?.selectedDependentCategories || []}
                                            onChange={(value) => handleChange("selectedDependentCategories", value)}
                                            isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox == "isCartCoupon" || selectedCheckbox === "isUniversalCoupon"}
                                            options={categoriesList}
                                            styles={customStyles}
                                            placeholder="Select Dependent Categories"
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>

                                    <CCol md="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Select Dependent Products
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            components={animatedComponents}
                                            value={selections.selectedDependentProducts || []}
                                            onChange={(value) => handleChange("selectedDependentProducts", value)}
                                            isDisabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isSizeUpgradeCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isUniversalCoupon"}
                                            options={productsInfo}
                                            styles={customStyles}
                                            placeholder="Select Dependent Products"
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>
                                </>
                            }

                            {
                                (selectedCheckbox === 'isCategoryCoupon' || selectedCheckbox === 'isSpecialCategoryCoupon') && (
                                    <CCol md="6" lg="6" className='mb-3'>
                                        <CFormLabel className="fw-semibold">
                                            Select Excluded Products
                                        </CFormLabel>
                                        <Select
                                            isMulti
                                            components={animatedComponents}
                                            value={selections?.selectedExcludedProducts || []}
                                            onChange={(value) => handleChange("selectedExcludedProducts", value)}
                                            options={exProductInfo.filter(
                                                (product) =>
                                                    !selections.selectedApplicableProducts?.some(
                                                        (excludedProduct) => excludedProduct.value === product.value
                                                    )
                                            )}
                                            styles={customStyles}
                                            placeholder="Select Excluded Products"
                                            closeMenuOnSelect={false}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#e9ecef",
                                                    primary: "#007bff",
                                                },
                                            })}
                                        />
                                    </CCol>
                                )
                            }

                            <CCol md="6" lg="6" className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Select Applicable Levels
                                </CFormLabel>
                                <Select
                                    isMulti
                                    components={animatedComponents}
                                    value={selections?.selectedApplicableLevels || []}
                                    onChange={(value) => handleChange("selectedApplicableLevels", value)}
                                    options={levelsData}
                                    styles={customStyles}
                                    placeholder="Select Applicable Levels"
                                    closeMenuOnSelect={false}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#e9ecef",
                                            primary: "#007bff",
                                        },
                                    })}
                                />
                            </CCol>
                            {
                                selectedCheckbox === 'isComboCoupon' && (
                                    <>
                                        <CCol md="6" className='mb-3'>
                                            <CFormLabel className="fw-semibold">
                                                Primary Combo Categories
                                            </CFormLabel>
                                            <Select
                                                isMulti
                                                components={animatedComponents}
                                                value={selections?.primaryComboCategories || []}
                                                onChange={(value) => handleChange("primaryComboCategories", value)}
                                                options={categoriesList.filter(
                                                    (category) =>
                                                        !selections.secondaryComboCategories?.some(
                                                            (cat) => cat.value === category.value
                                                        )
                                                )}
                                                styles={customStyles}
                                                placeholder="Primary Combo Categories"
                                                // isDisabled={!!selectedOptions?.length}
                                                closeMenuOnSelect={false}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: "#e9ecef",
                                                        primary: "#007bff",
                                                    },
                                                })}
                                            />
                                        </CCol>

                                        <CCol md="6" className='mb-3'>
                                            <CFormLabel className="fw-semibold">
                                                Secondary Combo Categories
                                            </CFormLabel>
                                            <Select
                                                isMulti
                                                components={animatedComponents}
                                                value={selections.secondaryComboCategories || []}
                                                onChange={(value) => handleChange("secondaryComboCategories", value)}
                                                options={categoriesList.filter(
                                                    (category) =>
                                                        !selections.primaryComboCategories?.some(
                                                            (cat) => cat.value === category.value
                                                        )
                                                )}
                                                styles={customStyles}
                                                placeholder="Secondary Combo Categories"
                                                // isDisabled={!!selectedOptions?.length}
                                                closeMenuOnSelect={false}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: "#e9ecef",
                                                        primary: "#007bff",
                                                    },
                                                })}
                                            />
                                        </CCol>
                                    </>
                                )
                            }

                            {
                                selectedCheckbox === "isSizeUpgradeCoupon" && (<CCol md="3">
                                    <CFormLabel className="fw-semibold">
                                        Select Size Upgrade Addon <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormSelect
                                        name="sizeUpgradeAddon"
                                        disabled={[
                                            "isUniversalCoupon",
                                            "isBogoCoupon",
                                            "isCartCoupon",
                                            "isProductCoupon",
                                            "isCategoryCoupon",
                                            "isSpecialCategoryCoupon",
                                            "isSpecialProductCoupon"
                                        ].includes(selectedCheckbox)}
                                        value={editData.sizeUpgradeAddon._id}
                                        onChange={handleEditFormInputChange}
                                    >
                                        <option value="">Select Size Upgrade Addon</option>
                                        {editFilteredAddon.length > 0 &&
                                            editFilteredAddon[0]?.addons?.map((addon) => (
                                                <option key={addon.addonId} value={addon.addonId}>
                                                    {addon.addonTitle}
                                                </option>
                                            ))}
                                    </CFormSelect>
                                </CCol>

                                )
                            }

                            <CCol md="3">
                                <CFormLabel className="fw-semibold">
                                    Hide Coupon <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isHidden"
                                    value={editData.isHidden}
                                    onChange={handleEditFormInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </CCol>

                            <CCol md="3">
                                <CFormLabel className="fw-semibold">
                                    Is Mrp Coupon? <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormSelect
                                    name="isMrpDiscount"
                                    value={editData.isMrpDiscount}
                                    onChange={handleEditFormInputChange}
                                >
                                    <option value="">Select Option</option>
                                    <option value="true">YES</option>
                                    <option value="false">NO</option>
                                </CFormSelect>
                            </CCol>


                            {
                                selectedCheckbox === 'isComboCoupon' && <CCol md={3}>
                                    <CFormLabel className="fw-semibold">
                                        Max Addons Allowed <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <CFormInput
                                        min={0}
                                        placeholder="Max Addons Allowed"
                                        className="no-arrows"
                                        type="number"
                                        name="maxAddonsAllowed"
                                        value={
                                            editData.maxAddonsAllowed === null || editData.maxAddonsAllowed === undefined
                                                ? undefined
                                                : editData.maxAddonsAllowed
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                handleEditFormInputChange(e);
                                            }
                                        }}
                                    />
                                </CCol>
                            }

                            {
                                (selectedCheckbox === 'isUniversalCoupon' || selectedCheckbox === 'isCartCoupon' || selectedCheckbox === 'isProductCoupon' || selectedCheckbox === 'isCategoryCoupon' || selectedCheckbox === 'isComboCoupon') && (
                                    <>
                                        <CCol md={3} className="mb-3">
                                            <CFormLabel className="fw-semibold">
                                                Discount Percent <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                min={0}
                                                max={100}
                                                placeholder="Discount Percent"
                                                className="no-arrows"
                                                type="number"
                                                name="discountPercent"
                                                value={editData.discountPercent || ""}
                                                onChange={handleEditFormInputChange}
                                            />
                                        </CCol>
                                        <CCol md={3}>
                                            <CFormLabel className="fw-semibold">
                                                Max. Dis. Amount
                                            </CFormLabel>
                                            <CFormInput
                                                min={0}
                                                placeholder="Max. Dis. Amount"
                                                type="number"
                                                className="no-arrows"
                                                name="maxDiscountAmount"
                                                value={editData.maxDiscountAmount || ""}
                                                onChange={handleEditFormInputChange}
                                            />
                                        </CCol>
                                    </>
                                )
                            }

                            {
                                (selectedCheckbox === 'isCartCoupon' || selectedCheckbox === 'isProductCoupon' || selectedCheckbox === 'isCategoryCoupon' || selectedCheckbox === 'isComboCoupon') && (
                                    <CCol md={3}>
                                        <CFormLabel className="fw-semibold">
                                            Discount Amount <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            min={0}
                                            step={0.01}
                                            placeholder="Discount Amount"
                                            className="no-arrows"
                                            type="number"
                                            name="discountAmount"
                                            value={editData.discountAmount || ""}
                                            onChange={handleEditFormInputChange}
                                        />
                                    </CCol>
                                )
                            }

                            <CCol className='mb-3' md={3} lg={3}>
                                <CFormLabel className="fw-semibold">
                                    Min. Order Amount
                                </CFormLabel>
                                <CFormInput
                                    min={0}
                                    // disabled={selectedCheckbox === "isBogoCoupon"}
                                    placeholder="Min. Order Amount"
                                    type="number"
                                    className="no-arrows"
                                    name="minOrderAmount"
                                    value={editData.minOrderAmount}
                                    onChange={handleEditFormInputChange}
                                />
                            </CCol>

                            {
                                (
                                    selectedCheckbox === 'isSpecialCategoryCoupon' || selectedCheckbox === 'isSpecialProductCoupon' || selectedCheckbox === 'isComboCoupon'
                                ) && (
                                    <>
                                        <CCol md={3}>
                                            <CFormLabel className="fw-semibold">
                                                Discount Price <span className="text-danger">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                min={0}
                                                step={0.01}
                                                disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon"}
                                                placeholder="Discount Price"
                                                className="no-arrows"
                                                type="number" s
                                                name="discountPrice"
                                                value={editData.discountPrice !== undefined && editData.discountPrice !== null ? editData.discountPrice : ""}

                                                onChange={handleEditFormInputChange}
                                            />
                                        </CCol>

                                        <CCol md={3} className='mb-3'>
                                            <div>
                                                <CFormLabel className="fw-semibold">
                                                    Applicable Quantity Type <span className="text-danger">*</span>
                                                </CFormLabel>
                                                <CFormSelect
                                                    name="applicableQuantityType"
                                                    value={editData.applicableQuantityType || ""}
                                                    onChange={handleEditFormInputChange}
                                                    disabled={selectedCheckbox === "isBogoCoupon" || selectedCheckbox === "isUniversalCoupon" || selectedCheckbox === "isCartCoupon" || selectedCheckbox === "isProductCoupon" || selectedCheckbox === "isCategoryCoupon" || selectedCheckbox === "isSizeUpgradeCoupon"}
                                                >
                                                    <option value="">Select Applicable Quantity Type</option>
                                                    <option value="SINGLE">SINGLE</option>
                                                    {
                                                        selectedCheckbox !== 'isComboCoupon' ? (<option value="MULTIPLE">MULTIPLE</option>) : null
                                                    }
                                                </CFormSelect>
                                            </div>
                                        </CCol>
                                    </>
                                )
                            }

                            <CCol md={12} className='mb-3'>
                                <CFormLabel className="fw-semibold">
                                    Description <span className="text-danger">*</span>
                                </CFormLabel>
                                <CFormTextarea
                                    rows={2}
                                    placeholder="Description"
                                    name="description"
                                    value={editData.description || ""}
                                    onChange={handleEditFormInputChange}
                                />
                            </CCol>

                            <CCol md="12" className="mb-3">
                                <div>
                                    <CFormLabel className="fw-semibold">
                                        Terms and Conditions <span className="text-danger">*</span>
                                    </CFormLabel>
                                    <div className="d-flex mb-2">
                                        <CFormInput
                                            placeholder="Add a term"
                                            value={currentTerm}
                                            onChange={(e) => setCurrentTerm(e.target.value)}
                                            className="me-2"
                                        />
                                        <CButton
                                            className='btn-success'
                                            disabled={!currentTerm.trim()}
                                            onClick={addTerm}
                                        >ADD</CButton>
                                    </div>
                                    {editData?.termsAndConditions?.length > 0 && (
                                        <ul className="list-group">
                                            {editData?.termsAndConditions?.map((term, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                >
                                                    {term}
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger rounded-pill"
                                                        onClick={() => removeTerm(index)}
                                                    >
                                                        Remove
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </CCol>
                        </CRow>
                    </CModalBody>
                    <CModalFooter>
                        <PrimaryButton style={{ width: '20%' }} title={"SAVE CHANGES"}
                            isLoading={isEditBtnLoading}
                        />
                    </CModalFooter>
                </CForm>
            </CModal>
        </CContainer>
    )
}

export default ViewCoupon
