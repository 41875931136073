import { cilCloudDownload, cilPrint, cilSearch, cilSpreadsheet } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useState, useEffect } from 'react'
import ReactDatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import { Spinner } from 'react-bootstrap';

const CouponsReport = () => {

    const accessToken = localStorage.getItem('authToken');
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [outlets, setOutlets] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [error, setError] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    
    const getOutlets = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("outlet", { headers });
    
          if (response?.data?.status === true) {
            console.log("outlets :", response.data.data);
            const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
            setOutlets(activeOutlets);
          }
        } catch (error) {
          if (error?.response) {
            let errorMessage = "";
            if (error.response?.status === 406) {
              errorMessage =
                error?.response?.data?.error?.message ||
                error?.response?.data?.error;
            } else if (error.response?.status === 412) {
              errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
              errorMessage = error?.response?.data?.error;
            }
    
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const getCouponsData = async (start, end, outlet, search, download) => {
      // if (download === false) {
      //     setLoading2(true);
      //     setBtnLoading(true);
      // }
  
      try {
          const headers = {
              Authorization: `Bearer ${accessToken}`,
              "content-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          };
  
          const updatedStartDate = start ? start.toISOString().split("T")[0] : null;
          const updatedEndDate = end ? end.toISOString().split("T")[0] : null;
  
          let apiUrl = `coupons/report/getAllCouponUsageReport?startDate=${updatedStartDate}&endDate=${updatedEndDate}`;
  
          // Append optional query parameters dynamically
          const queryParams = {
              outlet,
              search,
              isDownload: download
          };
  
          Object.entries(queryParams).forEach(([key, value]) => {
              if (value !== undefined && value !== null && value !== '') {
                  apiUrl += `&${key}=${value}`;
              }
          });
  
          // Set responseType 'blob' for file download
          const response = await axios.get(apiUrl, { headers, responseType: download ? 'blob' : 'json' });
  
          if (download) {
              const blob = new Blob([response.data], { 
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
              });
  
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'Coupon_Usage_Report.xlsx'); // File name
              document.body.appendChild(link);
              link.click();
  
              // Clean up
              link.remove();
              window.URL.revokeObjectURL(url);
  
              toast.success('Excel file downloaded successfully!', {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored"
              });
          } else {
              if (response?.data?.status === true) {
                  setFilteredData(response?.data?.data?.dbResponse);
                  toast.success(response?.data?.message, {
                      position: "top-right",
                      autoClose: 3000,
                      theme: "colored"
                  });
              }
          }
      } catch (error) {
          console.error("Error in coupon data fetch:", error);
  
          if (error.response && error.response?.data?.status === false) {
              let errorMessage = error.response.data.error;
              toast.error(errorMessage, {
                  position: "top-right",
                  autoClose: 3000,
                  theme: "colored",
              });
          }
      } finally {
          // setBtnLoading(false);
          // setLoading2(false);
          setDownloadBtnLoading(false);
      }
    };  

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
          setError('End date cannot be earlier than start date');
        } else {
          setError('');
          setEndDate(date);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtnLoading(true);
    
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // end of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
    
        await getCouponsData(adjustedStartDate, adjustedEndDate, selectedOutlet, searchText);
        
        setSubmitBtnLoading(false);
    };

    const handleDownloadExcel = async () => {
      setDownloadBtnLoading(true);
      const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
      const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
      getCouponsData(adjustedStartDate, adjustedEndDate, selectedOutlet, searchText, true);
    };

    const handlePrintReport = () => {
        const tableContent = `
          <table style="border-collapse: collapse; width: 100%;">
            <thead>
              <tr>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Coupon Code</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Coupon Title</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Usage Count</th>
                <th style="border: 1px solid black; padding: 8px; text-align: left;">Sales Contribution</th>
                <th style="border: 1px solid black; padding: 8px; text-align: center;">Discount Given</th>
                <th style="border: 1px solid black; padding: 8px; text-align: center;">Avg. Discount</th>
              </tr>
            </thead>
            <tbody>
              ${filteredData?.length > 0 ? 
                    filteredData?.map((coupon, index) => `
                        <tr>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px">${index + 1}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px;">${coupon?.couponCode}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px;">${coupon?.title}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: center">${coupon?.usageCount}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: end">₹. ${coupon?.salesContribution?.toFixed(2)}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: end">₹. ${coupon?.discountGiven?.toFixed(2)}</td>
                            <td style="border: 1px solid black; padding: 8px; font-size: 13px; text-align: end">₹. ${coupon?.avgDiscount?.toFixed(2)}</td>
                        </tr>
                    `).join("") :
                `<tr>
                  <td colspan="7" style="border: 1px solid black; padding: 8px; text-align: center;">No Coupons Found</td>
                </tr>`
              }
            </tbody>
          </table>
        `;
    
        const win = window.open("", "", "width=900,height=600");
        win.document.write(`
          <html>
            <head>
              <title>Coupons Report</title>
              <style>
                table { border-collapse: collapse; width: 100%; }
                th, td { border: 1px solid black; padding: 8px; text-align: left; }
                th { background-color: #f2f2f2; }
              </style>
            </head>
            <body onload="window.print();window.close()">
              <h2>Coupons Report</h2>
              <div>${tableContent}</div>
            </body>
          </html>
        `);
        win.document.close();
    };


    useEffect(() => {
        getOutlets();
    }, []);

    useEffect(() => {
      if(searchText === "" && isTyping) {
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
        getCouponsData(adjustedStartDate, adjustedEndDate, selectedOutlet, searchText)
      }
    }, [searchText]);

    useEffect(() => {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
    
        const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
        const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);
        // getProductData(start, end, selectedOutlet, selectedCategory);    
    }, []);

    return (
        <div className="container">
            <h5>COUPONS REPORTS</h5>
            <CRow className="mt-3">
                <CCol md="2" lg="2">
                    <CFormLabel className="fw-semibold">Select Outlet <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                    >
                        <option value="">All Outlets</option>
        
                        {outlets.map((outName, index) => {
                            return (
                                <option key={index} value={outName?._id}>
                                {outName?.name}
                                </option>
                            );
                        })}
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Start Date <span className="text-danger">*</span></CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select Start Date"
                            className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>End Date <span className="text-danger">*</span></CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={endDate}
                            // onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={handleEndDateChange}
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                        {/* { error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div> } */}
                    </div>
                </CCol>
                <CCol md="2" lg="2">
                    <div className='d-fex' style={{ flexDirection: 'column' }}>
                        <CFormLabel color="primary" className="text-white" >.</CFormLabel>
                        <CButton className='w-100' onClick={handleSubmit} disabled={submitBtnLoading}>
                            { submitBtnLoading ? <CSpinner size='sm' /> : "Submit" }
                        </CButton>
                    </div>
                </CCol>
                <CCol md="4" lg="4">
                    <CFormLabel className='fw-semibold'>Search</CFormLabel>
                    <div className="position-relative">
                        <CFormInput
                            type="text"
                            placeholder="Search By Coupon Code"
                            onChange={(e) => 
                              (
                                setIsTyping(true),
                                setSearchText(e.target.value)
                              )
                            }
                        />
                        <CButton
                            className="position-absolute end-0 top-0 bottom-0 d-flex align-items-center px-3 bg-primary "
                            onClick={handleSubmit} 
                            disabled={submitBtnLoading}
                            style={{ borderLeft: "1px solid #ccc", background: "transparent", borderRadius: "0 4px 4px 0" }}
                        >
                            { submitBtnLoading ? <CSpinner size='sm' /> : <><CIcon icon={cilSearch} size="lg" /> <i className='ms-2'>Search</i></> }
                        </CButton>
                    </div>
                </CCol>
            </CRow>

            <CRow className="mt-1">
                { filteredData?.length === 0 ? (
                    <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "300px" }}
                    >
                    <h5 className="text-danger">
                        Please Select An Outlet And Click On Submit To View Coupons Data
                    </h5>
                    </div>
                ) : (

                    <CCol>
                        <hr />
                        <CRow className='mt-3 align-items-center mb-2'>
                            <CCol md="2" lg="2" className="text-center mb-2">
                                <CButton className="w-100 bg-success border-success" onClick={handlePrintReport}>
                                  <CIcon icon={cilPrint} size="sm" /> Print Report
                                </CButton>
                            </CCol>
                            <CCol md="2" lg="2" className="text-center mb-2">
                                <CButton className="w-100 bg-info border-info fw-semibold" onClick={handleDownloadExcel}>
                                  { downloadBtnLoading ? <Spinner size='sm' /> : <><CIcon icon={cilCloudDownload}  className='me-1 fw-bold' size="md" />Download Excel</>} </CButton>
                            </CCol>
                        </CRow>

                        <CTable striped bordered hover responsive>
                            <CTableHead>
                                <CTableRow style={{ fontSize: '15px' }}>
                                <CTableHeaderCell>#</CTableHeaderCell>
                                <CTableHeaderCell>COUPON CODE</CTableHeaderCell>
                                <CTableHeaderCell>COUPON TITLE</CTableHeaderCell>
                                <CTableHeaderCell>USAGE COUNT</CTableHeaderCell>
                                <CTableHeaderCell>SALES CONTRIBUTION</CTableHeaderCell>
                                <CTableHeaderCell>DISCOUNT GIVEN</CTableHeaderCell>
                                <CTableHeaderCell>AVG. DISCOUNT</CTableHeaderCell>
                                </CTableRow>
                            </CTableHead>
                            <CTableBody>
                                {loading ? (
                                    <CTableRow>
                                        <CTableDataCell colSpan="7" className="text-center">
                                        <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                        </CTableDataCell>
                                    </CTableRow>
                                ) :
                                filteredData?.length > 0 ? (
                                <>
                                    {filteredData?.map((coupon, index) => (
                                    <CTableRow key={index} style={{ fontSize: '15px' }}>
                                        <CTableDataCell>{index + 1}</CTableDataCell>
                                        <CTableDataCell className='fw-semibold' style={{ color: 'purple' }}>{coupon?.couponCode}</CTableDataCell>
                                        <CTableDataCell>{coupon?.title}</CTableDataCell>
                                        <CTableDataCell className="text-center">{coupon?.usageCount}</CTableDataCell>
                                        <CTableDataCell className="text-end">₹. {coupon?.salesContribution?.toFixed(2)}</CTableDataCell>
                                        <CTableDataCell className="text-end">₹. {coupon?.discountGiven?.toFixed(2)}</CTableDataCell>
                                        <CTableDataCell className="text-end">₹. {coupon?.avgDiscount?.toFixed(2)}</CTableDataCell>                                        
                                    </CTableRow>
                                    ))}
                                </>
                                ) : (
                                <CTableRow>
                                    <CTableDataCell colSpan="7" className="text-center text-danger fw-5">
                                        No Coupons Found
                                    </CTableDataCell>
                                </CTableRow>
                                )}
                            </CTableBody>
                        </CTable>

                    </CCol>
                )  }
            </CRow>
        </div>
    )
}

export default CouponsReport