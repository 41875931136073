import React from 'react'
import { CCol, CFormLabel } from '@coreui/react';
import Select from "react-select";
import makeAnimated from "react-select/animated";

const MultiSelectComponent = ({
    label,
    options,
    value,
    onChange,
    placeholder ,
    isMulti = true,
    isDisabled = false,
    customStyles = {},
}) => {
    const animatedComponents = makeAnimated();
    const defaultStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: "4px",
            borderColor: "#ced4da",
        }),
    };
    return (
        <CCol md={6} className='mb-3'>
            <CFormLabel className="fw-semibold">{label}</CFormLabel>
            <Select
                isMulti={isMulti}
                isDisabled={isDisabled}
                components={animatedComponents}
                options={options}
                value={value || []}
                onChange={onChange}
                placeholder={placeholder}
                styles={{ ...defaultStyles, ...customStyles }}
                closeMenuOnSelect={false}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: "#e9ecef",
                        primary: "#007bff",
                    },
                })}
            />
        </CCol>
    )
}

export default MultiSelectComponent
