import { CBadge, CButton, CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalHeader, CModalTitle, CPagination, CPaginationItem, CRow, CSpinner, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import ReactDatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import axios from '../../api/axios';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import CIcon from '@coreui/icons-react';
import { cilSearch } from '@coreui/icons';
import { Spinner } from 'react-bootstrap';

const UserAnalyticsReport = () => {

    const accessToken = localStorage.getItem("authToken");
    const animatedComponents = makeAnimated();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedOutlet, setSelectedOutlet] = useState('');
    const [outlets, setOutlets] = useState([]);
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [error, setError] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('');
    const [productId, setProductId] = useState('');
    const [productsList, setProductsList] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState(productsList);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState('25');
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedTaste, setSelectedTaste] = useState('');
    const [totalPages, setTotalPages] = useState('');
    const [clicked, setClicked] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [totalRecords, setTotalRecords] = useState('');
    const [referredUsers, setReferredUsers] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [singleUserDetails, setSingleUserDetails] = useState({});
    const [isTouched, setIsTouched] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [downloadBtnLoading, setDownloadBtnLoading] = useState(false);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "100%",
            borderRadius: "6px",
            // minWidth: "200px",
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
            "&:hover": {
                borderColor: state.isFocused ? "#80bdff" : "#ced4da",
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6c757d",
        }),
    };

    const handlePageChange = (page) => {
               
        if (page > 0 && page <= totalPages) {
          setCurrentPage(page);
        }

        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day

        getUserAnalyticsReport(adjustedStartDate, adjustedEndDate, page, recordsPerPage, minPrice, maxPrice, selectedFilter, selectedTaste, selectedOutlet, selectedCategory, productId, searchText, false);
    };

    const getOutlets = async () => {
        try {
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const response = await axios.get("outlet", { headers });
    
          if (response?.data?.status === true) {
            console.log("outlets :", response.data.data);
            const activeOutlets = response?.data?.data?.outlets.filter((active) => active?.status === "ACTIVE");
            setOutlets(activeOutlets);
          }
        } catch (error) {
          if (error?.response) {
            let errorMessage = "";
            if (error.response?.status === 406) {
              errorMessage =
                error?.response?.data?.error?.message ||
                error?.response?.data?.error;
            } else if (error.response?.status === 412) {
              errorMessage = error?.response?.data?.data?.message;
            } else if (error.response?.status === 500) {
              errorMessage = error?.response?.data?.error;
            }
    
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        }
    };

    const getCategoryList = async () => {
            try {
                const headers = {
                Authorization: `Bearer ${accessToken}`,
                };
                const response = await axios.get("masters/category/get", { headers });
        
                if (response?.data?.status === true) {
                setCategoryList(response?.data?.data?.categories);
                }
            } catch (error) {
                if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                }
            }
    };
    
    const getProductsList = async () => {
        try {
            const headers = {
            Authorization: `Bearer ${accessToken}`,
            };
            const response = await axios.get("product", { headers });
            if (response?.data?.status === true) {
                setProductsList(response?.data?.data?.products);
                setFilteredProducts(response?.data?.data?.products)
            }
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
            let errorMessage = error.response.data.error;
            toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    };

    // const getUserAnalyticsReport = async (sDate, eDate, page, limit, min, max, filter, taste, outlet, category, product, search, download) => {
    //     setLoading2(true);
    //     setBtnLoading(true);
    //     try {
    //         const headers = {
    //             Authorization: `Bearer ${accessToken}`,
    //             "content-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    //         }
    //         const updatedStartDate = sDate ? sDate.toISOString().split("T")[0] : null;
    //         const updatedEndDate = eDate ? eDate.toISOString().split("T")[0] : null;

    //         let apiUrl = `user/user-analytics?page=${page}&limit=${limit}&startDate=${updatedStartDate}&endDate=${updatedEndDate}`;
    //         if (min) {
    //             apiUrl += `&minPrice=${min}`
    //         }

    //         if (max) {
    //             apiUrl += `&maxPrice=${max}`
    //         }

    //         if (filter) {
    //             apiUrl += `&filter=${filter}`
    //         }

    //         if (taste) {
    //             apiUrl += `&foodType=${taste}`
    //         }

    //         if (outlet) {
    //             apiUrl += `&outlet=${outlet}`
    //         }

    //         if (category) {
    //             apiUrl += `&category=${category}`
    //         }

    //         if (product) {
    //             apiUrl += `&product=${product}`
    //         }

    //         if (search) {
    //             apiUrl += `&search=${search}`
    //         }

    //         if (download) {
    //             apiUrl += `&isDownload=${download}`
    //         }

    //         const response = await axios.get(apiUrl, {headers});
    //         console.log("Response of user analytics :", response);
    //         if (download === false) {
    //             setFilteredData(response?.data?.data?.userAnalytics);
    //             setTotalRecords(response?.data?.data?.pagination?.totalRecords);
    //             setTotalPages(response?.data?.data?.pagination?.totalPages);
    //             let message = response?.data?.data?.message;
    //             toast.success(message, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 theme: "colored"
    //             })
    //         } else {
    //             let message = response?.data?.data?.message;
    //             toast.success(message, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 theme: "colored"
    //             })
    //         }            

    //     } catch (error) {
    //         console.log("Error in user analytis :", error);
            
    //         if (error.response && error.response?.data?.status === false) {
    //             let errorMessage = error.response.data.error;
    //             toast.error(errorMessage, {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 theme: "colored",
    //             });
    //         }
    //     } finally {
    //         setBtnLoading(false);
    //         setLoading2(false);
    //         setDownloadBtnLoading(false);
    //     }
    // };

    const getUserAnalyticsReport = async (sDate, eDate, page, limit, min, max, filter, taste, outlet, category, product, search, download ) => {
        if (download === false) {
            setLoading2(true);
            setBtnLoading(true);          
        }
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                "content-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            };
    
            const updatedStartDate = sDate ? sDate.toISOString().split("T")[0] : null;
            const updatedEndDate = eDate ? eDate.toISOString().split("T")[0] : null;
    
            let apiUrl = `user/user-analytics?page=${page}&limit=${limit}&startDate=${updatedStartDate}&endDate=${updatedEndDate}`;
            
            // Append optional query parameters if they exist
            const queryParams = {
                minPrice: min,
                maxPrice: max,
                filter,
                foodType: taste,
                outlet,
                category,
                product,
                search,
                isDownload: download
            };
    
            Object.entries(queryParams).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== '') {
                    apiUrl += `&${key}=${value}`;
                }
            });
    
            // Set responseType 'blob' for file download
            const response = await axios.get(apiUrl, { headers, responseType: download ? 'blob' : 'json' });
            
            if (download) {
                const blob = new Blob([response.data], { 
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                });
    
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'User_Analytics_Report.xlsx'); // File name
                document.body.appendChild(link);
                link.click();
    
                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);
    
                toast.success('Excel file downloaded successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored"
                });
            } else {    
                setFilteredData(response?.data?.data?.userAnalytics);
                setTotalRecords(response?.data?.data?.pagination?.totalRecords);
                setTotalPages(response?.data?.data?.pagination?.totalPages);
    
                let message = response?.data?.data?.message;
                toast.success(message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored"
                });
            }
    
        } catch (error) {
            console.error("Error in user analytics:", error);
    
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setBtnLoading(false);
            setLoading2(false);
            setDownloadBtnLoading(false);
        }
    };

    const categoryOptions = categoryList.map((category) => ({
        value: category._id,
        label: category.name,
    }));

    const handleEndDateChange = (date) => {
        if (date && startDate && date < startDate) {
          setError('End date cannot be earlier than start date');
        } else {
          setError('');
          setEndDate(date);
        }
    };

    const handleSubmit = async () => {
        // setBtnLoading(true);
        setSearchBtnLoading(true);
        console.log("Hello");
        setClicked(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
        setCurrentPage(1);
        getUserAnalyticsReport(adjustedStartDate, adjustedEndDate, 1, recordsPerPage, minPrice, maxPrice, selectedFilter, selectedTaste, selectedOutlet, selectedCategory, productId, searchText, false);
        setSearchBtnLoading(false);
        // setBtnLoading(false);
    }

    const handleDownloadExcel = async () => {
        setDownloadBtnLoading(true);
        const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
        const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
        getUserAnalyticsReport(adjustedStartDate, adjustedEndDate, 1, recordsPerPage, minPrice, maxPrice, selectedFilter, selectedTaste, selectedOutlet, selectedCategory, productId, searchText, true);
    }

    const handleOpenViewModal = (data) => {
        console.log("Single Data :", data);
        
        setOpenViewModal(true);
        setReferredUsers(data?.referredUsers);
        setSingleUserDetails(data);
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    };

    useEffect(() => {
        getOutlets();
        getCategoryList();
        getProductsList();
    }, []);

    useEffect(() => {
        const now = new Date();
        const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
    
        const start = new Date(firstOfMonth.setHours(23, 59, 59, 999)); // start of day
        const end = new Date(currentDate.setHours(23, 59, 59, 999)); // end of day
        // Set the start date and end date
        setStartDate(start);
        setEndDate(end);

        
    }, []);

    useEffect(() => {
        if (searchText === "" && isTyping === true) {
            const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
            const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
            getUserAnalyticsReport(adjustedStartDate, adjustedEndDate, currentPage, recordsPerPage, minPrice, maxPrice, selectedFilter, selectedTaste, selectedOutlet, selectedCategory, productId, searchText);
        }
    }, [])

    return (
        <div className="container">
            <h5>USER ANALYTICS REPORT</h5>
            <CRow className="mt-3">
                <CCol md="2" lg="2">
                    <CFormLabel className="fw-semibold">Select Outlet</CFormLabel>
                    <CFormSelect
                        id="selectField"
                        name="selectField"
                        value={selectedOutlet}
                        onChange={(e) => setSelectedOutlet(e.target.value)}
                    >
                    <option value="">All Outlets</option>

                    {outlets.map((outName, index) => {
                        return (
                            <option key={index} value={outName?._id}>
                            {outName?.name}
                            </option>
                        );
                    })}
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Start Date <span className="text-danger">*</span></CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={(date) => setStartDate(date)}
                            placeholderText="Select Start Date"
                            className="form-control"
                        />
                    </div>
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>End Date <span className="text-danger">*</span></CFormLabel>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <ReactDatePicker
                            selected={endDate}
                            // onChange={(date) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"  // Set the date format here
                            onChange={handleEndDateChange}
                            placeholderText="Select End Date"
                            className="form-control"
                        />
                        {/* { error && <div className="text-danger" style={{ fontSize: '14px' }}>{error}</div> } */}
                    </div>
                </CCol>
                {/* Min Price Input */}
                <CCol md={2}>
                    <CFormLabel className="fw-semibold">
                        Min Price
                    </CFormLabel>
                    <CFormInput
                        type="number"
                        min={0}
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                        placeholder="Enter Min Price"
                        className="form-control"
                    />
                    { isTouched && maxPrice < minPrice ? 
                        <span className='text-danger' style={{ fontSize: '11px' }}>Min price must be less than Max price</span>
                    : null}
                </CCol>

                {/* Max Price Input */}
                <CCol md={2}>
                    <CFormLabel className="fw-semibold">
                        Max Price
                    </CFormLabel>
                    <CFormInput
                        type="number"
                        min={0}
                        value={maxPrice}
                        onChange={(e) => {
                            setMaxPrice(e.target.value);
                            setIsTouched(true);
                            if (e.target.value === "") {
                                setIsTouched(false);
                            }
                        }}
                        placeholder="Enter Max Price"
                        className="form-control"
                    />
                </CCol>
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Select Type of Users</CFormLabel>
                    <CFormSelect id='selectField' value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)}>
                        <option value="">Select</option>
                        <option value="JOINING_COUPON_ONE_ORDER">Users Used Joining Coupon (only 1 ordered)</option>
                        <option value="COUPON_ORDERS">Users Using Coupons</option>
                        <option value="NO_COUPON_ORDERS">Users Not Using Coupons</option>
                        <option value="REFERRING_USERS">Refering Users</option> 
                        <option value="REFERRED_USERS">Referred Users (Joined By Referral)</option>
                        <option value="REPEATED_USERS_DAYS">Repeated Users (By Days)</option>
                    </CFormSelect>
                </CCol>
            </CRow>

            <CRow className='mt-2 mb-4'>
                <CCol md={2} sm={2}>
                    <CFormLabel className='fw-semibold'>Select Category</CFormLabel>
                    <Select
                        id="selectField"
                        options={categoryOptions}
                        value={categoryOptions?.find(option => option?.value === selectedCategory)}
                        onChange={(selectedOption) => {
                            const selectCategory = selectedOption?.value;
                            console.log("selectCategory List :", selectCategory);
                            setSelectedCategory(selectCategory)
                            // setCategoryId(selectCategory);
                            if (selectCategory === undefined) {
                              setFilteredProducts(productsList);
                            } else {
                              setFilteredProducts(
                                productsList.filter((product) => product?.category?._id === selectCategory)
                              );
                            }
                        }}
                        isClearable
                        styles={customStyles}
                        placeholder="Category" // You can also use a dynamic placeholder from props or state
                        // closeMenuOnSelect={false}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: "#e9ecef",
                                primary: "#007bff",
                            },
                        })}
                    />
                </CCol>
                <CCol md={2}>
                    <CFormLabel className="fw-semibold">Select Product</CFormLabel>
                    <Select
                        options={filteredProducts?.map((rm) => ({
                            value: rm?._id,
                            label: rm?.name,
                        }))}
                        value={
                            productId
                                ? { value: productId, label: filteredProducts?.find((rm) => rm._id === productId)?.name }
                                : null
                        }
                        onChange={(selectedOption) => {
                            setProductId(selectedOption ? selectedOption?.value : '');
                        }}
                        classNamePrefix="react-select"
                        isClearable
                        styles={customStyles}
                        placeholder="Product"
                    />
                </CCol>
                
                <CCol md={2}>
                    <CFormLabel className='fw-semibold'>Preferred Taste</CFormLabel>
                    <CFormSelect id='selectField' value={selectedTaste} onChange={(e) => setSelectedTaste(e.target.value)}>
                        <option value="">Select</option>
                        <option value="FOOD">FOOD</option>
                        <option value="BEVERAGE">BEVERAGE</option> 
                        <option value="BOTH">BOTH</option>
                    </CFormSelect>
                </CCol>
                <CCol md={2}>
                    <CRow><CFormLabel className='text-white'>.</CFormLabel></CRow>
                    <CButton color="primary" className="w-100" onClick={handleSubmit} >
                        {btnLoading ? <CSpinner size="sm" /> : 'Submit'} 
                        {/* Submit */}
                    </CButton>
                </CCol>

                <CCol md="4" lg="4">
                    <CRow><CFormLabel className='fw-semibold'>Search</CFormLabel></CRow>
                    <div className="position-relative">
                        <CFormInput
                            type="text"
                            placeholder="Search By Name or Mobile"
                            onChange={(e) => {
                                setSearchText(e.target.value)
                                setIsTyping(true)
                            }}
                        />
                        <CButton 
                            className="position-absolute end-0 top-0 bottom-0 d-flex align-items-center px-3 bg-primary"
                            onClick={handleSubmit} 
                            disabled={searchBtnLoading}
                            style={{ borderLeft: "1px solid #ccc", background: "transparent", borderRadius: "0 4px 4px 0" }}
                        >
                            { searchBtnLoading ? <CSpinner size='sm' /> : <><CIcon icon={cilSearch} size="lg" /> <i className='ms-2'>Search</i></> }
                        </CButton>
                    </div>
                </CCol>
            </CRow>

            <hr />

            <CRow>
                <CCol>
                { clicked === false ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <h5 className="text-danger">Please Select Necessary Filters And Click Submit</h5>
                    </div>
                    ) : (
                        <>
                            <CPagination className="justify-content-between align-items-center" style={{ cursor: 'pointer' }}>
                                <div className='d-flex w-25'>
                                    <CButton color='success' className='w-75' onClick={handleDownloadExcel}>
                                        { downloadBtnLoading ? <Spinner size='sm' /> : "Download Excel" }
                                    </CButton>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                        <CFormSelect
                                            className="ml-3"
                                            style={{ width: 'auto' }}
                                            value={recordsPerPage}
                                            onChange={(e) => {
                                                    const limit = e.target.value;
                                                    const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
                                                    const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
                                                    setRecordsPerPage(e.target.value);
                                                    getUserAnalyticsReport(adjustedStartDate, adjustedEndDate, currentPage, limit, minPrice, maxPrice, selectedFilter, selectedTaste, selectedOutlet, selectedCategory, productId, searchText, false);
                                                }
                                            }
                                        >
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={200}>200</option>
                                            <option value={500}>500</option>
                                            <option value={1000}>1000</option>
                                        </CFormSelect>
                                    </div>
                                    <CPaginationItem
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Previous
                                    </CPaginationItem>
                                    {totalPages > 15 ? (
                                    <>
                                        {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                        {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}
                
                                        {Array.from({ length: 5 }, (_, i) => {
                                        const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;
                                        if (pageNumber <= totalPages) {
                                            return (
                                            <CPaginationItem
                                                key={pageNumber}
                                                active={pageNumber === currentPage}
                                                onClick={() => handlePageChange(pageNumber)}
                                            >
                                                {pageNumber}
                                            </CPaginationItem>
                                            );
                                        }
                                        return null;
                                        })}
                
                                        {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                        {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                    </>
                                    ) : (
                                    Array.from({ length: totalPages }, (_, i) => (
                                        <CPaginationItem
                                        key={i + 1}
                                        active={i + 1 === currentPage}
                                        onClick={() => handlePageChange(i + 1)}
                                        >
                                        {i + 1}
                                        </CPaginationItem>
                                    ))
                                    )}
                                    <CPaginationItem
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                    Next
                                    </CPaginationItem>
                                </div>
                            </CPagination>

                            <CTable striped bordered hover responsive>
                                <CTableHead>
                                    <CTableRow style={{ fontSize: '15px' }}>
                                        <CTableHeaderCell className='fw-semibold'>Sr.no</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>User Details</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Outlet</CTableHeaderCell>
                                        <CTableHeaderCell>
                                            <span className='fw-semibold'>Total Visits</span> <br />
                                            <span className='text-danger' style={{ fontSize: '10px', fontWeight: '500' }}>(Visit Count Is Based On Days)</span>
                                        </CTableHeaderCell>
                                        <CTableHeaderCell>
                                            <span className='fw-semibold'>Total Spendings</span> <br />
                                            <span className='text-danger' style={{ fontSize: '10px', fontWeight: '500' }}>(Average)</span>
                                        </CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Total Spent</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Total Food Count</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Total Bevs Count</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Total Order Count</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Referral Count</CTableHeaderCell>
                                        <CTableHeaderCell className='fw-semibold'>Actions</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    {loading2 ? (
                                        <CTableRow>
                                            <CTableDataCell colSpan="12" className="text-center">
                                                <CSpinner color="primary" size="lg" /> {/* Display spinner */}
                                                {/* <p>Loading Data...</p> */}
                                            </CTableDataCell>
                                        </CTableRow>
                                    ) : (
                                        filteredData?.length > 0 ? (
                                            <>
                                                {filteredData?.map((user, index) => (
                                                    <CTableRow key={user?._id} style={{ fontSize: '15px' }}>
                                                        <CTableDataCell>{(currentPage - 1) * recordsPerPage + index + 1}</CTableDataCell>
                                                        <CTableDataCell>
                                                            <span className='fw-semibold' style={{ color: 'purple' }}>{user?.name}</span> <br />
                                                            <span className='text-primary'>{user?.email}</span> <br />
                                                            <span className='text-primary'>{user?.mobile}</span> <br />
                                                            <span className='text-muted'>{new Date(user?.createdAt).toLocaleString("en-IN", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                hour12: true,
                                                            })}</span>
                                                        </CTableDataCell>
                                                        <CTableDataCell>{user?.orderOutlet ? user?.orderOutlet?.name : "-------"}</CTableDataCell>
                                                        <CTableDataCell className='text-center'>{user?.orderDates?.length}</CTableDataCell>
                                                        <CTableDataCell className="text-end">{user?.orderStatistics?.averageGrandTotal ? `₹ ${user?.orderStatistics?.averageGrandTotal?.toFixed(2)}` : '-------'}</CTableDataCell>
                                                        <CTableDataCell className="text-end">{user?.orderStatistics?.totalGrandTotal ? `₹ ${user?.orderStatistics?.totalGrandTotal?.toFixed(2)}` : "------"}</CTableDataCell>
                                                        <CTableDataCell className="text-center">{user?.orderStatistics?.totalFoodCount ? `${user?.orderStatistics?.totalFoodCount}` : "0"}</CTableDataCell>
                                                        <CTableDataCell className="text-center">{user?.orderStatistics?.totalBeverageCount ? `${user?.orderStatistics?.totalBeverageCount}` : "0"}</CTableDataCell>
                                                        <CTableDataCell className="text-center">{user?.orderStatistics?.totalOrderCount ? `${user?.orderStatistics?.totalOrderCount}` : "0"}</CTableDataCell>
                                                        <CTableDataCell className="text-center">{user?.referredUsers?.length > 0 ? `${user?.referredUsers?.length}` : "0"}</CTableDataCell>
                                                        <CTableDataCell className="text-center">
                                                            <CButton size='sm' color='success' onClick={() => handleOpenViewModal(user)}>Check Referred Users</CButton>
                                                        </CTableDataCell>
                                                    </CTableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <CTableRow>
                                                <CTableDataCell colSpan="11" className="text-center text-danger fw-5">
                                                    No Data Found
                                                </CTableDataCell>
                                            </CTableRow>
                                        )
                                    )}
                                </CTableBody>
                            </CTable>

                            <CPagination className="justify-content-end" style={{ cursor: 'pointer' }}>
                                <div className='d-flex me-3' style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <CFormLabel className='fw-semibold me-2'>Records Per Page :- </CFormLabel>
                                    <CFormSelect
                                        className="ml-3"
                                        style={{ width: 'auto' }}
                                        value={recordsPerPage}
                                        onChange={(e) => {
                                            const limit = e.target.value;
                                            const adjustedStartDate = new Date(startDate.setHours(23, 59, 59, 999)); // start of day
                                            const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999)); // end of day
                                            setRecordsPerPage(e.target.value);
                                            getUserAnalyticsReport(adjustedStartDate, adjustedEndDate, currentPage, limit, minPrice, maxPrice, selectedFilter, selectedTaste, selectedOutlet, selectedCategory, productId, searchText, false);
                                        }
                                    }
                                    >
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={200}>200</option>
                                        <option value={500}>500</option>
                                        <option value={1000}>1000</option>
                                    </CFormSelect>
                                </div>
                                <CPaginationItem
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Previous
                                </CPaginationItem>
                                {totalPages > 15 ? (
                                    <>
                                    {currentPage > 3 && <CPaginationItem onClick={() => handlePageChange(1)}>1</CPaginationItem>}
                                    {currentPage > 4 && <CPaginationItem disabled>...</CPaginationItem>}
                
                                    {Array.from({ length: 5 }, (_, i) => {
                                        const pageNumber = currentPage > 3 ? currentPage - 2 + i : i + 1;
                                        if (pageNumber <= totalPages) {
                                        return (
                                            <CPaginationItem
                                            key={pageNumber}
                                            active={pageNumber === currentPage}
                                            onClick={() => handlePageChange(pageNumber)}
                                            >
                                            {pageNumber}
                                            </CPaginationItem>
                                        );
                                        }
                                        return null;
                                    })}
                
                                    {currentPage < totalPages - 3 && <CPaginationItem disabled>...</CPaginationItem>}
                                    {currentPage < totalPages - 2 && <CPaginationItem onClick={() => handlePageChange(totalPages)}>{totalPages}</CPaginationItem>}
                                    </>
                                ) : (
                                    Array.from({ length: totalPages }, (_, i) => (
                                    <CPaginationItem
                                        key={i + 1}
                                        active={i + 1 === currentPage}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </CPaginationItem>
                                    ))
                                )}
                                <CPaginationItem
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </CPaginationItem>
                            </CPagination>
                        </>
                    ) }

                </CCol>
            </CRow>

            {openViewModal ? (
                <CModal alignment='center' className="modal-xl" visible={openViewModal} onClose={() => setOpenViewModal(false)}>
                    <CModalHeader onClose={handleCloseViewModal}>
                        <CModalTitle className="text-center w-100"><span className='text-muted'>Users Referred By - </span><span className='text-success'>{singleUserDetails?.name}</span></CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <CRow>
                            {referredUsers?.length > 0 ? (
                                <CTable striped bordered hover responsive>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell className="fw-semibold">Created On</CTableHeaderCell>
                                            <CTableHeaderCell className="fw-semibold">Name</CTableHeaderCell>
                                            <CTableHeaderCell className="fw-semibold">Email</CTableHeaderCell>
                                            <CTableHeaderCell className="fw-semibold">Mobile</CTableHeaderCell>
                                            <CTableHeaderCell className="fw-semibold">Placed First Order ?</CTableHeaderCell>
                                            <CTableHeaderCell className="fw-semibold">Status of 1st Order ?</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        {referredUsers?.map((item) => (
                                            <CTableRow key={item._id}>
                                                <CTableDataCell>
                                                    {new Date(item?.createdAt).toLocaleString("en-IN", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                        hour12: true,
                                                    })}
                                                </CTableDataCell>
                                            <CTableDataCell>
                                                    <span >{item?.name}</span>
                                                    <br />
                                                    <span className='text-muted'>{item?.isDeletedBefore ? `[Deleted User]` : ''}</span>
                                                </CTableDataCell>
                                                <CTableDataCell>{item?.email}</CTableDataCell>
                                                <CTableDataCell>{item?.mobile}</CTableDataCell>
                                                <CTableDataCell>{item?.referrredUsersOrders?.length > 0 ? "YES" : "NOT YET"}</CTableDataCell>
                                                <CTableDataCell className='text-center'>
                                                    <CBadge className='w-75'
                                                        color={
                                                        item?.referrredUsersOrders[0]?.orderStatus === "CONFIRMED"
                                                            ? "warning"
                                                            : item?.referrredUsersOrders[0]?.orderStatus === "COMPLETED"
                                                            ? "primary"
                                                            : item?.referrredUsersOrders[0]?.orderStatus === "DELIVERED"
                                                            ? "success"
                                                            : "secondary"
                                                        }
                                                    >
                                                        {item?.referrredUsersOrders[0]?.orderStatus || "N/A"}
                                                    </CBadge>
                                                </CTableDataCell>

                                            </CTableRow>
                                        ))}
                                    </CTableBody>
                                </CTable>
                            ) : (
                                <h6 className='text-danger text-center'>This User Has Not Referred Anyone.</h6>
                            )}
                        </CRow>
                    </CModalBody>
                </CModal>
            ) : null}
        </div>
    )
}

export default UserAnalyticsReport