import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CImage,
  CInputGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from "@coreui/react";
import { LuEye } from "react-icons/lu";
import { MdEdit, MdDelete } from "react-icons/md";
import { PrimaryButton } from "../../components";
import "../product/ProductsCatalog.css";
import { isValidDescription, isValidName } from "../../utils/validation";
import { TagsInput } from "react-tag-input-component";
import { format } from "date-fns";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Spinner } from "react-bootstrap";

const ProductsCatalog = () => {
  const accessToken = localStorage.getItem("authToken");
  const [productsList, setProductsList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [displayImage, setDisplayImage] = useState({});
  const [imageId, setImageId] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageError, setImageError] = useState('');
  const [productDetails, setProductDetails] = useState({});
  const [editedData, setEditedData] = useState({});
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [productId, setProductId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [productTags, setProductTags] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagText, setTagText] = useState("");
  const [selected, setSelected] = useState([]);
  const [productTagsList, setProductTagsList] = useState([]);
  const [foodTypeFilter, setFoodTypeFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [productTagFilter, setProductTagFilter] = useState("all");
  const [editedSelectedExOutlets, setEditedSelectedExOutlets] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const animatedComponents = makeAnimated();
  const [coverImage, setCoverImage] = useState(null);
  const [showEditCoverModal, setShowEditCoverModal] = useState(false);
  const [isCoverImageLoading, setIsCoverImageLoading] = useState(false);
  const [selectedNature, setSelectedNature] = useState([]);

  const outletData = outlets.map((outlet) => ({
    value: outlet._id,
    label: `${outlet.name} (${outlet.city})`,
  }));
  
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderRadius: "6px",
        minWidth: "200px",
        borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0,123,255,.25)" : null,
        "&:hover": {
            borderColor: state.isFocused ? "#80bdff" : "#ced4da",
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#6c757d",
    }),
  };
    
  const getOutlets = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("outlet", { headers });

      if (response?.data?.status === true) {
        
        let filteredOutlets = response?.data?.data?.outlets?.filter((act) => act?.status === "ACTIVE");
        
        setOutlets(filteredOutlets);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getProductsList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("product", { headers });
      if (response?.data?.status === true) {
        const products = response?.data?.data?.products;
        // Sort products by category sequenceNumber first, then by product sequenceNumber
        const sortedProducts = products?.sort((a, b) => {
          
          // Compare category sequenceNumbers
          if (a.category.sequenceNumber !== b.category.sequenceNumber) {
              return a.category.sequenceNumber - b.category.sequenceNumber;
          }
          // If category sequenceNumbers are the same, compare product sequenceNumbers
          return a.sequenceNumber - b.sequenceNumber;
        });
        setProductsList(sortedProducts);
        setFilteredProducts(sortedProducts);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOpenViewModal = (data) => {
    console.log("View Details Modal :", data);
    
    getSingleProductDetails(data?._id);
    setOpenViewModal(true);
  };

  const getSingleProductDetails = async (productId) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`product?_id=${productId}`, { headers });
      if (response?.data?.status === true) {
        setProductDetails(response?.data?.data?.products);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleEditModal = async (data) => {
    console.log("data :", data);
    
    setEditedData({
      _id: data._id,
      sequenceNumber: data?.sequenceNumber,
      name: data.name,
      description: data.description,
      hsn: data.hsn,
      taxPercent: data.taxPercent,
      makingTime: data.makingTime,
      mrp: data.mrp,
      status: data?.status,
      category: data?.category?._id,
      taxPercent: data?.taxPercent,
      offerPrice: data?.offerPrice,
      foodType: data?.foodType,
      classType: data?.classType,
      productTagId: data?.productTag?._id,
      productTagName: data?.productTag?.tagName,
      isEventProduct: data?.isEventProduct,
      // keywords: data?.keywords ? data?.keywords.split(',') : []  // Convert keywords to an array
      calories: data?.calories,
      weight: data?.weight,
      weightUnit: data?.weightUnit
    });
    if (data) {
      setSelected(data.keywords && data.keywords.length > 0 ? data.keywords.split(',') : []);
    }
    let selectedExOutlet = data?.excludedOutletDetails;
    let mappedSelectedExOutlet = selectedExOutlet?.map((m) =>
      ({
        value: m?._id,
        label: m?.name
      }))
    let selectedNature = data?.productsNature;
    let mappedSelectedNature = selectedNature?.map((m) => m)
    setEditedSelectedExOutlets(mappedSelectedExOutlet);
    setSelectedNature(mappedSelectedNature);
    setOpenEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditSelectChange = (selected) => {
    setEditedSelectedExOutlets(selected);
  }

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    let _prodKeywords;
    if (selected.length > 0) {
      const _selectedodified = selected.map(val => val.trim());
      _prodKeywords = _selectedodified.join(',');
    }
    else {
      toast.error("Product keywords is required", {
        position: "top-right",
        autoClose: 3000,
        theme: "colored",
      });
      return;
    }

    setIsEditBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      let selectedExOutlets = editedSelectedExOutlets?.map((ex) => ex?.value);
      let newEditedData = {
        ...editedData,
        excludedOutlets: selectedExOutlets
      }

      const formData = new FormData();
      formData.append("_id", newEditedData._id);
      formData.append("sequenceNumber", newEditedData.sequenceNumber);
      formData.append("category", newEditedData.category);
      formData.append("name", newEditedData.name);
      formData.append("description", newEditedData.description);
      formData.append("hsn", newEditedData.hsn);
      formData.append("taxPercent", newEditedData.taxPercent);
      formData.append("status", newEditedData.status)
      formData.append("makingTime", newEditedData.makingTime);
      formData.append("mrp", newEditedData.mrp);
      formData.append("offerPrice", newEditedData.offerPrice);
      if (editedData?.productTagId) {
        formData.append("productTag[productTagId]", newEditedData.productTagId);
      }
      formData.append("productTag[productTagName]", newEditedData.productTagName);
      formData.append("foodType", newEditedData.foodType);
      formData.append("classType", newEditedData.classType);
      formData.append("calories", newEditedData.calories);
      formData.append("weight", newEditedData.weight);
      formData.append("weightUnit", newEditedData.weightUnit);
      formData.append("isEventProduct", newEditedData.isEventProduct);
      formData.append("keywords", _prodKeywords);
      // if (selectedExOutlets?.length > 0 ) {
      //   formData.append("excludedOutlets", JSON.stringify(newEditedData.excludedOutlets));
      // }

      // Check if excluded outlets are empty
      if (selectedExOutlets.length === 0) {
        // Explicitly set excludedOutlets to an empty array (JSON.stringify([]))
        // formData.append("excludedOutlets", JSON.stringify([]));
        
        // Explicitly set excludedOutlets to null
        formData.append("excludedOutlets", null);
      } else {
        // Append each excluded outlet as excludedOutlets[0], excludedOutlets[1], etc.
        selectedExOutlets.forEach((outlet, index) => {
          formData.append(`excludedOutlets[${index}]`, outlet);
        });
      }

      if (selectedNature.length === 0 || selectedNature.length === undefined) {
        // Explicitly set excludedOutlets to an empty array (JSON.stringify([]))
        // formData.append("productsNature", JSON.stringify([]));
        
        // Explicitly set excludedOutlets to null
        formData.append("productsNature", null);
      } else {
        // Append each excluded outlet as excludedOutlets[0], excludedOutlets[1], etc.
        selectedNature.forEach((outlet, index) => {
          formData.append(`productsNature[${index}]`, outlet);
        });
      }


      // Debugging: log the formData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await axios.put("product", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProductsList();
        getSingleProductDetails(editedData._id);
        setOpenEditModal(false);
        setOpenViewModal(true);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsEditBtnLoading(false);
    }
  };

  const handleDeleteModal = async (data) => {
    setOpenDeleteModal(true);
    setProductId(data?._id);
  };

  const handleDeleteProducts = async (e) => {
    e.preventDefault();
    setIsDeleteBtnLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };

      const data = {
        _id: productId,
      };

      const response = await axios.delete(`product`, { data, headers });
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
        getProductsList();
        setOpenDeleteModal(false);
      }
    } catch (error) {      
      if (error.response.status === 403) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response.status === 404) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response.status === 406) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response.status === 412) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }

      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsDeleteBtnLoading(false);
    }
  };

  const handleSearchProducts = () => {
    const lowerCaseSearchText = searchText.toLowerCase();
    const filteredData = productsList.filter((item) => {
      const nameMatch = item.name.toLowerCase().includes(lowerCaseSearchText);
      const tagMatch = item.productTag && item.productTag.tagName.toLowerCase().includes(lowerCaseSearchText);
      return nameMatch || tagMatch;
    });
    setFilteredProducts(filteredData);
  };

  const getCategoryList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get("masters/category/get", { headers });

      if (response?.data?.status === true) {
        setCategoryList(response?.data?.data?.categories);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleSearchText = (e) => {
    setTagText(e.target.value)
    setEditedData((prev) => ({ ...prev, productTagName: e.target.value, productTagId: null }))
  }

  const getProductTags = async (text) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`/product/tags?search=${text}`, { headers });

      if (response?.data?.status === true) {
        setProductTags(response?.data?.data?.productTags);
      } else {
        setProductTags([])
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleOptionSelect = (selectedOption, selectedName) => {
    setEditedData((prev) => ({ ...prev, productTagName: selectedName, productTagId: selectedOption }));
    setProductTags([]);
  };

  const getProductTagsList = async (text) => {
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.get(`/product/tags`, { headers });

      if (response?.data?.status === true) {
        setProductTagsList(response?.data?.data?.productTags);
      }
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        let errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleFoodTypeFilter = (e) => {
    const value = e.target.value;
    // let filteredData = productsList;

    // if (value !== "all") {
    //   filteredData = productsList.filter((item) => item.foodType === value);
    // }

    // setFilteredProducts(filteredData);
    setFoodTypeFilter(value);
    applyAllFilters(value, statusFilter, categoryFilter, productTagFilter);
  };

  const handleStatusFilter = (e) => {
    // const value = e.target.value;
    // let filteredData = productsList;

    // if (value !== "all") {
    //   filteredData = productsList.filter((item) => item.status === value);
    // }

    // setFilteredProducts(filteredData);
    const value = e.target.value;
    setStatusFilter(value);
    applyAllFilters(foodTypeFilter, value, categoryFilter, productTagFilter);
  };

  const handleCategoryFilter = (e) => {
    // const value = e.target.value;
    // let filteredData = productsList;

    // if (value !== "all") {
    //   filteredData = productsList.filter((item) => item.category?._id === value);
    // }

    // setFilteredProducts(filteredData);
    const value = e.target.value;
    setCategoryFilter(value);
    applyAllFilters(foodTypeFilter, statusFilter, value, productTagFilter);
  };

  const handleProductTagFilter = (e) => {
    // const value = e.target.value;
    // let filteredData = productsList;

    // if (value !== "all") {
    //   filteredData = productsList.filter((item) => item.productTag && item.productTag._id === value);
    // }

    // setFilteredProducts(filteredData);
    const value = e.target.value;
    setProductTagFilter(value);
    applyAllFilters(foodTypeFilter, statusFilter, categoryFilter, value);
  };

  const applyAllFilters = (foodType, status, category, productTag) => {
    let filteredData = productsList;
  
    // Apply food type filter
    if (foodType !== "all") {
      filteredData = filteredData.filter((item) => item.foodType === foodType);
    }
  
    // Apply status filter
    if (status !== "all") {
      filteredData = filteredData.filter((item) => item.status === status);
    }
  
    // Apply category filter
    if (category !== "all") {
      filteredData = filteredData.filter((item) => item.category?._id === category);
    }
  
    // Apply product tag filter
    if (productTag !== "all") {
      filteredData = filteredData.filter((item) => item.productTag && item.productTag._id === productTag);
    }
  
    // Update the filtered products state
    setFilteredProducts(filteredData);
  };

  useEffect(() => {
    applyAllFilters(foodTypeFilter, statusFilter, categoryFilter, productTagFilter);
  }, [productsList]);
  

  const handleOpenImageModal = (id) => {
    setOpenEditImageModal(true);
    setImageId(id);
  }

  const handleImageChange = (e) => {
    const input = document.getElementById("imageInput");
    const file = input.files[0];
    if (file) {
      const validFormats = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validFormats.includes(file.type)) {
        setImageError('Invalid file format. Only JPEG, PNG, and JPG are allowed.');
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const maxWidth = 400;
          const maxHeight = 400;

          if (width === maxWidth && height === maxHeight) {
            // alert("Image dimensions are valid!");
            setDisplayImage(file);
          } else {
            alert(
              "Image dimensions are not valid. Please choose an image with dimensions equal to " +
              maxWidth +
              "x" +
              maxHeight
            );
            input.value = "";
          }
        };
      };
      reader.readAsDataURL(file);
    }
  }

  const handleSumbitUpdateImage = async (e) => {
    e.preventDefault();
    setIsImageLoading(true);
    try {
      const formData = new FormData();
      formData.append("_id", imageId);
      if (displayImage) {
        formData.append("displayImage", displayImage);
      }
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await axios.put("product", formData, { headers });

      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });

        e.target.reset();
        setDisplayImage("");
        const input = document.getElementById("imageInput");
        getProductsList();
        input.value = "";
        setOpenEditImageModal(false);
      }
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (
          error.response?.status === 406
        ) {
          errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
        }
        else if (
          error.response?.status === 412
        ) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error
        }

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    } finally {
      setIsImageLoading(false);
    }
  }

  const handlePrintReport = () => {
    const tableContent = `
      <table style="border-collapse: collapse; width: 100%;">
        <thead>
          <tr>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Sr.No</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Menu Name</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Category</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Class Type</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Food Type</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left;">Making Time</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Price</th>
            <th style="border: 1px solid black; padding: 8px; text-align: left; width: 70px">Offer Price</th>
          </tr>
        </thead>
        <tbody>
          ${productsList?.length > 0 ? 
            productsList.map((product, index) => `
              <tr>
                <td style="border: 1px solid black; padding: 8px;">${index + 1}</td>
                <td style="border: 1px solid black; padding: 8px;">${product?.name}</td>
                <td style="border: 1px solid black; padding: 8px;">${product?.category?.name}</td>
                <td style="border: 1px solid black; padding: 8px;">${product?.classType}</td>
                <td style="border: 1px solid black; padding: 8px;">${product?.foodType}</td>
                <td style="border: 1px solid black; padding: 8px;">${product?.makingTime} mins</td>
                <td style="border: 1px solid black; padding: 8px;">₹. ${product?.mrp}</td>
                <td style="border: 1px solid black; padding: 8px;">₹. ${product?.offerPrice}</td>
              </tr>
            `).join("") :
            `<tr>
              <td colspan="8" style="border: 1px solid black; padding: 8px; text-align: center;">No Menu Items Found</td>
            </tr>`
          }
        </tbody>
      </table>
    `;
  
    const win = window.open("", "", "width=900,height=600");
    win.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid black; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body onload="window.print();window.close()">
          <h2>Menu Report</h2>
          <div>${tableContent}</div>
        </body>
      </html>
    `);
    win.document.close();
  };

  const handleOpenCoverImageModal = (id) => {
    setImageId(id); // Save the product ID for submission
    setShowEditCoverModal(true);
  }

  const handleCoverPictureChange = (e) => {
    const fileInput = document.getElementById('newCoverImage');
    const file = fileInput?.files[0];
    if (file) {
      setCoverImage(file); // Update the state with the selected file
    }
  };

  const handleSumbitUpdateCoverImage = async (e) => {
    // e.preventDefault();
    setIsCoverImageLoading(true);
  
    try {
      const formData = new FormData();
      formData.append('_id', imageId); // Use the product ID stored earlier
      if (coverImage) {
        formData.append('coverPicture', coverImage); // Attach the selected cover image
      }
  
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      };
  
      const response = await axios.put('product', formData, { headers });
      console.log("response");
  
      if (response?.data?.status === true) {
        toast.success(response?.data?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          theme: 'colored',
        });
        // e.target.reset();
        setCoverImage(null);
        const input = document.getElementById('newCoverImage');
        input.value = ''; // Clear the file input  
      }
      getProductsList(); // Refresh the product list to reflect the updated cover image
      getSingleProductDetails(imageId);
      setShowEditCoverModal(false);
    } catch (error) {
      if (error?.response) {
        let errorMessage = '';
        if (error.response?.status === 406) {
          errorMessage =
            error?.response?.data?.error?.message || error?.response?.data?.error;
        } else if (error.response?.status === 412) {
          errorMessage = error?.response?.data?.data?.message;
        } else if (error.response?.status === 500) {
          errorMessage = error?.response?.data?.error;
        }
  
        toast.error(errorMessage, {
          position: 'top-right',
          autoClose: 3000,
          theme: 'colored',
        });
      }
    } finally {
      setIsCoverImageLoading(false);
    }
  };
  
  useEffect(() => {
    getProductsList();
    getCategoryList()
    getProductTags();
    getProductTagsList();
    getOutlets();
  }, []);

  useEffect(() => {
    if (searchText === "") {
      getProductsList();
    }
  }, [searchText]);

  useEffect(() => {
    getProductTags(tagText);
  }, [tagText]);

  return (
    <CContainer>
      {/* <------------- FILTERS SECTIONS -----------> */}
      <h6>Filter By :</h6>
      <CRow>
        <CCol md="4" lg="3" className="mb-3">
          <CFormSelect onChange={handleCategoryFilter}>
            <option value="all">Category</option>
            {categoryList.map((rm) => (
              <option key={rm._id} value={rm._id}>
                {rm.name}
              </option>
            ))}
          </CFormSelect>
        </CCol>
        <CCol md="4" lg="3">
          <CFormSelect onChange={handleProductTagFilter}>
            <option value="all">Product Tag</option>
            {productTagsList.map((rm) => (
              <option key={rm._id} value={rm._id}>
                {rm.tagName}
              </option>
            ))}
          </CFormSelect>
        </CCol>
        <CCol md="4" lg="3">
          <CFormSelect onChange={handleStatusFilter}>
            <option value="all">Status</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
          </CFormSelect>
        </CCol>
        <CCol md="4" lg="3">
          <CFormSelect onChange={handleFoodTypeFilter}>
            <option value="all">Food Type</option>
            <option value="VEG">VEG</option>
            <option value="NON-VEG">NON-VEG</option>
            <option value="VEGAN">VEGAN</option>
          </CFormSelect>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6" lg="6">
          <CFormInput
            type="text"
            placeholder="Search by name..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </CCol>
        <CCol md="3" lg="3" className="text-center">
          <CButton className="btn rounded-pill w-100" disabled={!searchText} onClick={handleSearchProducts}>
            Search
          </CButton>
        </CCol>
        {/* New button for printing report */}
        <CCol md="3" lg="3" className="text-center">
          <CButton color="success" className="btn rounded-pill w-100" onClick={handlePrintReport}>
            Print Report
          </CButton>
        </CCol>
      </CRow>

      {/* <------------- PRODUCTS CARD LISTING -----------> */}
      <CRow>
        {filteredProducts && filteredProducts?.length > 0 ? filteredProducts.map((product) => (
          <CCol key={product.id} xs="12" sm="6" md="4" lg="3" xl="2" className="mt-3 mb-1">
            <CCard className="cardHover p-2">
              <CImage
                src={product.imageURL}
                alt="productimage"
                className="card-img-top"
                style={{ height: "55%", width: '100%' }}
                onClick={() => handleOpenImageModal(product._id)}
              />

              <CCardBody>
                {product.status === "ACTIVE" ? (
                  <div className="d-flex justify-content-between align-items-center" style={{ margin: '-10px' }}>
                    <CBadge
                      color="primary"
                      style={{
                        position: "absolute",
                        top: 16,
                        left: 16,
                        borderRadius: "15px",
                        fontSize: "8px",
                      }}
                    >
                      {product.status}
                    </CBadge>
                      {product?.foodType === "VEG/VEGAN" ? (
                        <>
                          <CImage style={{ width: '18px' }} src="/assets/veg.png" alt="Veg" />
                          <CImage className="ms-1" style={{ width: '18px' }} src="/assets/vegan.png" alt="Vegan" />
                        </>
                      ) : (
                        <CImage
                          style={{ width: '18px' }}
                          src={`${
                            product?.foodType === "VEG"
                              ? "/assets/veg.png"
                              : product?.foodType === "NON-VEG"
                              ? "/assets/nonveg.png"
                              : "/assets/vegan.png"
                          }`}
                        />
                      )}
                    <span
                      style={{ fontSize: '10px', color: '#09A5F7', fontWeight: '500' }}
                    >
                      {product?.productTag?.tagName}
                    </span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between align-items-center" style={{ margin: '-10px' }}>
                    <CBadge
                      color="warning"
                      style={{ position: "absolute", top: 16, left: 16, fontSize: "xx-small" }}
                    >
                      {product.status}
                    </CBadge>
                    {product?.foodType === "VEG/VEGAN" ? (
                      <>
                        <CImage style={{ width: '18px' }} src="/assets/veg.png" alt="Veg" />
                        <CImage className="ms-1" style={{ width: '18px' }} src="/assets/vegan.png" alt="Vegan" />
                      </>
                    ) : (
                      <CImage
                        style={{ width: '18px' }}
                        src={`${
                          product?.foodType === "VEG"
                            ? "/assets/veg.png"
                            : product?.foodType === "NON-VEG"
                            ? "/assets/nonveg.png"
                            : "/assets/vegan.png"
                        }`}
                      />
                    )}
                    <span
                      style={{ fontSize: '10px', color: '#09A5F7', fontWeight: '500' }}
                    >
                      {product?.productTag?.tagName}
                    </span>
                  </div>
                  
                )}

                {product?.sequenceNumber ? (
                  <h6
                    style={{
                      position: "absolute",
                      top: 12,
                      right: 16,
                      borderRadius: "15px",
                      fontSize: "11px",
                    }}
                  >
                    Sq.No :{" "}
                    <span style={{ textDecoration: 'underline' }}>
                      {product?.sequenceNumber}
                    </span>
                  </h6>
                ) : null}
                <h6
                  className="mt-4"
                  style={{
                    margin: '-10px',
                    overflow: "hidden",
                    fontSize: '13px',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "200px",
                  }}
                >
                  {product.name}
                </h6>
                <div className="d-flex mt-2" style={{ alignItems: 'center', margin: '-10px' }}>
                  <div className="col-6 text-start">
                    <span className="price-text" style={{ fontWeight: '500', color: '#8E0606' }}>MRP - ₹ {product?.mrp}</span>
                  </div>
                  <div className="col-6">
                    <span className="price-text" style={{ fontWeight: '500', color: '#00A413' }}>Off Price - ₹ {product?.offerPrice?.toFixed(0)}</span>
                  </div>
                </div>

                <div className="d-flex flex-wrap justify-content-around align-items-center mt-3" style={{ margin: '-15px' }}>
                  <div className="mb-2">
                    <CTooltip content="View Menu" placement="bottom">
                      <CButton
                        color="warning"
                        onClick={() => handleOpenViewModal(product)}
                        style={{
                          fontWeight: "bold",
                          padding: 4,
                          // fontSize: '10px',
                          borderRadius: "20px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          textTransform: "uppercase",
                        }}
                        className="btn btn-warning btn-hover-warning three-buttons w-100"
                      >
                        <LuEye /> <span style={{ fontSize: '9px' }}>VIEW</span>
                      </CButton>
                    </CTooltip>
                  </div>
                  <div className="mb-2">
                    <CTooltip content="Edit Data" placement="bottom">
                      <CButton
                        color="primary"
                        onClick={() => handleEditModal(product)}
                        style={{
                          padding: 4,
                          fontWeight: "bold",
                          // fontSize: "10px",
                          borderRadius: "20px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          textTransform: "uppercase",
                        }}
                        className="btn btn-primary btn-hover-danger three-buttons w-100"
                      >
                        <MdEdit /> <span style={{ fontSize: '9px' }}>EDIT</span> {"  "}
                      </CButton>
                    </CTooltip>
                  </div>
                  <div className="mb-2">
                    <CTooltip content="Delete Menu" placement="bottom">
                      <CButton
                        color="danger"
                        onClick={() => handleDeleteModal(product)}
                        style={{
                          padding: 4,
                          fontWeight: "bold",
                          // fontSize: "10px",
                          borderRadius: "20px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          textTransform: "uppercase",
                        }}
                        className="btn btn-danger btn-hover-danger three-buttons w-100"
                      >
                        <MdDelete /> <span style={{ fontSize: '9px' }}>DELETE</span>
                      </CButton>
                    </CTooltip>
                  </div>
                </div>

              </CCardBody>
            </CCard>
          </CCol>

        )) :
          <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '150px' }}>
            <h4>There are no products to display.</h4>
          </div>
        }
      </CRow>

      {openViewModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={openViewModal}
          onClose={() => setOpenViewModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              VIEW PRODUCT DETAILS
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol md="12" lg="6" style={{ position: 'relative' }}>
                {/* Display Cover Picture */}
                  
                  {/* Existing Product Image */}
                  <div style={{ position: 'relative' }}>
                    <div className="d-flex justify-content-between mt-1" style={{ position: 'absolute' }}>
                      <div className="ms-2">
                        <CBadge color="primary" size="sm">
                          {productDetails?.status}
                        </CBadge>
                      </div>
                      <div className="ms-2">
                        {productDetails?.foodType === 'VEG/VEGAN' ? (
                          <>
                            <div>
                              <CImage
                                className="mx-md-2 mx-lg-0"
                                style={{ width: '20px' }}
                                src="/assets/veg.png"
                              />
                            </div>
                            <div>
                              <CImage
                                className="mx-md-2 mx-lg-0"
                                style={{ width: '20px' }}
                                src="/assets/vegan.png"
                              />
                            </div>
                          </>
                        ) : (
                          <CImage
                            className="mx-md-2 mx-lg-0"
                            style={{ width: '20px' }}
                            src={`${
                              productDetails?.foodType === 'VEG'
                                ? '/assets/veg.png'
                                : productDetails?.foodType === 'NON-VEG'
                                ? '/assets/nonveg.png'
                                : '/assets/vegan.png'
                            }`}
                          />
                        )}
                      </div>
                    </div>
                    {/* <CImage
                      src={productDetails.imageURL}
                      alt="Product Image"
                      width="42%"
                      style={{ position: 'absolute', left: 145, top: 30 }}
                    /> */}
                    <div className="mb-4">
                      <CImage
                        src={
                          productDetails?.coverImageURL
                            ? productDetails?.coverImageURL
                            : '/assets/coverImage.jpg'
                        }
                        alt="Cover Picture"
                        width="100%"
                        style={{
                          borderRadius: '8px',
                          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                          objectFit: 'cover',
                          height: '100%',
                        }}
                      />
                      <CButton
                        className="mt-2 btn btn-success"
                        size="sm"
                        style={{
                          position: 'absolute',
                          top: 420,
                          right: 20,
                          borderRadius: '18px',
                          fontWeight: 'bold',
                        }}
                        onClick={() => handleOpenCoverImageModal(productDetails?._id)}
                      >
                        Update Cover Image
                      </CButton>
                    </div>
                  </div>
                
                {/* Table for Additional Product Details */}
                <CTable className="mt-4" striped bordered>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Calories</CTableDataCell>
                      <CTableDataCell>{productDetails?.calories}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Weight</CTableDataCell>
                      <CTableDataCell>{productDetails?.weight}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Weight Units</CTableDataCell>
                      <CTableDataCell>{productDetails?.weightUnit}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Offer Price</CTableDataCell>
                      <CTableDataCell>
                        {productDetails?.offerPrice ? productDetails?.offerPrice : 0} Rs.
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">MRP</CTableDataCell>
                      <CTableDataCell>
                        {productDetails?.mrp ? productDetails?.mrp : 0} Rs.
                      </CTableDataCell>
                    </CTableRow>
                    
                  </CTableBody>
                </CTable>
              </CCol>
              <CCol md="12" lg="6">
                <CTable striped bordered>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold w-25">Seq Number</CTableDataCell>
                      <CTableDataCell className="fw-semibold">
                        <i>{productDetails?.sequenceNumber}</i>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Name</CTableDataCell>
                      <CTableDataCell className="fw-semibold">
                        <i>{productDetails?.name}</i>
                      </CTableDataCell>
                    </CTableRow>
                    {/* <CTableRow>
                      <CTableDataCell className="fw-semibold">Created On</CTableDataCell>
                      <CTableDataCell>
                        {productDetails?.productTag?.createdAt
                          ? new Date(productDetails?.productTag?.createdAt).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                              hour12: true,
                            })
                          : '--------'}
                      </CTableDataCell>
                    </CTableRow> */}
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Category Name</CTableDataCell>
                      <CTableDataCell>{productDetails?.category?.name}</CTableDataCell>
                    </CTableRow>
                    {/* <CTableRow>
                      <CTableDataCell className="fw-semibold">Category Description</CTableDataCell>
                      <CTableDataCell>{productDetails?.category?.description}</CTableDataCell>
                    </CTableRow> */}
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Product Description</CTableDataCell>
                      <CTableDataCell>{productDetails?.description}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Excluding Outlets</CTableDataCell>
                      <CTableDataCell>
                        {productDetails.excludedOutletDetails && productDetails.excludedOutletDetails.length > 0
                          ? productDetails.excludedOutletDetails
                              .map((outlet) => outlet.name.toLowerCase().trim())
                              .join(', ')
                          : 'No outlets excluded'}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Class Type</CTableDataCell>
                      <CTableDataCell>{productDetails?.classType} </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Outlet Type</CTableDataCell>
                      <CTableDataCell className="text-primary">
                        {productDetails?.isEventProduct ? 'For Event Outlet' : 'For Regular Outlet'}{' '}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Product's Nature</CTableDataCell>
                      <CTableDataCell>
                        {productDetails?.productsNature && productDetails?.productsNature?.length > 0
                          ? productDetails?.productsNature
                              .map((outlet) => outlet?.toLowerCase().trim())
                              .join(', ')
                          : 'Not Available'}
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Product Tags</CTableDataCell>
                      <CTableDataCell>{productDetails?.productTag?.tagName} </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Product Keywords</CTableDataCell>
                      <CTableDataCell>{productDetails?.keywords} </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">HSN</CTableDataCell>
                      <CTableDataCell>{productDetails?.hsn}</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Tax Percent</CTableDataCell>
                      <CTableDataCell>
                        {productDetails?.taxPercent ? productDetails?.taxPercent : 0}%
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Making Time</CTableDataCell>
                      <CTableDataCell>{productDetails?.makingTime} mins</CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="fw-semibold">Combo Product</CTableDataCell>
                      <CTableDataCell className="fw-semibold" style={{ color: productDetails?.isComboProduct === true ? 'green' : 'red'  }}>{productDetails?.isComboProduct === true ? "YES" : "NO"}</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>

            <CRow>
              <hr />
              <h6 style={{ textDecoration: 'underline' }}>ADDON DETAILS <span style={{ textDecoration: 'none' }}>-</span></h6>

              {productDetails?.productAddons?.length === 0 ? (
                <span className="text-muted fw-semibold">No Addons Present for this Product.</span>
              ) : (
                productDetails?.productAddons?.map((addon) => (
                  <CCol lg="3" key={addon._id}>
                    <div>
                      <h6 className="mt-3 fw-bold" style={{ color: 'purple' }}>{addon.addonTitle}</h6>
                      <CTable striped bordered hover>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell style={{ fontWeight: '500' }}>Value</CTableHeaderCell>
                            <CTableHeaderCell style={{ fontWeight: '500' }}>Offer Price</CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {addon?.addonValues?.map((value) => (
                            <CTableRow key={value._id}>
                              <CTableDataCell style={{ color: value?.status === "ACTIVE" ? '' : 'red' }}>
                                {value.value}
                              </CTableDataCell>
                              <CTableDataCell style={{ color: value?.status === "ACTIVE" ? '' : 'red' }} className="text-end">
                                ₹. {value.offerPrice || "N/A"}
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                        </CTableBody>
                      </CTable>
                    </div>
                  </CCol>
                ))
              )}


              {/* {productDetails?.productAddons?.map((addon) => (
                <CCol lg="3">
                <div key={addon._id}>
                  <h6 className="mt-3 fw-bold" style={{ color: 'purple' }}>{addon.addonTitle}</h6>
                  <CTable striped bordered hover>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell style={{ fontWeight: '500' }}>Value</CTableHeaderCell>
                        <CTableHeaderCell style={{ fontWeight: '500' }}>Offer Price</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {addon?.addonValues?.map((value) => (
                        <CTableRow key={value._id}>
                          <CTableDataCell style={{ color: value?.status === "ACTIVE" ? '' : 'red'  }}>{value.value}</CTableDataCell>
                          <CTableDataCell style={{ color: value?.status === "ACTIVE" ? '' : 'red'  }} className="text-end">₹. {value.offerPrice || "N/A"}</CTableDataCell>
                        </CTableRow>
                      ))}
                    </CTableBody>
                  </CTable>
                </div>
                </CCol>
              ))} */}
            </CRow>


            {/* <span className="mt-3">
              <span className="fw-bold">
                {' '}
                <i style={{ textDecoration: 'underline' }}>Excluded Outlets: </i>{' '}
              </span>
              <span className="ms-1">
                {productDetails.excludedOutletDetails && productDetails.excludedOutletDetails.length > 0
                  ? productDetails.excludedOutletDetails
                      .map((outlet) => outlet.name.toLowerCase().trim())
                      .join(', ')
                  : 'No outlets excluded'}
              </span>
            </span>
            <p className="mt-3">
              <span className="fw-bold">
                {' '}
                <i style={{ textDecoration: 'underline' }}>Description:</i>{' '}
              </span>
              {productDetails?.description}
            </p> */}
          </CModalBody>
          <CModalFooter>
            <CButton
              style={{ borderRadius: '18px' }}
              className="btn btn-warning"
              onClick={() => setOpenViewModal(false)}
            >
              CLOSE
            </CButton>
          </CModalFooter>
        </CModal>
      ) : null}

      {openEditModal ? (
        <CModal
          size="xl"
          alignment="center"
          visible={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              EDIT PRODUCT
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleEditSubmit}>
            <CModalBody>
              <CRow>
                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Sequence Number <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="Sequence Number"
                      className="form-control no-arrows"
                      name="sequenceNumber"
                      value={editedData?.sequenceNumber}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Category <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="category"
                      className="mb-3"
                      value={editedData?.category}
                    >
                      <option value="">Select Category</option>
                      {categoryList.map((rm) => (
                        <option key={rm._id} value={rm._id}>
                          {rm.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Name <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Name"
                      className="form-control"
                      name="name"
                      value={editedData.name}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Class Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="classType"
                      className="mb-3"
                      value={editedData.classType}
                    >
                      <option value="">Class Type</option>
                      <option value="FOOD">FOOD</option>
                      <option value="BEVERAGE">BEVERAGE</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      HSN <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      placeholder="HSN"
                      className="form-control"
                      name="hsn"
                      value={editedData.hsn}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

                <CCol className="col-sm-2 col-md-4 col-lg-4 mx-md-auto">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Calories <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="Calories"
                      className="form-control no-arrows"
                      name="calories"
                      value={editedData.calories}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

                <CCol className="col-sm-2 col-md-4 col-lg-4 mx-md-auto">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Weight <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="Weight"
                      className="form-control no-arrows"
                      name="weight"
                      value={editedData.weight}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol className="col-sm-2 col-md-4 col-lg-4 mx-md-auto">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Weight Unit <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="weightUnit"
                      className="mb-3"
                      value={editedData.weightUnit}
                    >
                      <option value="">Weight Unit</option>
                      <option value="KG">KILOGRAMS</option>
                      <option value="GMS">GRAMS</option>
                      <option value="LITRES">LITRES</option>
                      <option value="ML">MILLI-LITRE</option>
                    </CFormSelect>
                  </div>
                </CCol>

                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Tax Percent <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      max={100}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="Tax Percent"
                      className="form-control no-arrows"
                      name="taxPercent"
                      value={editedData.taxPercent}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Making Time <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Mins"
                      className="form-control no-arrows"
                      name="makingTime"
                      value={editedData.makingTime}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>

                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      MRP <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="mrp"
                      value={editedData.mrp}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Offer Price <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormInput
                      min={0}
                      type="number"
                      step= "any" // Allows decimal values
                      placeholder="In Rupees"
                      className="form-control no-arrows"
                      name="offerPrice"
                      value={editedData.offerPrice}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
                <CCol md="4" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Status <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="status"
                      className="mb-3"
                      value={editedData.status}
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="INACTIVE">INACTIVE</option>
                    </CFormSelect>
                  </div>
                </CCol>
                <CCol md="4" lg="4">
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Select Food Type <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="foodType"
                      className="mb-3"
                      value={editedData.foodType}
                    >
                      <option value="">Select Food Type</option>
                      <option value="VEG">VEG</option>
                      <option value="NON-VEG">NON-VEG</option>
                      <option value="VEGAN">VEGAN</option>
                      <option value="VEG/VEGAN">VEG/VEGAN</option>
                    </CFormSelect>
                  </div>
                </CCol>
                {/* Is Product Event */}
                  <CCol md={4} lg={4}>
                    <CFormLabel className="fw-semibold">Outlet Type <span className="text-danger">*</span></CFormLabel>
                    <CFormSelect
                      size="sm"
                      onChange={handleEditChange}
                      name="isEventProduct"
                      value={editedData.isEventProduct}
                    >
                      <option value="" disabled>Select Outlet Type</option>
                      <option value="false">Regular Outlet</option>
                      <option value="true">Event Outlet</option>
                    </CFormSelect>
                  </CCol>

                <CRow className="mb-2">
                  <CCol md="4">
                    <div>
                      <CFormLabel className="fw-semibold">
                        Product Tag Name <span className="text-danger">*</span>
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="Product Tag Name"
                        className="form-control"
                        name="productTagName"
                        onChange={handleSearchText}
                        value={editedData?.productTagName}
                      />
                    </div>
                    {productTags && productTags.length > 0 && (
                      <div className="dropdown-menu">
                        {productTags.map((prod) => (
                          <div style={{ marginTop: '-10px', padding: '5px', cursor: 'pointer' }} key={prod._id} onClick={() => handleOptionSelect(prod._id, prod.tagName)}>
                            {prod.tagName}
                          </div>
                        ))}
                      </div>
                    )}
                  </CCol>
                
                  <CCol md="8" >
                    <div>
                      <CFormLabel className="fw-semibold">
                        Product Keywords <span className="text-danger">*</span> <span style={{ fontSize: '13px', color: 'purple', fontWeight: 'normal' }}>(Press Enter After Adding Each Keyword).</span>
                      </CFormLabel>
                      <TagsInput
                        value={selected}
                        onChange={setSelected}
                        name="keywords"
                        placeHolder="Product Keywords"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CCol className="col-md-6">
                    <CFormLabel className="fw-semibold">
                        Select Excluded Outlets
                    </CFormLabel>

                    <Select
                        isMulti
                        components={animatedComponents}
                        value={editedSelectedExOutlets}
                        onChange={handleEditSelectChange}
                        options={outletData}
                        styles={customStyles}
                        placeholder="Select Excluded Outlets" // You can also use a dynamic placeholder from props or state
                        closeMenuOnSelect={false}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: "#e9ecef",
                                primary: "#007bff",
                            },
                        })}
                    />
                  </CCol>
                  <CCol md="6" >
                    <div>
                      <CFormLabel className="fw-semibold">
                        Nature Of Product <span className="text-danger">*</span> <span style={{ fontSize: '11px', color: 'purple', fontWeight: 'normal' }}>(Press Enter After Adding Each Keyword).</span>
                      </CFormLabel>
                      <TagsInput
                        value={selectedNature}
                        onChange={setSelectedNature}
                        name="keywords"
                        placeHolder="Define Nature Of Product"
                      />
                    </div>
                  </CCol>
              </CRow>

                <CCol>
                  <div className="mb-3">
                    <CFormLabel className="fw-semibold">
                      Description <span className="text-danger">*</span>
                    </CFormLabel>
                    <CFormTextarea
                      rows="3"
                      type="text"
                      placeholder="Description"
                      className="form-control"
                      name="description"
                      value={editedData.description}
                      onChange={handleEditChange}
                    />
                  </div>
                </CCol>
              </CRow>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton
                title="SAVE CHANGES"
                isLoading={isEditBtnLoading}
              />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {openEditImageModal ? (
        <CModal
          size="md"
          alignment="center"
          visible={openEditImageModal}
          onClose={() => setOpenEditImageModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              CHANGE IMAGE
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleSumbitUpdateImage}>
            <CModalBody>
              <CInputGroup>
                <CFormInput type="file" id="imageInput" className='w-100' accept="image/*" onChange={(e) => handleImageChange(e)} />
                {imageError &&
                  <span style={{ color: 'red', fontSize: '13px' }}>{imageError}</span>
                }
              </CInputGroup>
            </CModalBody>
            <CModalFooter>
              <PrimaryButton title="Save" isLoading={isImageLoading} isDisable={!!imageError || isImageLoading} />
              <CButton color="danger" className='btn rounded-pill' onClick={() => setOpenEditImageModal(false)}>Cancel</CButton>
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}

      {/* Edit Cover Image Modal */}
      {showEditCoverModal && (
        <CModal
          alignment="center"
          visible={showEditCoverModal}
          onClose={() => setShowEditCoverModal(false)}
        >
          <CModalHeader>
            <CModalTitle>Edit Cover Image</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="mb-3">
              <label htmlFor="newCoverImage" className="form-label">
                Select a New Cover Image
              </label>
              <input
                type="file"
                className="form-control"
                id="newCoverImage"
                accept="image/*"
                onChange={(e) => handleCoverPictureChange(e)}
              />
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton
              color="success"
              onClick={handleSumbitUpdateCoverImage}
            >
              { isCoverImageLoading ? <Spinner size="sm" /> : "Update" }
            </CButton>
            <CButton
              color="secondary"
              onClick={() => setShowEditCoverModal(false)}
            >
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}

      {openDeleteModal ? (
        <CModal
          alignment="center"
          visible={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalHeader>
            <CModalTitle id="VerticallyCenteredExample" className="fs-6">
              DELETE MENU
            </CModalTitle>
          </CModalHeader>
          <CForm onSubmit={handleDeleteProducts}>
            <CModalBody>
              Are you sure you want to delete this menu ?
            </CModalBody>
            <CModalFooter>
              <CButton
                color="warning"
                onClick={() => setOpenDeleteModal(false)}
                style={{
                  borderRadius: "18px",
                }}
              >
                CLOSE
              </CButton>
              <PrimaryButton title="CONFIRM" isLoading={isDeleteBtnLoading} />
            </CModalFooter>
          </CForm>
        </CModal>
      ) : null}
    </CContainer>
  );
};

export default ProductsCatalog;
